import {AppBar, makeStyles, Tab, Tabs, Typography} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isDirty, stopSubmit} from 'redux-form';
import {RootState} from '~/app/rootReducers';
import {FORMGROUPS, load, updateAnswer} from '~/features/FormGroup/slice';
import AdmPlanForm from '~/forms/AdmPlanForm';
import QuizForm from '~/forms/QuizForm';
import SubjectForm from '~/forms/SubjectForm';
import {FormGroup, FormGroupType, Session} from '~/types';
import TabPanel from '~/components/TabPanel';

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyle = makeStyles((theme) => ({
  container: {
    width: '90%',
    margin: 'auto',
    marginTop: 20,
  },
  preQuiz: {
    marginBottom: 43,
    fontSize: 20,
  },
  answerBox: {
    backgroundColor: '#E7F5FC',
    padding: 20,
    marginBottom: 30,
    fontWeight: 600,
    boxShadow: '0 0 0 0.2px #000 inset',
  },
  tabPanel: {
    overflow: 'auto',
  },
  eduGoal: {
    fontSize: 16,
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.2)',
  },
  titleWrap: {
    '&:nth-child(n+2)': {
      marginTop: 40,
    },
  },
  quizForm: {
    marginBottom: 30,
  },
  subject: {
    backgroundColor: '#E7F5FC',
    width: '100%',
    padding: 10,
    '&:last-child': {
      marginBottom: 80,
    },
  },
  imgCenter: {
    display: 'block',
    margin: 'auto',
  },
}));

interface Props {
  scenarioId: number;
  session: Session;
}

const CommAnalysisScreen = ({scenarioId, session}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const formGroups = useSelector(
    (state: RootState) => state[FORMGROUPS].formGroups[session.type],
  );
  const state = useSelector((state: RootState) => state);
  const [value, setValue] = React.useState(0);

  // useEffect(() => {
  //   if (!formGroups) {
  //     dispatch(load({scenarioId, session}));
  //   }
  // }, [dispatch, scenarioId, session, formGroups]);

  // const handleSubmit = useCallback(
  //   (formGroup: FormGroup) => {
  //     const formName = `${session.type}-${formGroup.id}`;
  //     if (isDirty(formName)(state)) {
  //       dispatch(updateAnswer({formName, scenarioId, session, formGroup}));
  //     } else {
  //       dispatch(stopSubmit(formName));
  //     }
  //   },
  //   [dispatch, state, scenarioId, session],
  // );
  const handleSubmit = () => {};
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const renderMedia = (fg: FormGroup) => {
    if (fg.image) {
      if (fg.mediaType === 'video') {
        return (
          <iframe
            width="560"
            height="315"
            src={fg.image}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        );
      } else if (fg.mediaType === 'image') {
        return <img className={classes.imgCenter} src={fg.image} />;
      }
    }
  };

  return (
    <div className={classes.container}>
      <Typography variant="h6">※ 치료적 의사소통 분석 단계</Typography>
      {session.sessionDescriptions.length > 0 && (
        <>
          <Typography>{session.sessionDescriptions[0].description}</Typography>
          <br />
        </>
      )}

      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="simple tabs example">
          {_.map(formGroups, (formGroup, index) => (
            <Tab
              key={`chart-tab-${formGroup.id}`}
              label={formGroup.name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>

      {_.map(formGroups, (formGroup, index) => (
        <TabPanel
          key={`tab-panel-${formGroup.id}`}
          className={classes.tabPanel}
          value={value}
          index={index}>
          <div
            key={`subject-form-${formGroup.id}`}
            className={classes.titleWrap}>
            <div>
              {formGroup.explain && (
                <div
                  dangerouslySetInnerHTML={{__html: formGroup.explain}}></div>
              )}

              {renderMedia(formGroup)}
              <img
                src="/images/psy/psy_comm_analysis.png"
                style={{width: '100%'}}
              />
            </div>

            {formGroup.type === FormGroupType.SUBJECTIVE && (
              <SubjectForm
                initialValues={formGroup}
                form={`${session.type}-${formGroup.id}`}
                onSubmit={handleSubmit}
              />
            )}
            {formGroup.type === FormGroupType.OBJECTIVE && (
              <QuizForm
                initialValues={{
                  ...formGroup,
                }}
                form={`${session.type}-${formGroup.id}`}
                seq={index}
                hideSeq
                onSubmit={handleSubmit}
              />
            )}
            {formGroup.type === FormGroupType.MULTIPLE && (
              <AdmPlanForm
                initialValues={formGroup}
                form={`${session.type}-${formGroup.id}`}
                showName
                onSubmit={handleSubmit}
              />
            )}
          </div>
        </TabPanel>
      ))}
    </div>
  );
};

export default CommAnalysisScreen;
