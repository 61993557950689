import {PayloadAction} from '@reduxjs/toolkit';
import {takeLatest, call, put, select} from 'redux-saga/effects';
import api from '~/api';
import {RootState} from '~/app/rootReducers';
import {Student} from '~/types';
import {COURSE} from '../Course/slice';
import {USER} from '../User/slice';
import {create, load, successLoad, update, uploadFile} from './slice';

function* handleLoad() {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const schoolId = yield select(
      (state: RootState) => state[USER].user?.schoolId,
    );
    const resp = yield call(api.getStudents, schoolId, courseId);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUploadFile({payload}: PayloadAction<FormData>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const schoolId = yield select(
      (state: RootState) => state[USER].user?.schoolId,
    );
    yield call(api.uploadStudents, schoolId, courseId, payload);
    yield put(load());
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUpdate({payload}: PayloadAction<Student>) {
  try {
    const resp = yield call(api.updateStudent, payload.id, payload);
    yield put(load());
  } catch (err) {
    console.log('error', err);
  }
}

function* handleCreate({payload}: PayloadAction<Student>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const schoolId = yield select(
      (state: RootState) => state[USER].user?.schoolId,
    );
    const resp = yield call(api.createStudent, courseId, schoolId, payload);
    yield put(load());
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchStudent() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(uploadFile, handleUploadFile);
  yield takeLatest(update, handleUpdate);
  yield takeLatest(create, handleCreate);
}
