import React, {useEffect} from 'react';
import {Field, reduxForm, InjectedFormProps} from 'redux-form';
import {Button, makeStyles} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import {useDispatch, useSelector} from 'react-redux';
import {closeSignupModal} from '~/features/Modal/slice';
import {SignupType} from '~/types';
import {renderSelectField, renderTextField} from './common';
import PasswordTextField from '~/components/PasswordTextField';
import * as yup from 'yup';
import validator from './validator';
import {load, SCHOOL} from '~/features/School/slice';
import {RootState} from '~/app/rootReducers';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    height: '100%',
    paddingLeft: 24,
    paddingRight: 26,
    marginTop: 24,
  },
  idStyle: {
    borderRadius: 2,
    fontSize: 14,
  },
  selectField: {
    width: 280,
    fontSize: 14,
    color: '#a7adb2',
    fontWeight: 400,
  },
  divBtn: {
    width: 283,
    padding: '5px 0 10px 0',
    marginBottom: 15,
    display: 'flex',
  },
  reset: {
    width: 69,
    height: 32,
    backgroundColor: '#F9FAFA',
    left: 130,
    top: 10,
    borderRadius: 2,
    textTransform: 'none',
  },
  submit: {
    width: 73,
    height: 32,
    left: 139,
    top: 10,
    color: '#ffffff',
    backgroundColor: '#2595FF',
    borderRadius: 2,
    textTransform: 'none',
  },
}));

const schema = yup.object({
  password: yup
    .string()
    .required('비밀번호를 입력해주세요')
    .min(8, '비밀번호를 최소 8자 이상 입력해주세요'),
  verifyPassword: yup
    .string()
    .oneOf([yup.ref('password')], '비밀번호가 일치하지 않습니다.')
    .required('비밀번호를 확인해주세요')
    .min(8, '비밀번호를 최소 8자 이상 입력해주세요'),
  username: yup
    .string()
    .email('잘못된 이메일 주소입니다')
    .required('이메일을 입력해주세요'),
  schoolId: yup
    .string()
    .required('학교를 선택해주세요')
    .test(
      'notSelect',
      '학교를 선택해주세요',
      (value) => value !== 'School Selection',
    ),
});

const Signup = (props: InjectedFormProps<SignupType>) => {
  const classes = useStyles();
  const {handleSubmit} = props;

  const dispatch = useDispatch();

  const schools = useSelector((state: RootState) => state[SCHOOL].schools);

  useEffect(() => {
    dispatch(load());
  }, [dispatch]);

  const handleClose = () => {
    dispatch(closeSignupModal());
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div>
        <Field
          onClose={handleClose}
          variant="outlined"
          required
          fullWidth
          id="username"
          InputProps={{
            className: classes.idStyle,
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
          margin="none"
          placeholder="E-mail"
          name="username"
          autoComplete="E-mail"
          autoFocus
          type="E-mail"
          size="small"
          component={renderTextField}
        />
      </div>
      <div>
        <Field
          component={PasswordTextField}
          name="password"
          id="password"
          placeholder="Password"
        />
      </div>

      <div>
        <Field
          component={PasswordTextField}
          name="verifyPassword"
          id="verifyPassword"
          placeholder="Confirm Password"
        />
      </div>
      <div>
        <Field
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="name"
          InputProps={{
            className: classes.idStyle,
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
          placeholder="Your Name"
          name="name"
          type="string"
          size="small"
          component={renderTextField}
        />
      </div>
      <div>
        <Field
          className={classes.selectField}
          name="schoolId"
          component={renderSelectField}
          label="School Selecton">
          {[
            <option key="school-0">School Selection</option>,
            ..._.map(schools, (s) => (
              <option key={`school-${s.id}`} value={s.id}>
                {s.name}
              </option>
            )),
          ]}
        </Field>
      </div>

      <div>
        <Field
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="studentId"
          InputProps={{
            className: classes.idStyle,
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
          placeholder="Student ID"
          name="studentId"
          type="string"
          size="small"
          component={renderTextField}
        />
      </div>
      <div className={classes.divBtn}>
        <Button
          onClick={() => dispatch(closeSignupModal())}
          className={classes.reset}
          type="reset"
          variant="outlined"
          disableElevation
          fullWidth>
          Cancel
        </Button>
        <Button
          className={classes.submit}
          type="submit"
          variant="contained"
          disableElevation
          fullWidth>
          Submit
        </Button>
      </div>
    </form>
  );
};

const SignupForm = reduxForm<SignupType>({
  // a unique name for the form
  form: 'Signup',
  asyncValidate: validator(schema),
  asyncChangeFields: ['password', 'verifyPassword', 'username', 'schoolId'],
})(Signup);

export default SignupForm;
