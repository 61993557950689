import React, {useEffect} from 'react';
import {Route, useRouteMatch} from 'react-router-dom';
import withTemplate from '~/hoc/withTemplate';
import CourseScreen from '~/screens/CourseScreen';
import ScenarioScreen from '~/screens/ScenarioScreen';

import {makeStyles, Theme, withStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import {styled} from '@material-ui/styles';
import {useDispatch} from 'react-redux';
import {clear} from '~/features/Session/slice';
import * as FormGroupAction from '~/features/FormGroup/slice';

const StyledStepLabel = styled(StepLabel)({
  '& .MuiTypography-body2': {
    fontWeight: 850,
  },
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: '31px 0px',
  },

  stepperWrap: {
    padding: '0 0 31px 0',
    width: 210,
    marginLeft: 90,
  },

  backButton: {
    marginRight: theme.spacing(1),
  },
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 15,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#2595FF',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#2595FF',
    },
  },
  line: {
    borderColor: '#DBDEDF',
    borderTopWidth: 2,
  },
})(StepConnector);

function getSteps() {
  return ['과정 선택', '시나리오 선택'];
}

const CourseRoutes = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const scenarioMatch = useRouteMatch({
    path: '/class/:courseId/',
  });

  useEffect(() => {
    dispatch(clear());
    dispatch(FormGroupAction.clear());
  }, []);

  useEffect(() => {
    if (match && scenarioMatch === null) {
      setActiveStep(0);
    } else {
      setActiveStep(1);
    }
  }, [match, scenarioMatch]);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  return (
    <div className={classes.root}>
      <Stepper
        connector={<QontoConnector />}
        className={classes.stepperWrap}
        activeStep={activeStep}
        alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StyledStepLabel>{label}</StyledStepLabel>
          </Step>
        ))}
      </Stepper>
      <Route exact path={`${match.path}`} component={CourseScreen} />
      <Route path={`${match.path}/:courseId`} component={ScenarioScreen} />
    </div>
  );
};

export default withTemplate(CourseRoutes);
