import * as FileSaver from 'file-saver';
import _ from 'lodash';
import * as XLSX from 'xlsx';

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

export const exportToCSV = (
  csvData: {[key: string]: any},
  fileName: string,
) => {
  const sheets = _.reduce(
    csvData,
    (result, csv, key) => {
      return {...result, [key]: XLSX.utils.json_to_sheet(csv)};
    },
    {},
  );
  console.log(sheets);
  const wb = {Sheets: sheets, SheetNames: _.keys(sheets)};
  const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
  const data = new Blob([excelBuffer], {type: fileType});
  FileSaver.saveAs(data, fileName + fileExtension);
};
