import {takeLatest, call, put, select} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import api from '~/api';
import {create, load, loadTmpl, successLoad, successLoadTmpl} from './slice';
import {CreateCourse} from '~/types';
import {USER} from '../User/slice';

function* handleLoad({payload: userId}: PayloadAction<number>) {
  try {
    const resp = yield call(api.getCoursesWithUserId, userId);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadTmp() {
  try {
    const resp = yield call(api.getCourses);
    yield put(successLoadTmpl(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleCreate({payload}: PayloadAction<CreateCourse>) {
  try {
    const user = yield select((state) => state[USER].user);
    const {tmplId, name} = payload;
    if (tmplId) {
      yield call(api.createCourseWithTmplId, user.id, tmplId, {
        name: name,
        schoolId: user.schoolId,
      });
      //const resp = yield call(api.getCoursesWithSchoolId, user.schoolId);
      //yield put(successLoad(resp.data));
    } else {
      // yield call(api.createCourse, user.id, {
      //   name: name,
      // });
      //const resp = yield call(api.getCoursesWithUserId, user.id);
      //yield put(successLoad(resp.data));
    }
    yield put(load(user.id));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchCourse() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(loadTmpl, handleLoadTmp);
  yield takeLatest(create, handleCreate);
}
