import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Answer} from '~/types';

interface State {
  loading: boolean;
  answers: Answer[];
}

const initialState: State = {
  loading: false,
  answers: [],
};

const slice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    load: (
      state,
      _action: PayloadAction<{
        scenarioId: number;
        sessionId: number;
        userId?: number;
      }>,
    ) => {
      state.loading = true;
    },
    loadSuccess: (state, {payload}: PayloadAction<Answer[]>) => {
      state.loading = false;
      state.answers = payload;
    },
  },
});

export const {load, loadSuccess} = slice.actions;
export const ANSWER = slice.name;
export default slice.reducer;
