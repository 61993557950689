import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CreateSessionTime, SessionTime, SessionTimeStat} from '~/types';

interface State {
  loading: boolean;
  sessionTime?: SessionTime;
  sessionTimeStat: SessionTimeStat[];
}

let initialState: State = {
  loading: false,
  sessionTime: undefined,
  sessionTimeStat: [],
};

const slice = createSlice({
  name: 'session-time',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<SessionTime>) => {
      state.loading = false;
      state.sessionTime = payload;
    },
    successLoadStat: (state, {payload}: PayloadAction<SessionTimeStat[]>) => {
      state.loading = false;
      state.sessionTimeStat = payload;
    },
    create: (state, _action: PayloadAction<CreateSessionTime>) => {
      state.loading = true;
    },
    update: (state) => {
      state.loading = true;
    },
    clear: (state) => {
      state.loading = false;
      state.sessionTime = undefined;
    },
  },
});

export const {load, update, create, clear, successLoad, successLoadStat} =
  slice.actions;
export const SESSIONTIME = slice.name;
export default slice.reducer;
