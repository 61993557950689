import {
  takeLatest,
  call,
  put,
  select,
  actionChannel,
  take,
} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import api from '~/api';
import {
  clear,
  create,
  load,
  SESSIONTIME,
  successLoad,
  successLoadStat,
  update,
} from './slice';
import {CreateSessionTime} from '~/types';
import {RootState} from '~/app/rootReducers';
import {USER} from '../User/slice';
import {COURSE} from '../Course/slice';

function* handleCreate({payload}: PayloadAction<CreateSessionTime>) {
  try {
    const resp = yield call(api.createSessionTime, payload);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUpdate() {
  try {
    console.log('handleUpdate');
    const sessionTime = yield select(
      (state: RootState) => state[SESSIONTIME].sessionTime,
    );
    console.log('handleUpdate', sessionTime);
    if (sessionTime) {
      const resp = yield call(api.updateSessionTime, sessionTime.id);
      yield put(clear());
    }
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoad({payload}: PayloadAction<number>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const resp = yield call(api.getSessionTimeStat, courseId, payload);
    yield put(successLoadStat(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchSessionTime() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(create, handleCreate);
  yield takeLatest(update, handleUpdate);

  // const requestChan = yield actionChannel([create, update]);
  // while (true) {
  //   const {payload, type} = yield take(requestChan);
  //   console.log(
  //     'watchSessionTime',
  //     type,
  //     payload,
  //     create.type,
  //     update.type,
  //     type === create.type,
  //     type === update.type,
  //   );
  //   switch (type) {
  //     case create.type:
  //       yield call(handleCreate, {type, payload});
  //       break;
  //     case update.type:
  //       yield call(handleUpdate);
  //       break;
  //   }
  // }

  // yield takeLatest(update, handleUpdate);
}
