import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserInfo} from '~/types';

interface State {
  loading: boolean;
  userInfo: UserInfo | undefined;
}

let initialState: State = {
  loading: false,
  userInfo: undefined,
};

const slice = createSlice({
  name: 'user-info',
  initialState,
  reducers: {
    load: (state) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<UserInfo>) => {
      state.loading = false;
      state.userInfo = payload;
    },
    create: (state, _action: PayloadAction<UserInfo>) => {
      state.loading = true;
    },
    update: (state, _action: PayloadAction<UserInfo>) => {
      state.loading = true;
    },
  },
});

export const {load, successLoad, create, update} = slice.actions;
export const USERINFO = slice.name;
export default slice.reducer;
