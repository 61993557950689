import React from 'react';
import withTemplate from '~/hoc/withTemplate';
import LoginModal from '~/modals/LoginModal';
import PasswordModal from '~/modals/PasswordModal';
import SignupModal from '~/modals/SignupModal';
import {useHistory, useLocation} from 'react-router-dom';
import {makeStyles, Paper, Typography} from '@material-ui/core';

import mainBg from '~/assets/main_bg.jpg';
import PasswordResetForm from '~/forms/PasswordResetForm';
import {IPasswordReset} from '~/types';
import queryString from 'query-string';
import {useDispatch} from 'react-redux';
import {resetPassword} from '~/features/User/slice';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    backgroundImage: `url(${mainBg})`,
    backgroundPosition: 'center center',
    justifyContent: 'center',
  },
  container: {
    backgroundColor: 'white',
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    padding: 16,
  },
  btn: {
    alignSelf: 'center',
    width: 118,
    marginBottom: 10,
  },
}));

const PasswordResetScreen = () => {
  const classes = useStyle();
  const location = useLocation();
  const dispatch = useDispatch();
  const query = queryString.parse(location.search);
  const history = useHistory();

  const handleSubmit = (passwordReset: IPasswordReset) => {
    dispatch(resetPassword({...passwordReset, history}));
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.container}>
        <Typography>비밀번호 초기화</Typography>
        <PasswordResetForm
          initialValues={{token: query.token as string}}
          onSubmit={handleSubmit}
        />
      </Paper>
      <LoginModal />
      <PasswordModal />
      <SignupModal />
    </div>
  );
};
export default withTemplate(PasswordResetScreen);
