import React from 'react';
import {Field, reduxForm, InjectedFormProps} from 'redux-form';
import {makeStyles} from '@material-ui/core';
import {LoginType} from '~/types';
import PasswordField from '../components/PasswordField';
import PersonIconField from '~/components/PersonIconField';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },

  fieldWrap: {
    padding: '24px 24px 16px 24px',
  },

  email: {
    marginBottom: 16,
  },
}));

const Login = (props: InjectedFormProps<LoginType>) => {
  const classes = useStyles();
  const {handleSubmit} = props;

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div className={classes.fieldWrap}>
        <div>
          <Field
            component={PersonIconField}
            className={classes.email}
            name="username"
            id="username"
            placeholder="E-mail"
            autoComplete="username"
          />
        </div>
        <div>
          <Field
            component={PasswordField}
            name="password"
            id="password"
            placeholder="Password"
          />
        </div>
      </div>
    </form>
  );
};

const LoginForm = reduxForm<LoginType>({
  // a unique name for the form
  form: 'login',
})(Login);

export default LoginForm;
