import {call, put, takeLatest} from 'redux-saga/effects';
import api from '~/api';
import {load, successLoad} from './slice';

function* handleLoad() {
  try {
    const resp = yield call(api.getBoards);
    // yield put()
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchBoard() {
  yield takeLatest(load, handleLoad);
}
