import React from 'react';
import {makeStyles, Modal, Paper, Typography} from '@material-ui/core';
import {closeScoreSettingModal, MODAL} from '~/features/Modal/slice';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {FormGroup, FormGroupType, Session, UserRoleType} from '~/types';
import SubjectForm from '~/forms/SubjectForm';
import Quiz from '~/components/Quiz';
import {USER} from '~/features/User/slice';
import _ from 'lodash';
import AdmPlanForm from '~/forms/AdmPlanForm';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'Translate(-50%,-50%)',
    width: '100vw',
    height: '100vh',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  closeIcon: {
    fontSize: 50,
    position: 'absolute',
    top: 5,
    right: 5,
    float: 'right',
    cursor: 'pointer',
  },
  contextArea: {
    margin: 'auto',
    marginBottom: 50,
    width: '90%',
    boxShadow: '0.2px solid rgba(0, 0, 0, 1)',
  },
  titleStyle: {
    fontWeight: 700,
    margin: '30px 0 15px 60px',
    width: '90%',
  },
}));

interface Props {
  problems: FormGroup[];
  open: boolean;
  session?: Session;
  onClose: () => void;
}

const GradingProblemModal = ({problems, open, session, onClose}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const role = useSelector((state: RootState) => state[USER].user?.role.role);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper elevation={0} className={classes.paper}>
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
        {_.map(problems, (problem) => {
          // console.log('problem', problem);
          return (
            <div key={`problem-${problem.id}`}>
              <Typography variant="h6" className={classes.titleStyle}>
                ※ {problem.name}
              </Typography>
              <div className={classes.contextArea}>
                {problem.type === FormGroupType.SUBJECTIVE && (
                  <SubjectForm
                    initialValues={problem}
                    form={`formgroups-${problem.id}`}
                  />
                )}
                {problem.type === FormGroupType.OBJECTIVE &&
                  role &&
                  session && (
                    <Quiz
                      session={session}
                      formGroup={problem}
                      showAnswer={role === UserRoleType.PROF}
                      index={0}
                    />
                  )}

                {problem.type === FormGroupType.MULTIPLE && (
                  <AdmPlanForm
                    initialValues={problem}
                    showName
                    form={`formgroups-${problem.id}`}
                  />
                )}
              </div>
            </div>
          );
        })}
      </Paper>
    </Modal>
  );
};

export default GradingProblemModal;
