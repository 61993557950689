import {PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {takeLatest, call, put, select} from 'redux-saga/effects';
import api from '~/api';
import {RootState} from '~/app/rootReducers';
import {FormGroupType, Session} from '~/types';
import * as FormCategoryAction from '../FormCategory/slice';
import {COURSE} from '../Course/slice';
import {USER} from '../User/slice';
import {
  AttachForm,
  attachForms,
  clearLoading,
  detachForms,
  load,
  SaveAnswerPayload,
  SaveFormGroupPayload,
  successLoad,
  update,
  updateAnswer,
  UpdateScore,
  updateScore,
} from './slice';

function* handleLoad({
  payload: {scenarioId, session},
}: PayloadAction<{scenarioId: number; session: Session}>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );

    const sessionId = session.id;
    const resp = yield call(
      api.getFormGroupsWithScenarioSessionId,
      scenarioId,
      sessionId,
      courseId,
    );

    yield put(successLoad({type: session.type, formGroups: resp.data}));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUpdateAnswer({
  payload: {scenarioId, session, formGroups},
}: PayloadAction<SaveAnswerPayload>) {
  try {
    const userId = yield select((state: RootState) => state[USER].user?.id);
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );

    for (let i = 0; i < formGroups.length; i++) {
      const formGroup = formGroups[i];

      const formGroupId = formGroup.id;
      const sessionId = session.id;

      if (formGroup.type === FormGroupType.SUBJECTIVE) {
        yield Promise.all(
          _.map(formGroup.forms, (form) => {
            if (form.answers && form.answers.length > 0) {
              if (form.answers[0].id) {
                return api.updateAnswer(form.answers[0]);
              } else if (!form.isTitleOnly) {
                let answer = {
                  userId,
                  courseId,
                  formGroupId,
                  scenarioId,
                  sessionId,
                  formId: form.id,
                  content: form.answers[0].content,
                };
                if (formGroup.formCategoryId) {
                  return api.createAnswer({
                    ...answer,
                    formCategoryId: formGroup.formCategoryId,
                  });
                } else {
                  return api.createAnswer(answer);
                }
              }
            }
          }),
        );
      } else if (formGroup.type === FormGroupType.OBJECTIVE) {
        if (formGroup.uanswer) {
          if (formGroup.uanswer.id) {
            api.updateAnswer(_.pickBy(formGroup.uanswer, _.identity));
          } else {
            api.createAnswer({
              userId,
              courseId,
              formGroupId,
              scenarioId,
              sessionId,
              content: formGroup.uanswer.content,
            });
          }
        }
      } else if (formGroup.type === FormGroupType.MULTIPLE) {
        const maxTrial = formGroup.trial ?? 0;

        yield Promise.all(
          _.map(formGroup.forms, (form) => {
            if (form.answers && form.answers[0]) {
              // if (form.answers[0].id) {
              //   if (form.answers[0].content) {
              //     return api.updateAnswer({
              //       ...form.answers[0],
              //       trial: maxTrial + 1,
              //       content: form.answers[0].content.toString(),
              //     });
              //   }
              // } else
              if (form.answers[0].content !== undefined) {
                return api.createAnswer({
                  userId,
                  courseId,
                  formGroupId,
                  scenarioId,
                  sessionId,
                  randomFormSetId: formGroup.setId,
                  formId: form.id,
                  trial: maxTrial + 1,
                  content: form.answers[0].content.toString(),
                });
              }
            }
          }),
        );
      }
    }
    yield put(clearLoading());
    // yield put(stopSubmit(formName));
    // yield put(load({scenarioId, session}));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUpdateScore({
  payload: {score, formGroup, scenarioId, session},
}: PayloadAction<UpdateScore>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );

    if (score.id) {
      yield call(api.updateScore, {...score, scenarioId});
    } else {
      yield call(api.createScoreWithFromGroupId, formGroup.id, {
        ...score,
        sessionId: session.id,
        courseId,
        scenarioId,
      });
    }

    yield put(load({scenarioId, session}));
    yield put(FormCategoryAction.load({scenarioId, session}));
  } catch (err) {
    console.log('err', err);
  }
}

function* handleAttachForms({payload}: PayloadAction<AttachForm>) {
  try {
    const {session, scenarioId, ...rest} = payload;
    yield call(api.attachForms, rest);
    yield put(load({scenarioId, session}));
  } catch (err) {
    console.log('err', err);
  }
}

function* handleDetachForms({payload}: PayloadAction<AttachForm>) {
  try {
    const {session, scenarioId, ...rest} = payload;
    yield call(api.detachForms, rest);
    yield put(load({scenarioId, session}));
  } catch (err) {
    console.log('err', err);
  }
}

function* handleUpdate({
  payload: {scenarioId, session, formGroup},
}: PayloadAction<SaveFormGroupPayload>) {
  try {
    const {id, ...rest} = formGroup;
    yield call(
      api.updateFormGroup,
      id,
      _.chain(rest)
        .pickBy((p) => p !== null && p !== undefined)
        .omit(['scores'])
        .value(),
    );
    yield put(load({scenarioId, session}));
  } catch (err) {
    console.log('err', err);
  }
}

export function* watchFormGroup() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(update, handleUpdate);
  yield takeLatest(updateAnswer, handleUpdateAnswer);
  yield takeLatest(updateScore, handleUpdateScore);
  yield takeLatest(attachForms, handleAttachForms);
  yield takeLatest(detachForms, handleDetachForms);
}
