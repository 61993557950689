import {takeLatest, call, put, select} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import api from '~/api';
import {create, load, successLoad} from './slice';
import {CreateScenario} from '~/types';
import {RootState} from '~/app/rootReducers';
import {COURSE} from '../Course/slice';

function* handleLoad({payload: courseId}: PayloadAction<number>) {
  try {
    const resp = yield call(api.getScenariosWithCourselId, courseId);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleCreate({payload}: PayloadAction<CreateScenario>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    if (courseId) {
      yield call(api.createScenario, courseId, payload);
      const resp = yield call(api.getScenariosWithCourselId, courseId);
      yield put(successLoad(resp.data));
    }
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchScenario() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(create, handleCreate);
}
