import {combineReducers} from '@reduxjs/toolkit';
import {reducer as formReducer} from 'redux-form';
import answerReducer, {ANSWER} from '~/features/Answers/slice';
import boardReducer, {BOARD} from '~/features/Board/slice';
import candidateFCReducer, {CANDIDATEFC} from '~/features/CandidateFC/slice';
import candidateFGReducer, {CANDIDATEFG} from '~/features/CandidateFG/slice';
import candidateFormReducer, {
  CANDIDATEFORM,
} from '~/features/CandidateForm/slice';
import chartReducer, {CHART} from '~/features/Chart/slice';
import courseReducer, {COURSE} from '~/features/Course/slice';
import formBasisReducer, {FORMBASIS} from '~/features/FormBasis/slice';
import formCategoriesReducer, {
  FORMCATEGORIES,
} from '~/features/FormCategory/slice';
import formGroupReducer, {FORMGROUPS} from '~/features/FormGroup/slice';
import formsReducer, {FORMS} from '~/features/Forms/slice';
import loginReduer, {LOGIN} from '~/features/Login/slice';
import modalReducer, {MODAL} from '~/features/Modal/slice';
import postReducer, {POST} from '~/features/Post/slice';
import poReducer, {PO} from '~/features/ProblemOutcome/slice';
import scenarioReducer, {SCENARIO} from '~/features/Scenario/slice';
import schoolReducer, {SCHOOL} from '~/features/School/slice';
import sessionReducer, {SESSION} from '~/features/Session/slice';
import sessionTimeReducer, {SESSIONTIME} from '~/features/SessionTime/slice';
import studentReducer, {STUDENT} from '~/features/Student/slice';
import toastReducer, {TOAST} from '~/features/Toast/slice';
import userReducer, {USER} from '~/features/User/slice';
import userInfoReducer, {USERINFO} from '~/features/UserInfo/slice';
import userScoreReducer, {USERSCORE} from '~/features/UserScore/slice';

const rootReducer = combineReducers({
  [LOGIN]: loginReduer,
  [MODAL]: modalReducer,
  [USER]: userReducer,
  [COURSE]: courseReducer,
  [SCENARIO]: scenarioReducer,
  [SESSION]: sessionReducer,
  [SCHOOL]: schoolReducer,
  [PO]: poReducer,
  [FORMGROUPS]: formGroupReducer,
  [FORMCATEGORIES]: formCategoriesReducer,
  [STUDENT]: studentReducer,
  [CANDIDATEFG]: candidateFGReducer,
  [CANDIDATEFORM]: candidateFormReducer,
  [FORMS]: formsReducer,
  [FORMBASIS]: formBasisReducer,
  [CANDIDATEFC]: candidateFCReducer,
  [CHART]: chartReducer,
  [BOARD]: boardReducer,
  [POST]: postReducer,
  [USERINFO]: userInfoReducer,
  [SESSIONTIME]: sessionTimeReducer,
  [ANSWER]: answerReducer,
  [USERSCORE]: userScoreReducer,
  [TOAST]: toastReducer,
  form: formReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
