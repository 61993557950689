import {takeLatest, call, put} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {login, logout, successLogin, failLogin} from './slice';
import {LoginType} from '~/types';
import api from '~/api';
import {clear} from '../User/slice';
import {closeLoginModal} from '../Modal/slice';

function* handleLogin({payload}: PayloadAction<LoginType>) {
  try {
    const resp = yield call(api.login, payload);
    yield put(successLogin(resp.data));
    yield put(closeLoginModal());
  } catch (err) {
    console.log('error', err);
    yield put(failLogin({error: ''}));
  }
}

function* handleLogout() {
  try {
    yield put(clear());
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchLogin() {
  yield takeLatest(login, handleLogin);
  yield takeLatest(logout, handleLogout);
}
