import {LinearProgress, makeStyles, Typography} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isDirty, stopSubmit} from 'redux-form';
import {RootState} from '~/app/rootReducers';
import {FORMGROUPS, load, updateAnswer} from '~/features/FormGroup/slice';
import AdmPlanForm from '~/forms/AdmPlanForm';
import QuizForm from '~/forms/QuizForm';
import SubjectForm from '~/forms/SubjectForm';
import {FormGroup, FormGroupType, Session} from '~/types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
  },
  titleStyle: {
    fontSize: 20,
    fontWeight: 700,
  },
  titleWrap: {
    '&:nth-child(n+2)': {
      marginTop: 40,
    },
  },
  imgCenter: {
    display: 'block',
    margin: 'auto',
  },
  textCenter: {
    textAlign: 'center',
  },
}));

interface Props {
  scenarioId: number;
  session: Session;
  showNumber?: boolean;
}

const AsIsToBeScreen = ({scenarioId, session, showNumber}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const fgs = useSelector((state: RootState) => state[FORMGROUPS].formGroups);
  const loading = useSelector((state: RootState) => state[FORMGROUPS].loading);
  const [formGroups, setFormGroups] = useState<FormGroup[]>([]);

  useEffect(() => {
    setFormGroups(fgs[session.type]);
  }, [fgs, session]);

  // useEffect(() => {
  //   dispatch(load({scenarioId, session}));
  // }, [dispatch, scenarioId, session]);

  // const handleSubmit = useCallback(
  //   (formGroup: FormGroup) => {
  //     const formName = `${session.type}-${formGroup.id}`;
  //     if (isDirty(formName)(state)) {
  //       dispatch(updateAnswer({formName, scenarioId, session, formGroup}));
  //     } else {
  //       dispatch(stopSubmit(formName));
  //     }
  //   },
  //   [dispatch, state, scenarioId, session],
  // );

  const handleSubmit = () => {};

  return (
    <div className={classes.root}>
      {session.sessionDescriptions.length > 0 && (
        <>
          <Typography>{session.sessionDescriptions[0].description}</Typography>
          <br />
        </>
      )}
      {loading && <LinearProgress />}
      {_.map(formGroups, (formGroup, index) => (
        <div key={`subject-form-${formGroup.id}`} className={classes.titleWrap}>
          <div>
            <Typography variant="body1">
              {showNumber && `${index + 1}. `}
              {formGroup.name}
            </Typography>
            {formGroup.explain && (
              <p className={classes.textCenter}>{formGroup.explain}</p>
            )}
            {formGroup.image && (
              <img className={classes.imgCenter} src={formGroup.image} />
            )}
          </div>

          {formGroup.type === FormGroupType.SUBJECTIVE && (
            <SubjectForm
              initialValues={formGroup}
              form={`${session.type}-${formGroup.id}`}
              onSubmit={handleSubmit}
            />
          )}
          {formGroup.type === FormGroupType.OBJECTIVE && (
            <QuizForm
              initialValues={{
                ...formGroup,
              }}
              form={`${session.type}-${formGroup.id}`}
              onSubmit={handleSubmit}
              seq={index}
              hideSeq
            />
          )}
          {formGroup.type === FormGroupType.MULTIPLE && (
            <AdmPlanForm
              initialValues={formGroup}
              form={`${session.type}-${formGroup.id}`}
              onSubmit={handleSubmit}
              showName
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default AsIsToBeScreen;
