import React, {useEffect} from 'react';
import {makeStyles, Modal, Paper} from '@material-ui/core';
import {closeAdmPlanModal, MODAL} from '~/features/Modal/slice';
import CloseIcon from '@material-ui/icons/Close';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {Session} from '~/types';
import {load} from '~/features/FormGroup/slice';
import AdmPlanScreen from '~/screens/AdmPlanScreen';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'Translate(-50%,-50%)',
    width: '1200px',
    height: '824px',
    overflow: 'scroll',
    overflowX: 'hidden',
  },
  closeIcon: {
    fontSize: 50,
    position: 'absolute',
    top: 5,
    right: 5,
    float: 'right',
    cursor: 'pointer',
  },
}));

interface Props {
  scenarioId: number;
  session: Session;
}

const AdmPlanModal = ({session, scenarioId}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector((state: RootState) => state[MODAL].admPlanModal);

  useEffect(() => {
    if (session) {
      dispatch(load({scenarioId, session}));
    }
  }, [dispatch, session, scenarioId]);

  const handleClose = () => {
    dispatch(closeAdmPlanModal());
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper elevation={0} className={classes.paper}>
        <CloseIcon className={classes.closeIcon} onClick={handleClose} />
        <AdmPlanScreen session={session} scenarioId={scenarioId} />
      </Paper>
    </Modal>
  );
};

export default AdmPlanModal;
