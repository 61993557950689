import {
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {load, PO} from '~/features/ProblemOutcome/slice';
import * as UserInfoAction from '~/features/UserInfo/slice';
import IntroInfoForm from '~/forms/IntroInfoForm';
import IntroSylla from '~/components/IntroSyllabusField';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {Scenario, ScenarioType, Session, UserInfo} from '~/types';
import {USERINFO} from '~/features/UserInfo/slice';

const useStyles = makeStyles({
  container: {
    width: '90%',
    margin: 'auto',
    marginTop: '20px',
  },
  addInfoTitle: {
    fontWeight: 700,
    marginBottom: 12,
    '&:nth-child(n+2)': {
      marginTop: 50,
    },
  },
  tableContainer: {
    backgroundColor: '#E7F5FC',
    margin: 'auto',
    marginBottom: 50,
    padding: 40,
    boxShadow: '0 0 0 0.2px #000 inset',
  },
  tableWrap: {
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  tblHead: {
    backgroundColor: '#F3F4F4',
    fontSize: 16,
  },
  tblCircle: {
    width: 8,
    color: '#33BE63',
    position: 'relative',
    top: 8,
    left: -5,
  },
  tblHeadPO: {
    width: '50%',
    textAlign: 'center',
  },
  tblHeadSession: {
    textAlign: 'center',
    boxShadow: '-1px 2px 3px 0px rgba(0, 0, 0, 0.25)',
  },
  tblBody: {
    backgroundColor: '#fff',
  },
  tblBodyCell: {
    fontSize: 14,
  },
});

interface Props {
  scenario: Scenario;
  session: Session;
  userInfo?: UserInfo;
}

const IntroScreen = ({scenario, session}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const po = useSelector((state: RootState) => state[PO].pos);
  const userInfo = useSelector((state: RootState) => state[USERINFO].userInfo);

  useEffect(() => {
    dispatch(load(scenario.id));
  }, [dispatch, scenario]);

  useEffect(() => {
    dispatch(UserInfoAction.load());
  }, [dispatch]);

  const handleSubmitInfo = (info: UserInfo) => {
    if (userInfo) {
      dispatch(UserInfoAction.update(info));
    } else {
      dispatch(UserInfoAction.create(info));
    }
  };

  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.addInfoTitle}>
        ※ 학생정보 입력
      </Typography>

      <IntroInfoForm
        onSubmit={handleSubmitInfo}
        type={scenario.type}
        initialValues={userInfo}
      />

      {session.sessionFile && (
        <>
          <Typography variant="h6" className={classes.addInfoTitle}>
            ※ 강의계획서
          </Typography>

          <IntroSylla session={session} />
        </>
      )}

      <Typography variant="h6" className={classes.addInfoTitle}>
        ※ 학습성과연계표
        <Typography>
          - 학생은 본{' '}
          {scenario.type === ScenarioType.ADM ? '간호관리' : '정신간호'}{' '}
          시뮬레이션을 통해 아래와 같이 한국간호교육평가원의 학습성과 PO를
          달성하게 됩니다.
        </Typography>
      </Typography>
      <Container className={classes.tableContainer}>
        <Table className={classes.tableWrap}>
          <TableHead className={classes.tblHead}>
            <TableCell className={classes.tblHeadPO}>
              <FiberManualRecordIcon className={classes.tblCircle} />
              학습성과 PO
            </TableCell>
            <TableCell className={classes.tblHeadSession}>
              <FiberManualRecordIcon className={classes.tblCircle} />
              SESSION 활동
            </TableCell>
          </TableHead>
          {_.sortBy(po, ['seq']).map((pos) => {
            let sessionStore = _.chain(pos.sessions)
              .sortBy('seq')
              .map((s) => (
                <div key={`po-${s.id}`}>
                  {s.seq}. {s.name.replace('\\r\\n', ' ')}
                  <br />
                </div>
              ))
              .value();
            return (
              <TableBody key={`po-table-${pos.id}`} className={classes.tblBody}>
                <TableCell className={classes.tblBodyCell}>
                  {`${pos.seq}. ${pos.name}`}
                </TableCell>
                <TableCell className={classes.tblBodyCell}>
                  {sessionStore}
                </TableCell>
              </TableBody>
            );
          })}
        </Table>
      </Container>
    </div>
  );
};

export default IntroScreen;
