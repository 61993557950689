import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FormBasis, FormGroup, ScenarioType} from '~/types';

export interface CreateFormGroup {
  scenarioId: number;
  scenarioType: ScenarioType;
  sessionId: number;
  formGroup: FormGroup;
}

interface State {
  loading: boolean;
  formBasis: FormBasis[];
}

let initialState: State = {
  loading: false,
  formBasis: [],
};

const slice = createSlice({
  name: 'form-basis',
  initialState,
  reducers: {
    load: (state) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<FormBasis[]>) => {
      state.loading = false;
      state.formBasis = payload;
    },
  },
});

export const {load, successLoad} = slice.actions;
export const FORMBASIS = slice.name;
export default slice.reducer;
