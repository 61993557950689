import {Field, WrappedFieldArrayProps, WrappedFieldProps} from 'redux-form';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {Form} from '~/types';
import _ from 'lodash';
import {Autocomplete} from '@material-ui/lab';

interface FieldProps {
  label: string;
  titleStyle: string;
}

interface RadioProps {
  items: Form[];
  buttonStyle: string;
  hideSeq?: boolean;
  readOnly?: boolean;
}

interface CheckProps {
  checkStyle: string;
}

export const renderRadioButton = ({
  input,
  items,
  buttonStyle,
  hideSeq,
  readOnly,
  ...rest
}: WrappedFieldProps & RadioProps) => {
  return (
    <FormControl>
      <RadioGroup {...input} {...rest} value={parseInt(input.value)}>
        {_.map(items, (v, index) => {
          return (
            <FormControlLabel
              key={`radio-btn-${v.id}`}
              className={buttonStyle}
              value={v.id}
              control={
                <Radio size="small" color="primary" readOnly={readOnly} />
              }
              label={hideSeq ? v.name : `${index + 1}. ${v.name}`}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

interface TextareaFormsProps {
  txtAreaStyle: string;
  readOnly?: boolean;
}

export const renderTextareaAutosize = ({
  input,
  txtAreaStyle,
  readOnly,
}: WrappedFieldProps & FieldProps & TextareaFormsProps) => {
  return (
    <TextareaAutosize
      readOnly={readOnly}
      className={txtAreaStyle}
      rowsMin={2}
      {...input}
    />
  );
};

interface TextFormsProps {
  typoStyle: string;
  txtFieldStyle: string;
  txtAreaStyle: string;
  readOnly?: boolean;
}

export const renderTextFormsField = ({
  fields,
  typoStyle,
  txtFieldStyle,
  txtAreaStyle,
  readOnly,
}: WrappedFieldArrayProps<Form> & TextFormsProps) => {
  return (
    <div>
      {fields.map((form, index) => {
        const item = fields.get(index);
        let multiTxtArea = false;
        if (item.isMultiline === true) {
          multiTxtArea = true;
        }
        return (
          <Grid key={`text-forms-${index}`} container>
            {item.name && (
              <Grid
                item
                xs={
                  item.name
                    ? item.isTitleOnly || item.isMultiline
                      ? 12
                      : 3
                    : 12
                }>
                <Typography
                  variant="body1"
                  className={typoStyle}
                  style={item.isTitleOnly ? {textAlign: 'center'} : {}}>
                  {_.split(item.name, '\\r\\n').map((s, index) => (
                    <div key={`name-${index}`}>{s}</div>
                  ))}
                </Typography>
              </Grid>
            )}

            {!item.isTitleOnly &&
              (multiTxtArea ? (
                <Grid item xs={12}>
                  <Field
                    txtAreaStyle={txtAreaStyle}
                    readOnly={readOnly}
                    name={`${form}.answers[0].content`}
                    component={renderTextareaAutosize}
                  />
                </Grid>
              ) : (
                <Grid item xs={item.name ? 9 : 12}>
                  <Field
                    className={txtFieldStyle}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    name={`${form}.answers[0].content`}
                    readOnly={readOnly}
                    component={renderTextField}
                  />
                </Grid>
              ))}
          </Grid>
        );
      })}
    </div>
  );
};

export const renderTypoField = ({
  input: {value},
  titleStyle,
}: WrappedFieldProps & FieldProps) => (
  <div>
    <Typography className={titleStyle}>{value}</Typography>
  </div>
);

export const renderTextField = ({
  label,
  input,
  meta: {touched, invalid, error},
  ...custom
}: WrappedFieldProps & FieldProps) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
);

export const renderSelectionField = ({
  label,
  input,
  meta: {touched, invalid, error},
  options,
  ...custom
}: any) => {
  console.log(options);
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option: any) => {
        console.log('option', option);
        return option.name;
      }}
      onChange={(e) => console.log('evt', e)}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={label}
          margin="dense"
          variant="outlined"
          required
          fullWidth
          size="small"
        />
      )}
      {...input}
      {...custom}
    />
  );
};

const renderFromHelper = ({touched, error}: any) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>;
  }
};

export const renderSelectField = ({
  input,
  label,
  meta: {touched, error},
  children,
  ...custom
}: any) => (
  <FormControl variant="outlined" margin="dense" error={touched && error}>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: input.name,
        id: 'color-native-simple',
      }}>
      {children}
    </Select>
    {renderFromHelper({touched, error})}
  </FormControl>
);

export const renderCheckbox = ({
  input: {value, onChange},
  label,
  checkStyle,
}: any & CheckProps) => {
  // console.log('renderCheckbox', label, value);
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            className={checkStyle}
            checked={value === true || value === 'true' ? true : false}
            onChange={onChange}
            color="primary"
            size="small"
          />
        }
        label={label}
      />
    </div>
  );
};
