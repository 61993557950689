import {
  AppBar,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import TabPanel from '~/components/TabPanel';
import {FORMGROUPS} from '~/features/FormGroup/slice';
import AdmPlanForm from '~/forms/AdmPlanForm';
import QuizForm from '~/forms/QuizForm';
import SubjectForm from '~/forms/SubjectForm';
import {FormGroup, FormGroupType, Session} from '~/types';

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
    position: 'relative',
  },
  titleStyle: {
    fontSize: 20,
    fontWeight: 700,
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.2)',
  },
  tabPanel: {
    overflow: 'auto',
  },
  titleWrap: {
    '&:nth-child(n+2)': {
      marginTop: 40,
    },
  },
  imgCenter: {
    display: 'block',
    margin: 'auto',
  },
  textCenter: {
    textAlign: 'center',
  },
}));

interface Props {
  scenarioId: number;
  session: Session;
  abbr?: boolean;
  abbrPrefix?: string;
  title?: string;
}

const SubjectScreen = ({
  scenarioId,
  session,
  abbr,
  abbrPrefix,
  title,
}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const fgs = useSelector((state: RootState) => state[FORMGROUPS].formGroups);
  const loading = useSelector((state: RootState) => state[FORMGROUPS].loading);
  const [formGroups, setFormGroups] = useState<FormGroup[]>([]);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    setFormGroups(fgs[session.type]);
  }, [fgs, session]);

  // useEffect(() => {
  //   dispatch(load({scenarioId, session}));
  // }, [dispatch, scenarioId, session]);

  // const handleSubmit = useCallback(
  //   (formGroup: FormGroup) => {
  //     const formName = `${session.type}-${formGroup.id}`;
  //     if (isDirty(formName)(state)) {
  //       dispatch(updateAnswer({formName, scenarioId, session, formGroup}));
  //     } else {
  //       dispatch(stopSubmit(formName));
  //     }
  //   },
  //   [dispatch, state, scenarioId, session],
  // );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleSubmit = () => {};

  return (
    <div className={classes.root}>
      {title && <Typography className={classes.titleStyle}>{title}</Typography>}
      {session.sessionDescriptions.length > 0 && (
        <>
          <Typography>{session.sessionDescriptions[0].description}</Typography>
          <br />
        </>
      )}

      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="simple tabs example">
          {_.map(formGroups, (fomrGroup, index) => (
            <Tab
              key={`chart-tab-${fomrGroup.id}`}
              label={
                abbr
                  ? `${abbrPrefix || '과제'} ${index + 1}`
                  : `${index + 1}. ${fomrGroup.name}`
              }
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        {loading && <LinearProgress />}
      </AppBar>
      {_.map(formGroups, (formGroup, index) => (
        <TabPanel
          key={`tab-panel-${formGroup.id}`}
          className={classes.tabPanel}
          value={value}
          index={index}>
          <div
            key={`subject-form-${formGroup.id}`}
            className={classes.titleWrap}>
            <div>
              {abbr && <Typography variant="h6">{formGroup.name}</Typography>}
              {formGroup.explain && (
                <div
                  dangerouslySetInnerHTML={{__html: formGroup.explain}}></div>
              )}
              {formGroup.image && (
                <img className={classes.imgCenter} src={formGroup.image} />
              )}
            </div>

            {formGroup.type === FormGroupType.SUBJECTIVE && (
              <SubjectForm
                form={`${session.type}-${formGroup.id}`}
                initialValues={formGroup}
                onSubmit={handleSubmit}
              />
            )}
            {formGroup.type === FormGroupType.OBJECTIVE && (
              <QuizForm
                initialValues={{
                  ...formGroup,
                }}
                form={`${session.type}-${formGroup.id}`}
                seq={index}
                hideSeq
                onSubmit={handleSubmit}
              />
            )}
            {formGroup.type === FormGroupType.MULTIPLE && (
              <AdmPlanForm
                initialValues={formGroup}
                form={`${session.type}-${formGroup.id}`}
                showName
                onSubmit={handleSubmit}
              />
            )}
          </div>
        </TabPanel>
      ))}
    </div>
  );
};

export default SubjectScreen;
