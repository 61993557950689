import {PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {put, call, takeLatest} from 'redux-saga/effects';
import api from '~/api';
import {FormGroup, ScenarioType} from '~/types';
import {create, CreateFormGroup, load, successLoad, update} from './slice';

function* handleLoad({payload}: PayloadAction<ScenarioType>) {
  try {
    const resp = yield call(api.getFormGroup, payload);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleCreate({payload}: PayloadAction<CreateFormGroup>) {
  try {
    const {formGroup, scenarioType} = payload;
    yield call(api.createFormGroup, formGroup);
    yield put(load(scenarioType));
  } catch (err) {
    console.log('err', err);
  }
}

function* handleUpdate({payload}: PayloadAction<FormGroup>) {
  try {
    yield call(
      api.updateFormGroup,
      payload.id,
      _.pickBy(payload, (p) => p !== null && p !== undefined),
    );
    yield put(load(payload.scenarioType));
  } catch (err) {
    console.log('err', err);
  }
}

export function* watchCandidateFG() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(create, handleCreate);
  yield takeLatest(update, handleUpdate);
}
