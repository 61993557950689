import React, {useEffect} from 'react';
import axios from 'axios';
import {BrowserRouter, Switch, Route, useHistory} from 'react-router-dom';
import HomeScreen from '~/screens/HomeScreen';
import AuthorizedRoute from '~/components/AuthorizedRoute';
import {useDispatch, useSelector} from 'react-redux';
import {LOGIN, validToken} from '~/features/Login/slice';
import CourseRoutes from '~/routers/CourseRoutes';
import {RootState} from './rootReducers';
import {load, USER} from '~/features/User/slice';
import ScenarioRoutes from '~/routers/ScenarioRoutes';

import {createMuiTheme, ThemeProvider} from '@material-ui/core';
import ProfCourseRoutes from '~/routers/ProfCourseRoutes';
import SettingRoutes from '~/routers/SettingRoutes';
import ResultRoutes from '~/routers/ResultRoutes';
import AdminCourseRoutes from '~/routers/AdminCourseRoutes';
import {SERVER_URI} from '~/contants';
import BoardRoutes from '~/routers/BoardRoutes';
import _ from 'lodash';
import PasswordResetScreen from '~/screens/PasswordResetScreen';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#50ABFF',
      main: '#2595FF',
      dark: '#257EE7',
      contrastText: 'white',
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        width: 32,
        height: 32,
        color: 'transparent',
        border: '2px solid #DBDEDF',
        borderRadius: '50%',
        '&$active': {
          color: 'transparent',
          border: '2px solid #2595FF',
          '&>$text': {
            fill: '#2595FF',
          },
        },
        '&$completed': {
          border: '0px solid #2595FF',
          '&>path': {
            stroke: '#2595FF',
          },
        },
      },
      text: {
        fill: '#DBDEDF',
        '&$active': {
          fill: '#2595FF !important',
        },
      },
    },
  },
});

// axios.defaults.baseURL = 'http://localhost:3000';
axios.defaults.baseURL = SERVER_URI;

function App() {
  const dispatch = useDispatch();

  const token = useSelector((state: RootState) => state[LOGIN].token);
  const role = useSelector((state: RootState) => state[USER].user?.role.role);

  useEffect(() => {
    dispatch(validToken());
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      // Add a request interceptor
      const interceptor = axios.interceptors.request.use(
        function (config) {
          // Do something before request is sent
          config.headers.Authorization = `Bearer ${token}`;
          // config.data = _.omitBy(config.data, (d) => d === null);
          return config;
        },
        function (error) {
          // Do something with request error
          return Promise.reject(error);
        },
      );

      dispatch(load());

      return () => {
        console.log('ejected', interceptor);
        axios.interceptors.request.eject(interceptor);
      };
    }
  }, [dispatch, token]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={HomeScreen} />
          <Route path="/password-reset" component={PasswordResetScreen} />
          {role === 'student' && (
            <>
              <AuthorizedRoute path="/class" component={CourseRoutes} />
              <AuthorizedRoute path="/result" component={ResultRoutes} />
              <AuthorizedRoute path="/board" component={BoardRoutes} />
              <AuthorizedRoute
                path="/scenario/:scenarioId/:sessSeq"
                component={ScenarioRoutes}
              />
            </>
          )}
          {role === 'prof' && (
            <>
              <AuthorizedRoute
                path="/prof-class"
                component={ProfCourseRoutes}
              />
              <AuthorizedRoute path="/settings" component={SettingRoutes} />
              <AuthorizedRoute path="/result" component={ResultRoutes} />
              <AuthorizedRoute path="/board" component={BoardRoutes} />
            </>
          )}
          {role === 'admin' && (
            <>
              <AuthorizedRoute
                path="/admin-class"
                component={AdminCourseRoutes}
              />
            </>
          )}
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
