import React, {useCallback, useEffect} from 'react';
import {
  AppBar,
  LinearProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {
  getFormInitialValues,
  getFormValues,
  isDirty,
  startSubmit,
  stopSubmit,
  submit,
} from 'redux-form';
import {RootState} from '~/app/rootReducers';
import {FORMGROUPS, load, updateAnswer} from '~/features/FormGroup/slice';
import {FormGroup, Session, SessionType} from '~/types';
import {USER} from '~/features/User/slice';
import Quiz from '~/components/Quiz';
import TabPanel from '~/components/TabPanel';

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyle = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
  },
  preQuiz: {
    marginBottom: 43,
    fontSize: 20,
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.2)',
  },
  answerBox: {
    backgroundColor: '#E7F5FC',
    padding: 20,
    marginBottom: 30,
    fontWeight: 600,
    boxShadow: '0 0 0 0.2px #000 inset',
  },
  eduGoal: {
    fontSize: 16,
  },
  quizForm: {
    marginBottom: 30,
  },
  tabPanel: {
    overflow: 'auto',
  },
}));

interface Props {
  scenarioId: number;
  session: Session;
  showAnswer?: boolean;
  onShowSubmit?: () => void;
}

const QuizScreen = ({scenarioId, showAnswer, session, onShowSubmit}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyle();

  const state = useSelector((state: RootState) => state);
  const form = useSelector((state: RootState) => state['form']);
  const formGroups = useSelector(
    (state: RootState) => state[FORMGROUPS].formGroups[session.type],
  );
  const loading = useSelector((state: RootState) => state[FORMGROUPS].loading);
  const [value, setValue] = React.useState(0);

  // useEffect(() => {
  //   dispatch(load({scenarioId, session}));
  // }, [dispatch, scenarioId, session]);

  // const handleSubmit = useCallback(
  //   (formGroup: FormGroup) => {
  //     const formName = `${session.type}-${formGroup.id}`;
  //     if (isDirty(formName)(state)) {
  //       dispatch(updateAnswer({formName, scenarioId, session, formGroup}));
  //     } else {
  //       dispatch(stopSubmit(formName));
  //     }
  //   },
  //   [dispatch, state, scenarioId, session],
  // );

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
      const nAnswer = _.chain(formGroups)
        .map((formGroup) => formGroup.uanswer)
        .flattenDeep()
        .compact()
        .value().length;
      if (!formGroups || nAnswer > 0) {
        // setSubmitVisible(false);
        onShowSubmit?.();
      }
      // if (newValue === formGroups.length - 1) {
      //   onShowSubmit?.();
      // }
    },
    [formGroups, onShowSubmit],
  );

  const handleSubmit = () => {};
  const handleFormChange = useCallback(() => {
    const answers = _.chain(formGroups)
      .map((fg) => {
        const name = `${session.type}-${fg.id}`;
        const value = getFormValues(name)(state) as FormGroup;
        return value.uanswer;
      })
      .compact()
      .value();

    const ianswers = _.chain(formGroups)
      .map((fg) => {
        const name = `${session.type}-${fg.id}`;
        const value = getFormInitialValues(name)(state) as FormGroup;
        return value.uanswer;
      })
      .compact()
      .value();

    if (!_.isEqual(answers, ianswers) && answers.length === formGroups.length) {
      // setSubmitVisible(false);
      onShowSubmit?.();
    }
  }, [state, formGroups]);

  return (
    <div className={classes.container}>
      <Typography variant="h6">
        {session.type === SessionType.PRE_QUIZ ? '※ Pre-Quiz' : '※ Post-Quiz'}
      </Typography>
      <Typography variant="body1">
        • 모든 문제 풀이 시, 하단에 제출하기 버튼이 활성화 됩니다.
      </Typography>

      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="simple tabs example">
          {_.map(formGroups, (fomrGroup, index) => (
            <Tab
              key={`chart-tab-${fomrGroup.id}`}
              label={`문제 ${index + 1}`}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
        {loading && <LinearProgress />}
      </AppBar>
      {_.map(formGroups, (formGroup, index) => {
        return (
          <TabPanel
            key={`tab-panel-${formGroup.id}`}
            className={classes.tabPanel}
            value={value}
            index={index}>
            <Quiz
              key={`quiz-form-${index}`}
              session={session}
              formGroup={formGroup}
              index={index}
              showAnswer={showAnswer}
              handleSubmit={handleSubmit}
              onChange={handleFormChange}
            />
          </TabPanel>
        );
      })}
    </div>
  );
};

export default QuizScreen;
