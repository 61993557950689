import {PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {put, call, takeLatest} from 'redux-saga/effects';
import api from '~/api';
import {Form, ScenarioType} from '~/types';
import {create, CreateForm, load, successLoad, update} from './slice';

function* handleLoad({payload}: PayloadAction<ScenarioType>) {
  try {
    const resp = yield call(api.getForms, payload);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleCreate({payload}: PayloadAction<CreateForm>) {
  try {
    const {form, scenarioType} = payload;
    yield call(api.createForm, form);
    yield put(load(scenarioType));
  } catch (err) {
    console.log('err', err);
  }
}

function* handleUpdate({payload}: PayloadAction<Form>) {
  try {
    yield call(
      api.updateForm,
      payload.id,
      _.pickBy(payload, (p) => p !== null && p !== undefined),
    );
    yield put(load(payload.scenarioType));
  } catch (err) {
    console.log('err', err);
  }
}

export function* watchCandidateForm() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(create, handleCreate);
  yield takeLatest(update, handleUpdate);
}
