import {CircularProgress, makeStyles, Typography} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isDirty, stopSubmit} from 'redux-form';
import {RootState} from '~/app/rootReducers';
import {FORMGROUPS, load, updateAnswer} from '~/features/FormGroup/slice';
import AdmPlanForm from '~/forms/AdmPlanForm';
import {FormGroup, FormGroupType, Session} from '~/types';

import ErrorIcon from '@material-ui/icons/Error';
import QuizForm from '~/forms/QuizForm';
import SubjectForm from '~/forms/SubjectForm';

interface Props {
  scenarioId: number;
  session: Session;
  trial?: number;
  passed?: boolean;
}

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'absolute',
    width: '100%',
    backgroundColor: '#13131C73',
  },
  root: {
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
  },
  topWrap: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0',
  },
  titleWrap: {
    display: 'flex',
  },
  firstTitle: {
    color: '#495055',
    fontWeight: 700,
    marginRight: 10,
  },
  title: {
    color: '#495055',
    fontWeight: 700,
    fontSize: 20,
  },
  warning: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #DF3826',
    borderRadius: 2,
    marginTop: 8,
    padding: '6px 12px',
  },
  warningText: {
    color: '#495055',
    fontWeight: 400,
    marginRight: 26,
  },
}));

const AdmPlanScreen = ({scenarioId, session, trial, passed}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formGroups = useSelector(
    (state: RootState) => state[FORMGROUPS].formGroups[session.type],
  );
  const {loading} = useSelector((state: RootState) => state[FORMGROUPS]);
  const state = useSelector((state: RootState) => state);
  const [trySubmit, setTrySubmit] = useState(false);

  // useEffect(() => {
  //   dispatch(load({scenarioId, session}));
  // }, [dispatch, scenarioId, session]);

  useEffect(() => {
    console.log('AdmPlanScreen', trial, passed, trySubmit);
    if (trial === 1 && !passed && trySubmit) {
      alert('정답이 아닙니다. 다시 한번 정답을 골라보세요.');
      dispatch(load({scenarioId, session}));
    }
  }, [trial, passed, trySubmit, dispatch, scenarioId, session]);

  // const handleSubmit = useCallback(
  //   (formGroup: FormGroup) => {
  //     setTrySubmit(true);
  //     const formName = `${session.type}-${formGroup.id}`;
  //     if (isDirty(formName)(state)) {
  //       dispatch(updateAnswer({formName, scenarioId, session, formGroup}));
  //     } else {
  //       dispatch(stopSubmit(formName));
  //     }
  //   },
  //   [dispatch, state, scenarioId, session],
  // );

  const handleSubmit = () => {
    console.log('handleSubmit');
    setTrySubmit(true);
  };
  return (
    <>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
      <div className={classes.root}>
        {!!trial && trial === 1 && !passed && (
          <div className={classes.warning}>
            <Typography className={classes.warningText} variant="subtitle2">
              정답을 다시 선택해 주세요. (오답 1회)
            </Typography>
            <ErrorIcon color="error" fontSize="small" />
          </div>
        )}
        {_.map(formGroups, (formGroup, index) => (
          <div key={`adm-plan-${formGroup.id}`}>
            <div className={classes.topWrap}>
              <div className={classes.titleWrap}>
                <Typography variant="body1">※ {formGroup.name}</Typography>

                {formGroup.explain && <div>{formGroup.explain}</div>}
                {formGroup.image && <img src={formGroup.image} />}
              </div>

              {formGroup.type === FormGroupType.SUBJECTIVE && (
                <SubjectForm
                  initialValues={formGroup}
                  form={`${session.type}-${formGroup.id}`}
                  onSubmit={handleSubmit}
                />
              )}
              {formGroup.type === FormGroupType.OBJECTIVE && (
                <QuizForm
                  initialValues={{
                    ...formGroup,
                  }}
                  form={`${session.type}-${formGroup.id}`}
                  seq={index}
                  hideSeq
                  onSubmit={handleSubmit}
                />
              )}
              {formGroup.type === FormGroupType.MULTIPLE && (
                <AdmPlanForm
                  initialValues={formGroup}
                  form={`${session.type}-${formGroup.id}`}
                  showName
                  onSubmit={handleSubmit}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AdmPlanScreen;
