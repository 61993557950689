import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Post} from '~/types';

export interface CreatePost {
  boardId: number;
  post: Post;
}

export interface DeletePost {
  boardId: number;
  postId: number;
}

interface State {
  loading: boolean;
  posts: Post[];
  page: number;
}

let initialState: State = {
  loading: false,
  posts: [],
  page: 0,
};

const slice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<number>) => {
      state.loading = true;
      state.page = 0;
    },
    successLoad: (state, {payload}: PayloadAction<Post[]>) => {
      state.loading = false;
      state.posts = payload;
    },
    loadMore: (state, _action: PayloadAction<number>) => {
      state.loading = true;
      state.page++;
    },
    successLoadMore: (state, {payload}: PayloadAction<Post[]>) => {
      state.loading = false;
      state.posts = [...state.posts, ...payload];
    },
    create: (state, _action: PayloadAction<CreatePost>) => {
      state.loading = true;
    },
    update: (state, _action: PayloadAction<CreatePost>) => {
      state.loading = true;
    },
    deletePost: (state, _action: PayloadAction<DeletePost>) => {
      state.loading = true;
    },
  },
});

export const {
  load,
  loadMore,
  successLoad,
  successLoadMore,
  create,
  update,
  deletePost,
} = slice.actions;
export const POST = slice.name;
export default slice.reducer;
