import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioProps,
  Typography,
} from '@material-ui/core';
import React from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import radioButton from '~/components/IntroInfoField';
import {ScenarioType, UserInfo} from '~/types';

interface IntroProps {
  type: ScenarioType;
}

const useStyles = makeStyles({
  container: {
    backgroundColor: '#F1F9FF',
    padding: '10px 14px 10px 20px',
    boxShadow: '0 0 0 0.2px #000 inset',
  },
  divBox: {
    display: 'inline-block',
  },
  infoTitle: {
    display: 'inline-block',
    margin: '7px 10px 0 0',
    fontWeight: 600,
  },
  infoTitleGrade: {
    display: 'inline-block',
    margin: '7px 10px 0 0',
    fontWeight: 600,
  },
  gradeField: {
    width: 50,
    backgroundColor: '#fff',
    boxSizing: '-moz-initial',
    height: 25,
    border: '1px solid #DBDEDF',
    borderRadius: '2px',
  },
  radioField: {
    flexDirection: 'row',
    display: 'inline-block',
    margin: '0 10px 0 0',
  },
  radioFieldTraning: {
    flexDirection: 'row',
    display: 'inline-block',
  },
  labelTxt: {
    fontSize: 14,
  },
  radioRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '100%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#fff',
  },
  checkedIcon: {
    borderRadius: '100%',
    boxShadow: '0 0 0 5px #2595FF inset',
    backgroundColor: '#106ba3',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  },
});

function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.radioRoot}
      disableRipple
      checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const Intro = (props: InjectedFormProps<UserInfo, IntroProps> & IntroProps) => {
  const classes = useStyles();
  const {handleSubmit, type} = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.container}>
        <div className={classes.divBox}>
          <Typography variant="body2" className={classes.infoTitle}>
            성별
          </Typography>
          <Field
            name="sex"
            component={radioButton}
            className={classes.radioField}>
            <FormControlLabel
              control={<StyledRadio />}
              label={<span className={classes.labelTxt}>남자</span>}
              value="male"
            />

            <FormControlLabel
              control={<StyledRadio />}
              label={<span className={classes.labelTxt}>여자</span>}
              value="female"
            />
          </Field>
        </div>
        <div className={classes.divBox}>
          <Typography variant="body2" className={classes.infoTitleGrade}>
            학년
          </Typography>
          <Field
            name="year"
            component={radioButton}
            className={classes.radioField}>
            <FormControlLabel
              control={<StyledRadio />}
              label={<span className={classes.labelTxt}>1</span>}
              name="year"
              value="1"
            />
            <FormControlLabel
              control={<StyledRadio />}
              label={<span className={classes.labelTxt}>2</span>}
              name="year"
              value="2"
            />
            <FormControlLabel
              control={<StyledRadio />}
              label={<span className={classes.labelTxt}>3</span>}
              name="year"
              value="3"
            />
            <FormControlLabel
              control={<StyledRadio />}
              label={<span className={classes.labelTxt}>4</span>}
              name="year"
              value="4"
            />
          </Field>
        </div>
        <div className={classes.divBox}>
          <Typography variant="body2" className={classes.infoTitle}>
            {type === ScenarioType.ADM ? '관리이론' : '정신간호학'} 교과목 수강
            유무
          </Typography>
          <Field
            name="theory"
            component={radioButton}
            className={classes.radioField}>
            <FormControlLabel
              control={<StyledRadio />}
              label={<span className={classes.labelTxt}>있음</span>}
              name="theory"
              value="true"
            />
            <FormControlLabel
              control={<StyledRadio />}
              label={<span className={classes.labelTxt}>없음</span>}
              name="theory"
              value="false"
            />
          </Field>
        </div>

        <div className={classes.divBox}>
          <Typography variant="body2" className={classes.infoTitle}>
            {type === ScenarioType.ADM ? '관리실습' : '정신간호 실습'} 교과목
            수강 유무
          </Typography>

          <Field
            name="practice"
            component={radioButton}
            className={classes.radioFieldTraning}>
            <FormControlLabel
              control={<StyledRadio />}
              label={<span className={classes.labelTxt}>있음</span>}
              name="practice"
              value="true"
            />
            <FormControlLabel
              control={<StyledRadio />}
              label={<span className={classes.labelTxt}>없음</span>}
              name="practice"
              value="false"
            />
          </Field>
        </div>
      </div>
    </form>
  );
};

const IntroForm = reduxForm<UserInfo, IntroProps>({
  // a unique name for the form
  form: 'Intro-info',
})(Intro);

export default IntroForm;
