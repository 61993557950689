import React from 'react';
import {Field, reduxForm, InjectedFormProps} from 'redux-form';
import {Button, makeStyles} from '@material-ui/core';
import {IPasswordReset} from '~/types';
import PasswordTextField from '~/components/PasswordTextField';
import * as yup from 'yup';
import validator from './validator';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 360, // Fix IE 11 issue.
    height: '100%',
    marginTop: 24,
  },
  idStyle: {
    borderRadius: 2,
    fontSize: 14,
  },
  selectField: {
    width: 360,
    fontSize: 14,
    color: '#a7adb2',
    fontWeight: 400,
  },
  divBtn: {
    display: 'flex',
  },
  reset: {
    width: 69,
    height: 32,
    backgroundColor: '#F9FAFA',
    left: 130,
    top: 10,
    borderRadius: 2,
    textTransform: 'none',
  },
  submit: {
    color: '#ffffff',
    backgroundColor: '#2595FF',
    borderRadius: 2,
    textTransform: 'none',
  },
}));

const schema = yup.object({
  password: yup
    .string()
    .required('비밀번호를 입력해주세요')
    .min(6, '비밀번호를 최소 6자 이상 입력해주세요'),
  verifyPassword: yup
    .string()
    .oneOf([yup.ref('password')], '비밀번호가 일치하지 않습니다.')
    .required('비밀번호를 확인해주세요')
    .min(6, '비밀번호를 최소 6자 이상 입력해주세요'),
});

const PasswordResetF = (props: InjectedFormProps<IPasswordReset>) => {
  const classes = useStyles();
  const {handleSubmit} = props;

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div>
        <div>
          <Field
            component={PasswordTextField}
            name="password"
            id="password"
            placeholder="Password"
          />
        </div>

        <div>
          <Field
            component={PasswordTextField}
            name="verifyPassword"
            id="verifyPassword"
            placeholder="Confirm Password"
          />
        </div>
      </div>
      <div className={classes.divBtn}>
        <Button
          className={classes.submit}
          type="submit"
          variant="contained"
          disableElevation
          fullWidth>
          초기화
        </Button>
      </div>
    </form>
  );
};

const PasswordResetForm = reduxForm<IPasswordReset>({
  // a unique name for the form
  form: 'password-reset',
  asyncValidate: validator(schema),
  asyncChangeFields: ['password', 'verifyPassword'],
})(PasswordResetF);

export default PasswordResetForm;
