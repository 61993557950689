import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Student} from '~/types';

interface State {
  loading: boolean;
  students: Student[];
}

let initialState: State = {
  loading: false,
  students: [],
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    load: (state) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<Student[]>) => {
      state.loading = false;
      state.students = payload;
    },
    uploadFile: (state, _action: PayloadAction<FormData>) => {
      state.loading = true;
    },
    update: (state, _action: PayloadAction<Student>) => {
      state.loading = true;
    },
    create: (state, _action: PayloadAction<Student>) => {
      state.loading = true;
    },
  },
});

export const {load, successLoad, uploadFile, update, create} = slice.actions;
export const STUDENT = slice.name;
export default slice.reducer;
