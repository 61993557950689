import React, {useCallback} from 'react';
import {Box, makeStyles, Modal, Paper, Typography} from '@material-ui/core';
import {useDropzone} from 'react-dropzone';
import {useDispatch} from 'react-redux';
import {uploadFile} from '~/features/Student/slice';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 485,
    height: 348,
  },
  title: {
    fontWeight: 700,
    padding: '10px 0 10px 30px',
    color: 'rgba(73, 80, 85, 1)',
    borderBottom: '1px solid #DBDEDF',
  },
  content: {
    width: '90%',
    height: '75%',
    margin: 'auto',
    border: '1px dashed #DBDEDF',
    borderWidth: 2,
    borderRadius: 2,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  uploadIconWrap: {
    width: 32,
    height: 32,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    border: '1px solid #DBDEDF',
  },
  uploadIcon: {
    display: 'flex',
    color: ' #495055',
    cursor: 'pointer',
  },
  text: {
    display: 'flex',
    marginTop: 10,
  },
});

interface Props {
  open: boolean;
  onClose: () => void;
}

const StudentUploadModal = ({open, onClose}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles) => {
      // Do something with the files
      if (acceptedFiles[0]) {
        let formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        dispatch(uploadFile(formData));
        onClose();
      }
    },
    [dispatch],
  );
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}>
      <Paper className={classes.container}>
        <Typography variant="h6" className={classes.title}>
          이름/학번 엑셀 업로드
        </Typography>
        <div {...getRootProps()} className={classes.content}>
          <Box className={classes.uploadIconWrap}>
            <VerticalAlignTopIcon className={classes.uploadIcon} />
          </Box>
          <input {...getInputProps()} />
          {isDragActive ? (
            <div className={classes.text}>Drop the files here ...</div>
          ) : (
            <div className={classes.text}>
              Drag 'n' drop some files here, or click to select files
            </div>
          )}
        </div>
      </Paper>
    </Modal>
  );
};

export default StudentUploadModal;
