import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CreateUser, IPasswordReset, PasswordFindType, User} from '~/types';
import {History} from 'history';

interface State {
  loading: boolean;
  user: User | undefined;
}

let initialState: State = {
  loading: false,
  user: undefined,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    load: (state) => {
      state.loading = true;
    },
    clear: (state) => {
      state.loading = false;
      state.user = undefined;
    },
    successLoad: (state, {payload}: PayloadAction<User>) => {
      state.loading = false;
      state.user = payload;
    },
    create: (state, _action: PayloadAction<CreateUser>) => {
      state.loading = true;
    },
    findPassword: (state, _action: PayloadAction<PasswordFindType>) => {
      state.loading = true;
    },
    resetPassword: (
      state,
      _action: PayloadAction<IPasswordReset & {history: History}>,
    ) => {
      state.loading = true;
    },
  },
});

export const {
  load,
  create,
  clear,
  successLoad,
  findPassword,
  resetPassword,
} = slice.actions;
export const USER = slice.name;
export default slice.reducer;
