import {createSlice} from '@reduxjs/toolkit';

interface State {
  loginModal: boolean;
  signupModal: boolean;
  passwordModal: boolean;
  profileModal: boolean;
  scoreSettingModal: boolean;
  admPlanModal: boolean;
}

let initialState: State = {
  loginModal: false,
  signupModal: false,
  passwordModal: false,
  profileModal: false,
  scoreSettingModal: false,
  admPlanModal: false,
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openLoginModal: (state) => {
      state.loginModal = true;
    },
    closeLoginModal: (state) => {
      state.loginModal = false;
    },
    openSignupModal: (state) => {
      state.signupModal = true;
    },
    closeSignupModal: (state) => {
      state.signupModal = false;
    },
    openPasswordModal: (state) => {
      state.passwordModal = true;
    },
    closePasswordModal: (state) => {
      state.passwordModal = false;
    },
    openProfileModal: (state) => {
      state.profileModal = true;
    },
    closeProfileModal: (state) => {
      state.profileModal = false;
    },
    openScoreSettingModal: (state) => {
      state.scoreSettingModal = true;
    },
    closeScoreSettingModal: (state) => {
      state.scoreSettingModal = false;
    },
    openAdmPlanModal: (state) => {
      state.admPlanModal = true;
    },
    closeAdmPlanModal: (state) => {
      state.admPlanModal = false;
    },
  },
});

export const {
  openLoginModal,
  closeLoginModal,
  openSignupModal,
  closeSignupModal,
  openPasswordModal,
  closePasswordModal,
  openProfileModal,
  closeProfileModal,
  openScoreSettingModal,
  closeScoreSettingModal,
  openAdmPlanModal,
  closeAdmPlanModal,
} = slice.actions;
export const MODAL = slice.name;
export default slice.reducer;
