import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface State {
  message?: string;
}

let initialState: State = {};

const slice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast: (state, {payload}: PayloadAction<{message: string}>) => {
      state.message = payload.message;
    },
    closeToast: (state) => {
      state.message = undefined;
    },
  },
});

export const {openToast, closeToast} = slice.actions;
export const TOAST = slice.name;
export default slice.reducer;
