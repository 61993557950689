import {put, call, takeLatest} from 'redux-saga/effects';
import api from '~/api';
import {load, successLoad} from './slice';

function* handleLoad() {
  try {
    const resp = yield call(api.getFormCategories);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchCandidateFC() {
  yield takeLatest(load, handleLoad);
}
