import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Select,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {loadTmpl} from '~/features/Course/slice';
import {create} from '~/features/Scenario/slice';
import {ScenarioType} from '~/types';

interface Props {
  open: boolean;
  onClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: 'column',
    width: 331,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'Translate(-50%,-50%)',
  },
  title: {
    height: 44,
    fontWeight: 700,
    padding: '6px 26px',
    borderBottom: '1px solid #DBDEDF',
  },
  contentWrap: {
    display: 'flex',
    height: '80%',
    padding: '24px',
  },
  select: {
    color: '#a7adb2',
    fontWeight: 400,
  },
  selectInput: {
    padding: 10.5,
  },
  selectItemTitle: {
    color: '#a7adb2',
  },
  selectItem: {
    color: '#a7adb2',
  },

  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 8,
  },
}));

const StyledButton = styled(Button)({
  textTransform: 'none',
  boxShadow: 'none',
  borderRadius: 2,
  '&:first-child': {
    marginRight: 8,
  },
});

const ScenarioCreateModal = ({open, onClose}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [name, setName] = useState<string>();
  const [type, setType] = useState<ScenarioType>();

  useEffect(() => {
    dispatch(loadTmpl());
  }, [dispatch]);

  const handleSave = useCallback(() => {
    if (name && type) {
      dispatch(create({name, type}));
    }
  }, [dispatch, name, type]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper elevation={0} className={classes.paper}>
        <Typography component="h1" variant="h6" className={classes.title}>
          시나리오 생성
        </Typography>
        <div className={classes.contentWrap}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                placeholder="Scenario Name"
                type="string"
                size="small"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  labelId="demo-simple-select-label"
                  id="selectclass"
                  value={type}
                  className={classes.select}
                  classes={{
                    root: classes.selectInput,
                  }}
                  onChange={(e: any) => setType(e.target.value)}>
                  <option className={classes.selectItemTitle}>
                    Choose scenario type
                  </option>
                  <option
                    value={ScenarioType.ADM}
                    className={classes.selectItem}>
                    간호관리
                  </option>
                  <option
                    value={ScenarioType.PSY}
                    className={classes.selectItem}>
                    정신간호
                  </option>
                </Select>
              </FormControl>
            </Grid>
            <div className={classes.buttonWrap}>
              <StyledButton
                onClick={() => onClose()}
                type="button"
                variant="outlined"
                size="small">
                Cancel
              </StyledButton>
              <StyledButton
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSave}>
                Add
              </StyledButton>
            </div>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default ScenarioCreateModal;
