import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  createStyles,
  makeStyles,
  Theme,
  Button,
  Modal,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {
  create,
  deletePost,
  load,
  loadMore,
  POST,
  update,
} from '~/features/Post/slice';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {USER} from '~/features/User/slice';
import {Post, UserRoleType} from '~/types';
import PostForm from '~/forms/PostForm';
import {submit} from 'redux-form';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'normal',
    },
    btn: {
      marginTop: 16,
    },
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    modalBtn: {
      marginTop: 16,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    contentBtn: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

interface Props {
  boardId: number;
}

const Board = ({boardId}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const posts = useSelector((state: RootState) => state[POST].posts);
  const role = useSelector((state: RootState) => state[USER].user?.role.role);

  const [open, setOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState<Post | undefined>();

  useEffect(() => {
    dispatch(load(boardId));
  }, [boardId]);

  const handleLoadMore = () => {
    dispatch(loadMore(boardId));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    dispatch(submit('Post'));
  };

  const handleAddPost = (post: Post) => {
    if (selectedPost) {
      dispatch(update({boardId, post}));
      setSelectedPost(undefined);
    } else {
      dispatch(create({boardId, post}));
    }

    setOpen(false);
  };

  const handleDeletePost = (postId: number) => {
    dispatch(deletePost({boardId, postId}));
  };

  const handleModify = (post: Post) => {
    setSelectedPost(post);
    setOpen(true);
  };

  return (
    <div>
      {_.map(posts, (post, index) => (
        <Accordion key={post.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}>
            <Typography className={classes.heading}>{post.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.content}>
              {role === UserRoleType.PROF && (
                <div className={classes.contentBtn}>
                  <Button onClick={() => handleModify(post)}>수정</Button>
                  <Button onClick={() => handleDeletePost(post.id)}>
                    삭제
                  </Button>
                </div>
              )}

              <div>
                <Typography>{post.content}</Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
      <div className={classes.btn}>
        <Button fullWidth onClick={handleLoadMore} variant="outlined">
          더 불러오기
        </Button>
      </div>
      {role === UserRoleType.PROF ? (
        <>
          <div className={classes.btn}>
            <Button fullWidth onClick={handleOpen} variant="outlined">
              글 올리기
            </Button>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description">
            <div className={classes.paper}>
              <Typography variant="h5">글 올리기</Typography>
              <PostForm onSubmit={handleAddPost} initialValues={selectedPost} />
              <div className={classes.modalBtn}>
                <Button onClick={handleClose} variant="outlined">
                  취소
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="outlined"
                  color="primary">
                  저장
                </Button>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Board;
