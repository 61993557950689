import {Link, makeStyles, Theme} from '@material-ui/core';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {load} from '~/features/FormGroup/slice';
import * as FormCategoryAction from '~/features/FormCategory/slice';
import {Session, SessionType} from '~/types';
import ScoreSetting from '~/components/ScoreSetting';
import RandomProblemSetting from '~/components/RandomProblemSetting';
import ProblemView from '~/components/ProblemView';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menubar: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '76%',
    cursor: 'pointer',
  },
  menuSession: {
    color: 'rgba(73, 80, 85, 1)',
    padding: '12px 2px 6px 2px',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'none',
      color: ' rgba(37, 149, 255, 1)',
      borderBottom: '3px solid #2595FF',
      borderRadius: '2px 2px 0px 0px',
    },
  },
  active: {
    color: ' rgba(37, 149, 255, 1)',
    borderBottom: '3px solid #2595FF',
    borderRadius: '2px 2px 0px 0px',
    textDecoration: 'none',
  },
}));

interface Props {
  sessions: Session[];
  scenarioId: number;
}

const EX_FIELDS = [
  SessionType.INTRO,
  SessionType.SIMULATION,
  SessionType.ADM_PLAN_RESULT,
];

const StudyScreen = ({sessions, scenarioId}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [session, setSession] = useState<Session>();

  useEffect(() => {
    if (session) {
      dispatch(load({scenarioId, session}));
      dispatch(FormCategoryAction.load({scenarioId, session}));
    }
  }, [dispatch, scenarioId, session]);

  return (
    <div>
      <div className={classes.container}>
        <div className={classes.menubar}>
          {_.filter(sessions, (sess) => !_.includes(EX_FIELDS, sess.type)).map(
            (sess) => (
              <Link
                className={`${classes.menuSession} ${
                  session?.id === sess.id ? classes.active : ''
                }`}
                key={`link-${sess.id}`}
                onClick={() => setSession(sess)}>
                {sess.name.replace('\\r\\n', ' ')}
              </Link>
            ),
          )}
        </div>
      </div>
      {session && <ProblemView session={session} scenarioId={scenarioId} />}
      {session &&
        !_.includes(
          [
            SessionType.ADM_PLAN,
            SessionType.PSY_PLAN,
            SessionType.PSY_PROBLEM,
            SessionType.PRE_QUIZ,
            SessionType.POST_QUIZ,
            SessionType.CHART,
          ],
          session.type,
        ) && <ScoreSetting session={session} scenarioId={scenarioId} />}
      {session &&
        _.includes(
          [SessionType.PSY_PLAN, SessionType.PSY_PROBLEM, SessionType.ADM_PLAN],
          session.type,
        ) && <RandomProblemSetting session={session} scenarioId={scenarioId} />}
    </div>
  );
};

export default StudyScreen;
