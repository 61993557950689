import {
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {FORMGROUPS} from '~/features/FormGroup/slice';
import * as FormCategoryAction from '~/features/FormCategory/slice';
import {FormGroup, Session, SessionType} from '~/types';
import {openScoreSettingModal} from '~/features/Modal/slice';
import ScoreSettingModal from '~/modals/ScoreSettingtModal';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '80%',
    margin: 'auto',
    position: 'relative',
    top: 70,
  },
  tableContainer: {},
  tableTitle: {
    fontWeight: 600,
    '&:nth-child(1)': {
      width: 166,
    },
    '&:nth-child(2)': {
      width: 309,
    },
    '&:nth-child(3)': {
      width: 445,
    },
    '&:nth-child(4)': {
      width: 110,
    },
  },
  tableActive: {
    textTransform: 'none',
    height: '25px',
    justifyContent: 'center',
  },
  tblCircle: {
    width: 8,
    color: '#33BE63',
    position: 'relative',
    top: 8,
    left: -5,
  },
  tblInactCircle: {
    width: 8,
    color: '#DF3826',
    position: 'relative',
    top: 8,
    left: -5,
  },
  tableCellScore: {
    marginLeft: 17,
    width: 30,
  },
  tableCellLink: {
    color: 'black',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  tableCellBtn: {
    marginLeft: 7,
    width: 20,
    height: 20,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

interface Props {
  session: Session;
  scenarioId: number;
}

const ProblemView = ({session, scenarioId}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formGroupsRedux = useSelector(
    (state: RootState) => state[FORMGROUPS].formGroups[session.type],
  );
  const formCategoriesRedux = useSelector(
    (state: RootState) =>
      state[FormCategoryAction.FORMCATEGORIES].formCategories,
  );

  const [scores, setScores] = useState<number[]>([]);
  const [formGroup, setFormGroup] = useState<FormGroup>();
  const [formGroups, setFormGroups] = useState<FormGroup[]>([]);

  useEffect(() => {
    if (session?.type === SessionType.PSY_INTERVENTION) {
      setFormGroups(
        _.chain(formCategoriesRedux)
          .map((fc) =>
            _.map(fc.formGroups, (fg) => ({
              ...fg,
              name: `${fc.name}-${fg.name}`,
            })),
          )
          .flattenDeep()
          .value(),
      );
    } else {
      setFormGroups(formGroupsRedux);
    }
  }, [session, formGroupsRedux, formCategoriesRedux]);

  useEffect(() => {
    if (session) {
      setScores(
        _.map(formGroups, (g) => {
          return g.score?.score ?? 0;
        }),
      );
    }
  }, [formGroups, session]);

  const showProblem = useCallback(
    (fg: FormGroup) => {
      setFormGroup(fg);
      dispatch(openScoreSettingModal());
    },
    [dispatch],
  );

  const renderTable = useCallback(() => {
    return _.map(formGroups, (row, index) => (
      <TableRow key={row.name}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>
          <Link
            onClick={() => showProblem(row)}
            underline="none"
            className={classes.tableCellLink}>
            문제 불러오기
          </Link>
        </TableCell>
      </TableRow>
    ));
  }, [showProblem, formGroups, scores]);

  return (
    <div className={classes.container}>
      <TableContainer>
        <Table aria-label="simple table" className={classes.tableContainer}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableTitle}>교육과제</TableCell>
              <TableCell className={classes.tableTitle}>문제보기</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTable()}</TableBody>
        </Table>
      </TableContainer>
      {formGroup && <ScoreSettingModal problem={formGroup} session={session} />}
    </div>
  );
};

export default ProblemView;
