import StepConnector from '@material-ui/core/StepConnector';
import {withStyles} from '@material-ui/core/styles';
import React from 'react';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 15,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#2595FF',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#2595FF',
    },
  },
  line: {
    borderColor: '#DBDEDF',
    borderTopWidth: 2,
  },
})(StepConnector);

export default QontoConnector;
