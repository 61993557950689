import {
  Button,
  InputAdornment,
  InputBase,
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import update from 'immutability-helper';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {COURSE} from '~/features/Course/slice';
import * as StudentAction from '~/features/Student/slice';
import {STUDENT} from '~/features/Student/slice';
import StudentAddModal from '~/modals/StudentAddModal';
import StudentUploadModal from '~/modals/StudentUploadModal';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import _ from 'lodash';
import {Student} from '~/types';

const useStyles = makeStyles({
  container: {
    width: '70%',
    margin: 'auto',
    marginTop: '50px',
  },
  btnWrap: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingTop: 10,
  },
  tblContainer: {
    border: 'none',
  },
  tblHead: {
    fontWeight: 600,
    width: 240,
  },
  activeCell: {
    cursor: 'pointer',
    display: 'flex',
  },
  tblPencil: {
    color: '#495055',
  },
  tblInput: {
    border: 'none',
    cursor: 'default',
    fontSize: 14,
  },
  tblInputName: {
    fontSize: 14,
  },
  tblCircle: {
    width: 8,
    color: '#33BE63',
    display: 'flex',
    marginRight: 5,
  },
  tblInactCircle: {
    width: 8,
    color: '#DF3826',
    display: 'flex',
    marginRight: 5,
  },
});

const StudentScreen = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const students = useSelector((state: RootState) => state[STUDENT].students);
  const course = useSelector((state: RootState) => state[COURSE].current);

  const [openUpload, setOpenUpload] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [activates, setActivates] = useState<boolean[]>([]);
  const [studentsState, setStudentsState] = useState<Student[]>([]);

  useEffect(() => {
    setStudentsState(students);
  }, [students]);

  useEffect(() => {
    if (course) {
      setActivates(
        _.map(students, (s) => {
          let index = -1;
          const courseId = s.courseIds;
          for (let i = 0; i < courseId.length; i++) {
            if (courseId[i] === course?.id) {
              index = i;
            }
          }
          if (index !== -1) {
            return s.activates?.[index] ?? true;
          } else {
            return false;
          }
        }),
      );
    }
  }, [students, course]);

  const handleAddClose = () => {
    setOpenAdd(false);
  };

  const handleClose = () => {
    setOpenUpload(false);
  };

  useEffect(() => {
    dispatch(StudentAction.load());
  }, [dispatch]);

  const StyledButton = styled(Button)({
    backgroundColor: '#2595FF',
    color: 'white',
    height: 32,
    borderRadius: 2,
    padding: '5px 12px 5px 12px',
    textTransform: 'none',
    marginRight: 10,
    '&:nth-child(1)': {
      margin: 0,
    },
  });

  const handleActivity = (student: Student) => {
    if (course) {
      const idx = _.indexOf(student.courseIds, course.id);
      if (idx !== -1) {
        const {user, ...restStudent} = student;
        const activates = [...student.activates];
        activates[idx] = !activates[idx];
        dispatch(
          StudentAction.update({
            ...restStudent,
            activates,
          }),
        );
      }
    }
  };

  const handleChangeName = (idx: number, name: string) => {
    setStudentsState(
      update(studentsState, {
        [idx]: (student) => update(student, {name: {$set: name}}),
      }),
    );
  };

  const handleChangeStudentId = (idx: number, studentId: string) => {
    setStudentsState(
      update(studentsState, {
        [idx]: (student) => update(student, {studentId: {$set: studentId}}),
      }),
    );
  };

  const handleSave = () => {
    _.map(studentsState, (student) => {
      if (!student.user) {
        dispatch(StudentAction.update(student));
      }
    });
  };

  return (
    <div className={classes.container}>
      <div>
        <TableContainer className={classes.tblContainer}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tblHead}>교육 활성화</TableCell>
                <TableCell className={classes.tblHead}>이름</TableCell>
                <TableCell className={classes.tblHead}>학번</TableCell>
                <TableCell className={classes.tblHead}>E-mail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(studentsState, (student, indx) => (
                <TableRow key={student.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={() => handleActivity(student)}>
                    {activates[indx] ? (
                      <Typography
                        variant="body2"
                        className={classes.activeCell}>
                        <FiberManualRecordIcon className={classes.tblCircle} />
                        활성화
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        className={classes.activeCell}>
                        <FiberManualRecordIcon
                          className={classes.tblInactCircle}
                        />
                        비활성화
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {student.user ? (
                      <Typography className={classes.tblInputName}>
                        {student.name}
                      </Typography>
                    ) : (
                      <InputBase
                        startAdornment={
                          <InputAdornment position="start">
                            <CreateOutlinedIcon
                              fontSize="small"
                              className={classes.tblPencil}
                            />
                          </InputAdornment>
                        }
                        type="text"
                        value={student.name}
                        className={classes.tblInput}
                        onChange={(e) => handleChangeName(indx, e.target.value)}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {student.user ? (
                      <Typography className={classes.tblInputName}>
                        {student.studentId}
                      </Typography>
                    ) : (
                      <InputBase
                        startAdornment={
                          <InputAdornment position="start">
                            <CreateOutlinedIcon
                              fontSize="small"
                              className={classes.tblPencil}
                            />
                          </InputAdornment>
                        }
                        type="text"
                        value={student.studentId}
                        className={classes.tblInput}
                        onChange={(e) =>
                          handleChangeStudentId(indx, e.target.value)
                        }
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {student.user ? student.user.username : '미가입'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.btnWrap}>
          <StyledButton onClick={() => setOpenUpload(true)}>
            Upload
          </StyledButton>
          <StyledButton onClick={() => setOpenAdd(true)}>Add</StyledButton>
          <StyledButton onClick={handleSave}>Save</StyledButton>
        </div>
      </div>
      <StudentAddModal open={openAdd} onClose={handleAddClose} />
      <StudentUploadModal open={openUpload} onClose={handleClose} />
    </div>
  );
};

export default StudentScreen;
