import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Board} from '~/types';

interface State {
  loading: boolean;
  boards: Board[];
}

let initialState: State = {
  loading: false,
  boards: [],
};

const slice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    load: (state) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<Board[]>) => {
      state.loading = false;
      state.boards = payload;
    },
  },
});

export const {load, successLoad} = slice.actions;
export const BOARD = slice.name;
export default slice.reducer;
