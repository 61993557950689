import {PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import api from '~/api';
import {RootState} from '~/app/rootReducers';
import {Answer, Form, FormCategory, FormGroup, Session} from '~/types';
import {COURSE} from '../Course/slice';
import {USER} from '../User/slice';
import {
  attach,
  attachFormGroup,
  detach,
  detachFormGroup,
  load,
  successLoad,
} from './slice';

const loadForm = async (
  answer: Partial<Answer>,
  formGroups: FormGroup[],
  courseId: number,
  scenarioId: number,
  formCategoryId: number,
) => {
  return Promise.all(
    _.map(formGroups, async (fg) => {
      const formGroupId = fg.id;
      const where = {
        ...answer,
        formGroupId,
      };

      const resp = await api.getFormsWithFromGroupId(
        formGroupId,
        fg.formCategoryId
          ? {
              ...where,
              formCategoryId: fg.formCategoryId,
            }
          : where,
      );
      const scores = await api.getScoresWithFormGroupId(
        formGroupId,
        courseId,
        scenarioId,
        formCategoryId,
      );
      const forms = _.map(resp.data, (form: Form) => ({
        ...form,
        answers: form.answers ?? [{content: ''}],
      }));
      return {...fg, forms: forms, scores: scores.data} as FormGroup;
    }),
  );
};

const loadFormGroup = async (
  formCats: FormCategory[],
  courseId: number,
  scenarioId: number,
  answer: Partial<Answer>,
) => {
  return Promise.all(
    _.map(formCats, async (cat) => {
      const formCatId = cat.id;
      const resp = await api.getFormGroupWithFormCategoryId(formCatId);
      const formGroups = await loadForm(
        answer,
        resp.data,
        courseId,
        scenarioId,
        formCatId,
      );
      const isActivated = _.chain(formGroups)
        .map((f) => {
          return _.map(f.scores, (s) => s.isActivated);
        })
        .flattenDeep()
        .every(Boolean)
        .value();
      return {...cat, isActivated, formGroups};
    }),
  );
};

function* handleLoad({
  payload: {scenarioId, session},
}: PayloadAction<{scenarioId: number; session: Session}>) {
  try {
    const userId = yield select((state: RootState) => state[USER].user?.id);
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const sessionId = session.id;
    const resp = yield call(
      api.getFormCategoriesWithScenarioSessionId,
      scenarioId,
      sessionId,
      {userId, courseId, sessionId, scenarioId},
    );
    const tmp = yield call(loadFormGroup, resp.data, courseId, scenarioId, {
      userId,
      courseId,
      sessionId,
      scenarioId,
    });
    yield put(successLoad(tmp));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleAttach({
  payload: {formCategoryId, scenarioId, session},
}: PayloadAction<{
  formCategoryId: number;
  scenarioId: number;
  session: Session;
}>) {
  try {
    yield call(api.attachFormCategory, formCategoryId, {
      scenarioId,
      sessionId: session.id,
    });
    yield put(load({scenarioId, session}));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleDetach({
  payload: {formCategoryId, scenarioId, session},
}: PayloadAction<{
  formCategoryId: number;
  scenarioId: number;
  session: Session;
}>) {
  try {
    yield call(api.detachFormCategory, formCategoryId, {
      scenarioId,
      sessionId: session.id,
    });
    yield put(load({scenarioId, session}));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleAttachFormGroup({
  payload: {formCategoryId, formGroupIds, scenarioId, session},
}: PayloadAction<{
  formCategoryId: number;
  formGroupIds: number[];
  scenarioId: number;
  session: Session;
}>) {
  try {
    yield call(api.attachFormGroupToFC, formCategoryId, {
      scenarioId,
      formGroupIds,
      sessionId: session.id,
    });
    yield put(load({scenarioId, session}));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleDetachFormGroup({
  payload: {formCategoryId, formGroupIds, scenarioId, session},
}: PayloadAction<{
  formCategoryId: number;
  formGroupIds: number[];
  scenarioId: number;
  session: Session;
}>) {
  try {
    yield call(api.detachFormGroupToFC, formCategoryId, {
      scenarioId,
      formGroupIds,
      sessionId: session.id,
    });
    yield put(load({scenarioId, session}));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchFormCategories() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(attach, handleAttach);
  yield takeLatest(detach, handleDetach);
  yield takeLatest(attachFormGroup, handleAttachFormGroup);
  yield takeLatest(detachFormGroup, handleDetachFormGroup);
}
