import {takeLatest, call, put} from 'redux-saga/effects';
import api from '~/api';
import {load, successLoad} from './slice';

function* handleLoad() {
  try {
    const resp = yield call(api.getSchools);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchSchool() {
  yield takeLatest(load, handleLoad);
}
