import {makeStyles} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import {FormGroup} from '~/types';
import {renderCheckbox} from './common';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '19px 72px',
  },
  topWrap: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleWrap: {
    display: 'flex',
  },
  firstTitle: {
    color: '#495055',
    fontWeight: 700,
    marginRight: 10,
  },
  title: {
    color: '#495055',
    fontWeight: 700,
    fontSize: 20,
  },
  warning: {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid #DF3826',
    borderRadius: 2,
    marginTop: 8,
    padding: '6px 12px',
  },
  warningText: {
    color: '#495055',
    fontWeight: 400,
    marginRight: 26,
  },
  answerBox: {
    padding: '10px',
  },
  checkeBox: {
    padding: 8,
  },
}));

interface Props {
  showName?: boolean;
}

const AdmPlan = ({
  initialValues,
  showName,
}: InjectedFormProps<FormGroup, Props> & Props) => {
  const classes = useStyles();
  // console.log('initialValues', initialValues);
  return (
    <form>
      <div className={classes.answerBox}>
        {_.map(initialValues.forms, (form, index) => (
          <Field
            key={`adm-plan-cb-${index}`}
            checkStyle={classes.checkeBox}
            name={`forms[${index}].answers[0].content`}
            component={renderCheckbox}
            label={<Box>{form.name}</Box>}
          />
        ))}
      </div>
    </form>
  );
};

const AdmPlanForm = reduxForm<FormGroup, Props>({
  // a unique name for the form
  form: 'AdmPlan',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(AdmPlan);

export default AdmPlanForm;
