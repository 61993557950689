import {PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import api from '~/api';
import {RootState} from '~/app/rootReducers';
import {UserScore} from '~/types';
import {COURSE} from '../Course/slice';
import {openToast} from '../Toast/slice';
import {create, load, successLoad, update} from './slice';

function* handleLoad({
  payload: {scenarioId, sessionId, userId},
}: PayloadAction<{
  scenarioId?: number;
  sessionId?: number;
  userId?: number;
}>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const resp = yield call(
      api.getUserScore,
      courseId,
      scenarioId,
      sessionId,
      userId,
    );
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleCreate({payload}: PayloadAction<UserScore>) {
  try {
    const {id, score, formGroupId, courseId, ...restPayload} = payload;
    const resp = yield call(api.createUserScore, payload);
    yield put(openToast({message: '저장 완료'}));
    yield put(load(restPayload));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUpdate({payload}: PayloadAction<UserScore>) {
  try {
    const {id, score, formGroupId, courseId, ...restPayload} = payload;
    console.log('handleUpdate', payload, _.omitBy(payload, _.isNull));
    if (payload.id) {
      const omittedPayload = _.omitBy(payload, _.isNull) as UserScore;
      const resp = yield call(api.updateUserScore, payload.id, omittedPayload);
      yield put(openToast({message: '저장 완료'}));
      yield put(load(restPayload));
    }
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchUserScore() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(create, handleCreate);
  yield takeLatest(update, handleUpdate);
}
