import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Modal,
  TextField,
  Theme,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Paper} from '@material-ui/core';
import {submit} from 'redux-form';
import {useLocation} from 'react-router-dom';

import {RootState} from '~/app/rootReducers';
import * as FormBasis from '~/features/FormBasis/slice';
import {Form as FormT, ProblemState} from '~/types';
import * as CandidateForm from '~/features/CandidateForm/slice';
import {CANDIDATEFORM} from '~/features/CandidateForm/slice';
import * as Forms from '~/features/Forms/slice';
import {FORMS} from '~/features/Forms/slice';
import * as FormGroup from '~/features/FormGroup/slice';
import FormForm from '~/forms/Form';

import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  grid: {
    flex: 1,
    padding: '10px',
  },
  paper: {
    flex: 1,
    height: '80vh',
    overflow: 'auto',
  },
  modalPaper: {
    flex: 1,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a: FormT[], b: FormT[]) {
  const bIds = _.map(b, (f) => f.id);
  return a.filter((value) => bIds.indexOf(value.id) === -1);
}

function intersection(a: FormT[], b: FormT[]) {
  const bIds = _.map(b, (f) => f.id);
  return a.filter((value) => bIds.indexOf(value.id) !== -1);
}

const OptionScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation<ProblemState>();
  const {scenario, session, formGroupId} = location.state;

  const forms = useSelector((state: RootState) => state[FORMS].forms);
  const candidateForms = useSelector(
    (state: RootState) => state[CANDIDATEFORM].forms,
  );
  const formGroup = useSelector(
    (state: RootState) =>
      session &&
      _.find(
        state[FormGroup.FORMGROUPS].formGroups[session.type],
        (fg) => fg.id === formGroupId,
      ),
  );

  const [open, setOpen] = useState<boolean>(false);

  const [answerChecked, setAnswerChecked] = React.useState<FormT[]>([]);
  const [checked, setChecked] = React.useState<FormT[]>([]);
  const [right, setRight] = React.useState<FormT[]>([]);
  const [left, setLeft] = React.useState<FormT[]>([]);
  const [search, setSearch] = useState<string>('');
  const [form, setForm] = useState<FormT | undefined>();

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    dispatch(FormBasis.load());
  }, [dispatch]);

  useEffect(() => {
    if (formGroup && forms) {
      setAnswerChecked(
        _.filter(forms, (form) => _.includes(formGroup.answers, form.id)),
      );
    }
  }, [formGroup, forms]);

  useEffect(() => {
    if (forms) {
      setRight(forms);
    }
  }, [forms]);

  useEffect(() => {
    setLeft(
      _.filter(
        not(candidateForms, right),
        (form) => search === '' || form.name?.includes(search),
      ),
    );
  }, [candidateForms, right, search]);

  useEffect(() => {
    if (formGroup) {
      dispatch(Forms.load(formGroup.id));
    }
  }, [dispatch, formGroup]);

  useEffect(() => {
    if (scenario) {
      dispatch(CandidateForm.load(scenario.type));
    }
  }, [dispatch, scenario]);

  const handleToggle = (value: FormT) => () => {
    const currentIndex = _.findIndex(checked, (t) => t.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const addToAnswer = (value: FormT) => {
    const currentIndex = _.findIndex(answerChecked, (t) => t.id === value.id);
    const newChecked = [...answerChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (formGroup && session && scenario) {
      dispatch(
        FormGroup.update({
          session,
          scenarioId: scenario.id,
          formGroup: {
            id: formGroup.id,
            answers: _.map(newChecked, (a) => a.id),
          },
        }),
      );
    }

    setAnswerChecked(newChecked);
  };

  const customList = (items: FormT[], edit?: boolean) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value: FormT) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={
                    _.findIndex(checked, (t) => t.id === value.id) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{'aria-labelledby': labelId}}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
              {edit && (
                <div onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    checked={
                      _.findIndex(answerChecked, (t) => t.id === value.id) !==
                      -1
                    }
                    color="primary"
                    inputProps={{'aria-label': 'secondary checkbox'}}
                    onChange={(e) => addToAnswer(value)}
                  />
                  {/* <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      editFormGroup(value);
                    }}>
                    <AddCircleOutlineIcon fontSize="inherit" />
                  </IconButton> */}
                </div>
              )}
              <IconButton
                aria-label="settings"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  setForm(value);
                  setOpen(true);
                }}>
                <SettingsIcon fontSize="inherit" />
              </IconButton>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  const handleCheckedRight = () => {
    if (session && scenario && formGroup) {
      dispatch(
        FormGroup.attachForms({
          scenarioId: scenario.id,
          formGroupId: formGroup.id,
          session,
          formIds: _.map(leftChecked, (c) => c.id),
        }),
      );
    }
  };

  const handleCheckedLeft = () => {
    if (session && scenario && formGroup) {
      dispatch(
        FormGroup.detachForms({
          scenarioId: scenario.id,
          formGroupId: formGroup.id,
          session,
          formIds: _.map(rightChecked, (c) => c.id),
        }),
      );
    }
  };

  const handleAddFormGroup = useCallback(
    (form: FormT) => {
      if (scenario) {
        if (form.id) {
          dispatch(
            CandidateForm.update({
              ...form,
            }),
          );
        } else {
          dispatch(
            CandidateForm.create({
              scenarioType: scenario.type,
              form: {...form, scenarioType: scenario.type},
            }),
          );
        }
      }
    },
    [dispatch, scenario],
  );

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <div className={classes.root}>
      {formGroup?.name}
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        className={classes.grid}>
        <Grid xs item>
          <TextField
            fullWidth
            id="filled-search"
            label="Search field"
            type="search"
            variant="outlined"
            onChange={handleSearch}
          />
          {customList(left)}
        </Grid>
        <Grid xs={1} item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={() => {
                setForm(undefined);
                setOpen(true);
              }}
              aria-label="add formgroup">
              +
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right">
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left">
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid xs item>
          {customList(right, true)}
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Paper elevation={0} className={classes.modalPaper}>
          <FormForm
            initialValues={form}
            scenarioId={scenario?.id}
            onSubmit={handleAddFormGroup}
          />
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => dispatch(submit('Form'))}>Add</Button>
        </Paper>
      </Modal>
    </div>
  );
};

export default OptionScreen;
