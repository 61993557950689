import React from 'react';
import {Field, reduxForm, InjectedFormProps} from 'redux-form';
import {makeStyles} from '@material-ui/core';
import {Student} from '~/types';
import PersonIconField from '~/components/PersonIconField';

const useStyles = makeStyles((theme) => ({
  content: {padding: theme.spacing(1, 4, 1)},
}));

const StudentF = (props: InjectedFormProps<Student>) => {
  const classes = useStyles();
  const {handleSubmit} = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.content}>
        <Field
          component={PersonIconField}
          name="name"
          id="name"
          placeholder="Name"
        />
        <Field
          component={PersonIconField}
          name="studentId"
          id="studentId"
          placeholder="Student Id"
        />
      </div>
    </form>
  );
};

const StudentAddForm = reduxForm<Student>({
  // a unique name for the form
  form: 'student',
})(StudentF);
export default StudentAddForm;
