import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Container, Typography, Link} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    borderTop: '1px solid #DBDEDF',
    backgroundColor: '#FFFFFF',
    padding: 0,
  },

  wrap: {
    listStyleType: 'none',
    display: 'flex',
    paddingInlineStart: 0,
    flexWrap: 'wrap',
    margin: 0,
  },

  item: {
    flex: 'none',
    paddingLeft: 16.5,
    paddingRight: 16.5,
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    borderLeft: '1px solid #DBDEDF',
    color: '#495055',
    flexBasis: 'auto',
    '&:first-child': {
      borderLeft: 'none',
      paddingLeft: 23,
    },
    '&:last-child': {
      borderLeft: 'none',
      color: '#6E7880',
      marginLeft: 'auto',
      paddingRight: 21,
    },
  },
});

const Footer = () => {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <ul className={classes.wrap}>
        <li className={classes.item}>
          <Typography variant="body2">(주)휴먼브릿지</Typography>
        </li>
        <li className={classes.item}>
          <Typography variant="body2">대표자 : 임지영</Typography>
        </li>
        <li className={classes.item}>
          <Typography variant="body2">
            인천광역시 미추홀구 인하로 100, 5남 369호
          </Typography>
        </li>
        <li className={classes.item}>
          <Typography variant="body2">고객센터 : 032-000-0000</Typography>
        </li>
        <Link
          href="#"
          variant="body2"
          className={classes.item}
          underline="none">
          개인정보처리방침
        </Link>
        <li className={classes.item}>
          Copyright © 2020 (주)휴먼브릿지. All rights reserved
        </li>
      </ul>
    </Container>
  );
};

export default Footer;
