import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, {useState} from 'react';
import {WrappedFieldProps} from 'redux-form';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

interface FieldProps {
  label: string;
}

const useStyles = makeStyles((theme) => ({
  idStyle: {
    borderRadius: 2,
    fontSize: 14,
  },
}));

const PasswordField = ({
  label,
  input,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  return (
    <TextField
      label={label}
      placeholder={label}
      {...input}
      {...custom}
      variant="outlined"
      margin="dense"
      required
      fullWidth
      type={showPassword ? 'text' : 'password'}
      autoComplete="current-password"
      size="small"
      InputProps={{
        className: classes.idStyle,
        startAdornment: (
          <InputAdornment position="start">
            <LockOutlinedIcon fontSize="small" color="disabled" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              style={{
                padding: '11px 0px',
              }}>
              {showPassword ? (
                <VisibilityOutlinedIcon color="disabled" fontSize="small" />
              ) : (
                <VisibilityOffOutlinedIcon color="disabled" fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
