import React, {useCallback, useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import {Paper} from '@material-ui/core';
import {makeStyles, withStyles} from '@material-ui/core/styles';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import {styled} from '@material-ui/styles';

import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {RootState} from '~/app/rootReducers';
import withTemplate from '~/hoc/withTemplate';

import IntroScreen from '~/screens/IntroScreen';
import SubjectScreen from '~/screens/SubjectScreen';
import QuizScreen from '~/screens/QuizScreen';

import {
  FormGroup,
  ScenarioType,
  Session,
  SessionType,
  UserRoleType,
} from '~/types';
import SimulationScreen from '~/screens/SimulationScreen';
import AdmPlanScreen from '~/screens/AdmPlanScreen';
import AdmPlanResultScreen from '~/screens/AdmPlanResultScreen';
import {
  getFormValues,
  hasSubmitSucceeded,
  isDirty,
  isSubmitting,
  startSubmit,
  submit,
} from 'redux-form';

import {COURSE} from '~/features/Course/slice';
import {load, SESSION} from '~/features/Session/slice';
import {create, update} from '~/features/SessionTime/slice';
import * as ScenarioAction from '~/features/Scenario/slice';
import {SCENARIO} from '~/features/Scenario/slice';
import {USER} from '~/features/User/slice';

import AdmActionScreen from '~/screens/AdmActionScreen';
import MSEScreen from '~/screens/MSEScreen';
import CommAnalysisScreen from '~/screens/CommAnalysisScreen';
import PsySOAPIEScreen from '~/screens/PsySOAPIEScreen';
import PsyIntervRoutes from './PsyIntervRoutes';
import {SERVER_URI} from '~/contants';
import ChartScreen from '~/screens/ChartScreen';
import AsIsToBeScreen from '~/screens/AsIsToBeScreen';
import * as FormGroupAction from '~/features/FormGroup/slice';
import {FORMGROUPS} from '~/features/FormGroup/slice';
import SubmitCheckDialog from '~/components/SubmitCheckDialog';
import api from '~/api';
import QontoConnector from '~/components/QontoConnector';

const StyledStepLabel = styled(StepLabel)({
  '& .MuiTypography-body2': {
    fontWeight: 600,
    fontSize: 14,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: `0 ${theme.spacing(4)}px`,
    display: 'flex',
    flexDirection: 'column',
  },

  stepperWrap: {
    padding: '17px 0',
  },

  paper: {
    flex: 1,
    position: 'relative',
  },

  button: {
    boxShadow: 'none',
    '&:first-child': {
      marginRight: 5,
    },
  },
  buttonWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '12px 37px',
  },

  hidden: {
    // visibility: 'hidden',
    display: 'none',
  },
}));

interface ParamTypes {
  scenarioId: string;
  sessSeq: string;
}

const ScenarioRoutes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const {scenarioId, sessSeq} = useParams<ParamTypes>();

  const currentCourse = useSelector(
    (state: RootState) => state[COURSE].current,
  );
  const {loading} = useSelector((state: RootState) => state[FORMGROUPS]);
  const state = useSelector((state: RootState) => state);
  const formNames = useSelector((state: RootState) => _.keys(state['form']));
  const sessOrig = useSelector((state: RootState) => state[SESSION].sessions);
  const scenario = useSelector((state: RootState) =>
    _.find(state[SCENARIO].scenarios, (s) => s.id === parseInt(scenarioId)),
  );
  const user = useSelector((state: RootState) => state[USER].user);
  const [session, setSession] = useState<Session>();
  const [submitting, setSubmitting] = useState(false);
  const [submitVisible, setSubmitVisible] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [trial, setTrial] = useState<number>();
  const [passed, setPassed] = useState<boolean>(false);
  const [showAnswer, setShowAnswer] = useState(false);

  const sessions = useMemo(() => {
    return _.orderBy(sessOrig, ['seq']);
  }, [sessOrig]);

  const formGroupsRedux = useSelector(
    (state: RootState) => session && state[FORMGROUPS].formGroups,
  );

  const formGroups = useMemo(() => {
    if (session && formGroupsRedux) {
      return formGroupsRedux[session.type];
    }
    return [];
  }, [formGroupsRedux, session]);

  useEffect(() => {
    if (formGroups) {
      setTrial(
        _.chain(formGroups)
          .map((formGroup) => formGroup.trial)
          .compact()
          .max()
          .value() ?? 0,
      );

      setPassed(
        _.chain(formGroups)
          .map((formGroup) => formGroup.passed)
          .every()
          .value(),
      );
    }
  }, [formGroups]);

  useEffect(() => {
    if (session) {
      if (
        _.includes([SessionType.PRE_QUIZ, SessionType.POST_QUIZ], session.type)
      ) {
        setSubmitVisible(false);
        const nAnswer = _.chain(formGroups)
          .map((formGroup) => formGroup.uanswer)
          .flattenDeep()
          .compact()
          .value();

        console.log('nAnswer', nAnswer);

        if (nAnswer.length > 0) {
          console.log('nAnswer', true);
          setShowAnswer(true);
        } else {
          console.log('nAnswer', false);
          setShowAnswer(false);
        }
      } else if (
        _.includes(
          [SessionType.PSY_PROBLEM, SessionType.PSY_PLAN, SessionType.ADM_PLAN],
          session.type,
        )
      ) {
        const nAnswer = _.chain(formGroups)
          .map((formGroup) => formGroup.uanswer)
          .flattenDeep()
          .compact()
          .value();
        console.log(trial, trial && trial > 1, passed);
        if (passed || ((trial ?? 3) >= 2 && nAnswer.length > 0)) {
          setSubmitVisible(false);
        } else {
          setSubmitVisible(true);
        }
      } else if (
        !_.includes(
          [
            SessionType.CHART,
            SessionType.ADM_PLAN_RESULT,
            SessionType.SIMULATION,
          ],
          session.type,
        )
      ) {
        setSubmitVisible(true);
      } else {
        setSubmitVisible(false);
      }
    }
  }, [formGroups, session, trial, passed]);

  const memoizedValue = useMemo(() => {
    for (let i = 0; i < sessions.length; i++) {
      if (parseInt(sessSeq) === sessions[i].seq) {
        return i;
      }
    }
  }, [sessSeq, sessions]);

  useEffect(() => {
    const seq = parseInt(sessSeq);
    const sessIndex = _.findIndex(sessions, (sess) => sess.seq === seq);
    if (sessIndex > -1) {
      setSession(sessions[sessIndex]);
      setActiveStep(sessIndex);
    }
  }, [sessions, sessSeq]);

  useEffect(() => {
    console.log('useEffect');
    return () => {
      console.log('useEffect', 'clean up');
    };
  }, []);

  useEffect(() => {
    if (!currentCourse) {
      history.replace('/class');
    }
  }, [history, currentCourse]);

  useEffect(() => {
    if (
      currentCourse &&
      session &&
      scenarioId &&
      user &&
      user.role.role === UserRoleType.STUDENT
    ) {
      console.log('create session time');
      dispatch(
        create({
          scenarioId: parseInt(scenarioId),
          sessionId: session.id,
          courseId: currentCourse.id,
          userId: user.id,
        }),
      );
    }
    return () => {
      console.log('update session time');

      dispatch(update());
    };
  }, [currentCourse, session, scenarioId, user]);

  useEffect(() => {
    const checkSubmitting = async () => {
      if (session && submitting) {
        const forms = _.filter(formNames, (name) =>
          name.startsWith(session?.type),
        );
        const _submitting = _.chain(forms)
          .map((name) => isSubmitting(name)(state))
          .value();
        const submitted = _.chain(forms)
          .map((name) => hasSubmitSucceeded(name)(state))
          .value();

        if (_submitting.length > 0 && submitted.length > 0) {
          let submitSuccess = true;
          for (let i = 0; i < _submitting.length; i++) {
            if (_submitting[i] !== submitted[i]) {
              submitSuccess = false;
              break;
            }
          }
          if (submitSuccess) {
            alert('성공적으로 제출되었습니다.');
            setSubmitting(false);
            setSubmitVisible(false);
            if (
              formGroups?.[0]?.setId &&
              _.includes(
                [
                  SessionType.PSY_PROBLEM,
                  SessionType.PSY_PLAN,
                  SessionType.ADM_PLAN,
                ],
                session.type,
              )
            ) {
              const result = await api.checkAnswer(formGroups[0].setId);
              setPassed(result.data.pass);
              setTrial((t) => (t ?? 0) + 1);
            } else {
              if (session) {
                dispatch(
                  FormGroupAction.load({
                    scenarioId: parseInt(scenarioId),
                    session,
                  }),
                );
              }
            }
          }
        }
      }
    };

    checkSubmitting();
  }, [state, formNames, session, submitting, formGroups, scenarioId]);

  useEffect(() => {
    dispatch(load(parseInt(scenarioId)));
  }, [dispatch, scenarioId]);

  useEffect(() => {
    if (session) {
      dispatch(
        FormGroupAction.load({scenarioId: parseInt(scenarioId), session}),
      );
    }
  }, [dispatch, scenarioId, session]);

  useEffect(() => {
    if (currentCourse) {
      dispatch(ScenarioAction.load(currentCourse?.id));
    }
  }, [dispatch, currentCourse]);

  useEffect(() => {
    const seq = parseInt(sessSeq);
    setSession(_.find(sessions, (sess) => sess.seq === seq));
    setActiveStep(memoizedValue ?? 0);
  }, [memoizedValue, sessions, sessSeq]);

  const checkSubmit = useCallback(() => {
    if (session) {
      const nAnswer = _.chain(formGroups)
        .map((formGroup) => {
          const formName = `${session.type}-${formGroup.id}`;
          const form = getFormValues(formName)(state) as any;
          return form.uanswer || _.map(form.forms, (f) => f.answers);
        })
        .flattenDeep()
        .compact()
        .value().length;

      if (nAnswer > 0) {
        if (
          _.includes(
            [
              SessionType.PSY_PROBLEM,
              SessionType.PSY_PLAN,
              SessionType.ADM_PLAN,
            ],
            session.type,
          )
        ) {
          handleSubmit();
        } else {
          setOpenDialog(true);
        }
      } else {
        alert('한개이상 정답을 선택해주세요.');
      }
    }
  }, [session, formGroups, state]);

  const handleSubmit = useCallback(() => {
    if (session) {
      setSubmitting(true);
      console.log('formNames', formNames, session?.type);
      const formGroups = _.chain(formNames)
        .map((name) => {
          if (name.startsWith(session?.type) && isDirty(name)(state)) {
            console.log('submit');
            dispatch(submit(name));
            dispatch(startSubmit(name));
            return getFormValues(name)(state);
          }
        })
        .compact()
        .value() as FormGroup[];
      console.log(formGroups);
      dispatch(
        FormGroupAction.updateAnswer({
          scenarioId: parseInt(scenarioId),
          session,
          formGroups,
        }),
      );
    }
  }, [dispatch, session, formNames]);

  const screenMarkup = useMemo(() => {
    console.log('screenMarkup', submitVisible, session);

    if (session && scenario) {
      switch (session.type) {
        case SessionType.INTRO:
          return <IntroScreen scenario={scenario} session={session} />;
        case SessionType.CHART:
          return (
            <ChartScreen scenarioId={parseInt(scenarioId)} session={session} />
          );
        case SessionType.SUBJECT:
          return (
            <SubjectScreen
              scenarioId={parseInt(scenarioId)}
              session={session}
              abbr={scenario.type === ScenarioType.PSY}
            />
          );
        case SessionType.PRE_QUIZ:
        case SessionType.POST_QUIZ:
          return (
            <QuizScreen
              scenarioId={parseInt(scenarioId)}
              session={session}
              showAnswer={showAnswer}
              onShowSubmit={() => {
                if (!showAnswer) {
                  setSubmitVisible(true);
                }
              }}
            />
          );
        case SessionType.SIMULATION:
          return scenario && <SimulationScreen scenario={scenario} />;
        case SessionType.ASIS_TOBE:
          return (
            <AsIsToBeScreen
              scenarioId={parseInt(scenarioId)}
              session={session}
            />
          );
        case SessionType.ADM_EXPLORING:
          return (
            <SubjectScreen
              scenarioId={parseInt(scenarioId)}
              session={session}
              abbr
            />
          );
        case SessionType.ADM_PLAN_RESULT:
          return (
            <AdmPlanResultScreen
              scenarioId={parseInt(scenarioId)}
              session={session}
            />
          );
        case SessionType.ADM_ACTION:
          return (
            <SubjectScreen
              scenarioId={parseInt(scenarioId)}
              session={session}
              title="※ 간호관리활동 수행(Action)"
              abbr
              abbrPrefix="관리활동"
            />
          );
        case SessionType.MSE:
          return (
            <MSEScreen scenarioId={parseInt(scenarioId)} session={session} />
          );
        case SessionType.COMM_ANALYSIS:
          return (
            <CommAnalysisScreen
              scenarioId={parseInt(scenarioId)}
              session={session}
            />
          );
        case SessionType.PSY_PROBLEM:
        case SessionType.PSY_PLAN:
        case SessionType.ADM_PLAN:
          return (
            <AdmPlanScreen
              scenarioId={parseInt(scenarioId)}
              session={session}
              passed={passed}
              trial={trial}
            />
          );
        case SessionType.PSY_INTERVENTION:
          return (
            <PsyIntervRoutes
              scenarioId={parseInt(scenarioId)}
              session={session}
            />
          );
        case SessionType.PSY_SOAPIE:
          return (
            <PsySOAPIEScreen
              scenarioId={parseInt(scenarioId)}
              session={session}
            />
          );
      }
    }
  }, [scenario, submitVisible, scenarioId, session, trial, passed, showAnswer]);

  const handleNext = useCallback(() => {
    if (session?.type === SessionType.INTRO) {
      if (isDirty('intro-info')(state)) {
        dispatch(submit('intro-info'));
      }
    }

    if (memoizedValue !== undefined) {
      const findSeq = sessions[memoizedValue + 1].seq;
      const url = '/scenario/' + scenarioId + '/' + findSeq;
      history.push(url);
    }
  }, [session, state, memoizedValue, sessions, history]);

  const nextButtonMarkup = useMemo(() => {
    let hide = false;
    if (
      _.includes(
        [SessionType.ADM_PLAN, SessionType.PSY_PROBLEM, SessionType.PSY_PLAN],
        session?.type,
      ) &&
      submitVisible
    ) {
      hide = true;
    }

    if (
      _.includes(
        [SessionType.PRE_QUIZ, SessionType.POST_QUIZ],
        session?.type,
      ) &&
      !showAnswer
    ) {
      hide = true;
    }

    return (
      <Button
        variant="contained"
        color="primary"
        onClick={handleNext}
        className={`${classes.button} ${hide && classes.hidden}`}>
        다음단계
        <ChevronRightIcon />
      </Button>
    );
  }, [session, showAnswer, submitVisible]);

  const handleBack = () => {
    history.goBack();
  };

  const renderScenarioStepper = () => (
    <Stepper
      connector={<QontoConnector />}
      className={classes.stepperWrap}
      activeStep={activeStep}
      alternativeLabel>
      {_.map(sessions, (sessSeq) => (
        <Step key={sessSeq.id}>
          <StyledStepLabel>
            <>
              {_.chain(sessSeq.name)
                .split('\\r\\n')
                .map((name) => <div key={name}>{name}</div>)
                .value()}
            </>
          </StyledStepLabel>
        </Step>
      ))}
    </Stepper>
  );

  const handleDone = () => {
    history.replace('/class');
  };

  return (
    <div className={classes.root}>
      <div>{renderScenarioStepper()}</div>
      <Paper className={classes.paper} elevation={0} variant="outlined" square>
        {screenMarkup}
      </Paper>
      <div className={classes.buttonWrap}>
        <div>
          <Button
            variant="outlined"
            className={`${classes.button} ${
              (!submitVisible || loading) && classes.hidden
            }`}
            onClick={
              session &&
              (_.includes(
                [
                  SessionType.PRE_QUIZ,
                  SessionType.POST_QUIZ,
                  SessionType.ADM_PLAN,
                  SessionType.PSY_PROBLEM,
                  SessionType.PSY_PLAN,
                ],
                session.type,
              )
                ? checkSubmit
                : handleSubmit)
            }>
            제출하기
          </Button>
          {session &&
            session.type !== SessionType.INTRO &&
            session.sessionFile && (
              <Button
                variant="outlined"
                color="primary"
                href={`${SERVER_URI}/files/${session?.sessionFile.id}`}
                className={classes.button}>
                자료받기
              </Button>
            )}
        </div>

        {activeStep === sessions.length ? (
          <div></div>
        ) : (
          <div>
            {activeStep !== 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleBack}
                className={classes.button}>
                <ChevronLeftIcon />
                이전단계
              </Button>
            )}
            {activeStep !== sessions.length - 1 && nextButtonMarkup}

            {activeStep === sessions.length - 1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleDone}
                className={`${classes.button} ${
                  _.includes([SessionType.POST_QUIZ], session?.type) &&
                  submitVisible &&
                  classes.hidden
                }`}>
                완료하기
              </Button>
            )}
          </div>
        )}
      </div>
      <SubmitCheckDialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        onSubmit={() => {
          setOpenDialog(false);
          handleSubmit();
        }}
      />
    </div>
  );
};

export default withTemplate(ScenarioRoutes);
