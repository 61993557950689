import React from 'react';
import {Box, Typography} from '@material-ui/core';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  className?: string;
}

const TabPanel = (props: TabPanelProps) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      //   hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        visibility: value === index ? 'visible' : 'hidden',
        position: value === index ? 'relative' : 'absolute',
        // height: value === index ? undefined : 0,
      }}
      {...other}>
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
};

export default TabPanel;
