import React from 'react';
import {Route, useRouteMatch} from 'react-router-dom';
import withTemplate from '~/hoc/withTemplate';
import CourseScreen from '~/screens/CourseScreen';

import {makeStyles, Theme} from '@material-ui/core/styles';
import ProblemRoutes from './ProblemRoutes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    padding: '31px 0px',
  },
}));
const AdminCourseRoutes = () => {
  const match = useRouteMatch();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Route exact path={`${match.path}`} component={CourseScreen} />
      <Route path={`${match.path}/problems`} component={ProblemRoutes} />
    </div>
  );
};

export default withTemplate(AdminCourseRoutes);
