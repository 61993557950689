import React, {useCallback} from 'react';
import {Route, useRouteMatch} from 'react-router-dom';
import PsyIntervDetail from '~/screens/PsyIntervDetail';
import PsyIntervention from '~/screens/PsyIntervention';
import {Session} from '~/types';

interface Props {
  scenarioId: number;
  session: Session;
}

const PsyIntervRoutes = ({scenarioId, session}: Props) => {
  const match = useRouteMatch();

  const renderPsyInterv = useCallback(() => {
    return <PsyIntervention scenarioId={scenarioId} session={session} />;
  }, [scenarioId, session]);

  return (
    <div>
      <Route exact path={`${match.path}`} render={renderPsyInterv} />
      <Route path={`${match.path}/:formCatId`} component={PsyIntervDetail} />
    </div>
  );
};

export default PsyIntervRoutes;
