import moment from 'moment-timezone';

export interface UserScore {
  id?: number;
  score: number;
  courseId: number;
  scenarioId: number;
  formGroupId: number;
  sessionId: number;
  userId: number;
  formCategoryId?: number;
}

export interface Board {
  id: number;
  name: string;
  course?: Course;
}

export interface Post {
  id: number;
  title: string;
  content: string;
}

export interface IPasswordReset {
  token: string;
  password: string;
  verifyPassword: string;
}
export interface SessionTime {
  id: number;
  startTime: string;
  endTime: string;
  courseId: number;
  userId: number;
  sessionId: number;
  scenarioId: number;
}

export interface SessionTimeStat {
  courseId: number;
  userId: number;
  sessionId: number;
  scenarioId: number;
  time: number;
}

export interface SessionClear {
  courseId: number;
  userId: number;
  sessionId: number;
  scenarioId: number;
  done: boolean;
}

export type CreateSessionTime = Omit<
  SessionTime,
  'id' | 'startTime' | 'endTime'
>;

export interface StudentType {
  id: string;
  username: string;
}

export interface ProfileType {
  profile: string;
  name: string;
  school: string;
  studentId: string;
}

export interface LoginType {
  username: string;
  password: string;
}

export interface PasswordFindType {
  email: string;
}

export interface SignupType {
  username: string;
  password: string;
  verifyPassword: string;
  name: string;
  schoolId: string;
  studentId: string;
}

export interface LoginResponse {
  error: string;
  token?: string;
}

export interface User {
  id: number;
  username: string;
  schoolId: number;
  courses: Course[];
  role: UserRole;
  student?: Student;
  school?: School;
}

export interface UserInfo {
  id: number;
  sex: string;
  year: string;
  theory: string;
  practice: string;
  courseId: number;
  userId: number;
}

export type CreateUser = Omit<User, 'id' | 'courses' | 'role'> & {
  name: string;
  studentId: string;
  password: string;
};

export enum UserRoleType {
  ADMIN = 'admin',
  PROF = 'prof',
  STUDENT = 'student',
}

export interface UserRole {
  id: number;
  role: UserRoleType;
}

export interface Role {
  id: number;
  name: string;
  description: string;
  type: string;
  created_by: moment.Moment;
  updated_by: moment.Moment;
}

export interface UserSimple {
  id: number;
  firstname: string;
  lastname: string;
  username: string;
}

export interface JwtToken {
  id: number;
  iat: number;
  exp: number;
}

export interface CreateCourse {
  tmplId: number;
  name: string;
}

export interface Course {
  id: number;
  name: string;
  validFrom?: string;
  validTo?: string;
  school?: School;
  schoolId?: number;
  type: ScenarioType;
  scenarios?: Scenario[];
}

export interface School {
  id?: number;
  name: string;
  courses: Course[];
}

export interface Scenario {
  id: number;
  name: string;
  description?: string;
  thm?: string;
  simulation?: string;
  courses: Course[];
  type: ScenarioType;
  youtube?: string;
}

export interface Chart {
  id: number;
  name: string;
  url: string;
  seq: number;
}

export type CreateScenario = Omit<Scenario, 'id' | 'courses'>;

export enum ScenarioType {
  ADM = 'adm',
  PSY = 'psy',
}

export enum SessionType {
  INTRO = 'Intro',
  SUBJECT = 'Subject',
  PRE_QUIZ = 'Pre_Quiz',
  SIMULATION = 'Simulation',
  ASIS_TOBE = 'AsIs_ToBe',
  COMM_ANALYSIS = 'Comm_Analysis',
  ADM_PLAN = 'Adm_Plan',
  ADM_PLAN_RESULT = 'Adm_Plan_Result',
  ADM_ACTION = 'Adm_Action',
  ADM_EXPLORING = 'Adm_Exploring',
  PSY_PROBLEM = 'Psy_Problem',
  PSY_PLAN = 'Psy_Plan',
  PSY_INTERVENTION = 'Psy_Intervention',
  PSY_SOAPIE = 'Psy_Soapie',
  POST_QUIZ = 'Post_Quiz',
  MSE = 'MSE',
  EBP = 'EBP',
  CHART = 'Chart',
}

export interface SessionDescription {
  id: number;
  description: string;
  scenarioId: number;
  sessionId: number;
}

export interface Session {
  id: number;
  name: string;
  type: SessionType;
  seq: number;
  sessionFile: SessionFile;
  sessionDescriptions: SessionDescription[];
}

export interface SessionFile {
  id: number;
  name: string;
}

export interface ProblemOutcome {
  id: number;
  name: string;
  seq: number;
  sessions: Session[];
}

export interface RandomFormSet {
  id: number;
  forms: number[];
  canswers: number[];
  ansers: Answer[];
  courseId: number;
  scenarioId: number;
  formGroupId: number;
  sessionId: number;
  userId: number;
  passed?: boolean;
  trial?: number;
}

export interface FormGroup {
  id: number;
  name: string;
  type: FormGroupType;
  explain?: string;
  image?: string;
  object?: string;
  example?: string;
  uanswer?: Answer;
  answers?: number[];
  trial?: number;
  forms: Form[];
  score: Score;
  scores: Score[];
  scenarioType: ScenarioType;
  randomFormSets: RandomFormSet[];
  setId?: number;
  passed?: boolean;
  mediaType?: string;
  formCategoryId?: number;
  order?: number;
}

export enum FormGroupType {
  OBJECTIVE = 'objective',
  SUBJECTIVE = 'subjective',
  MULTIPLE = 'multiple',
}

export enum ScoreType {
  RANDOM = 'random',
  DEFAULT = 'default',
}

export interface Score {
  id?: number;
  score: number;
  courseId?: number;
  total?: number;
  thres?: number;
  isActivated: boolean;
  mode?: ScoreType;
  formCategoryId?: number;
  sessionId?: number;
  scenarioId?: number;
}

export type CreateScore = Omit<Score, 'id'>;

export interface Form {
  id: number;
  name: string;
  seq: number;
  isMultiline?: boolean;
  maxLength?: number;
  minLength?: number;
  isAnswer?: boolean;
  isTitleOnly?: boolean;
  part: string;
  formBasisId?: number;
  formBases?: FormBasis[];
  answers?: Answer[];
  hasImage?: boolean;
  scenarioType: ScenarioType;
}

export interface FormBasis {
  id: number;
  content: string;
}

export interface Answer {
  id: number;
  content: string;
  userId: number;
  formId?: number;
  courseId: number;
  scenarioId: number;
  formGroupId: number;
  sessionId: number;
  formCategoryId?: number;
  randomFormSetId: number;
  trial: number;
  createdAt?: string;
  deletedAt?: string;
  updatedAt?: string;
}

export interface UploadSessionFile {
  sessionId: number;
  scenarioId: number;
  formData: FormData;
}

export interface AttachFormGroup {
  sessionId: number;
  session: Session;
  scenarioId: number;
  formGroupIds: number[];
}

export interface FormCategory {
  id: number;
  name: string;
  category: number;
  comment: string;
  formGroups: FormGroup[];
  isActivated?: boolean;
}

export interface Student {
  id: number;
  name: string;
  studentId: string;
  courseIds: number[];
  courseIds2: number[];
  activates: boolean[];
  user?: User;
}

export interface UploadStudentFile {
  formData: FormData;
}

export interface ProblemState {
  session?: Session;
  scenario?: Scenario;
  formGroupId?: number;
}
