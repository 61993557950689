import React from 'react';
import {Route, useRouteMatch} from 'react-router-dom';
import withTemplate from '~/hoc/withTemplate';
import BoardScreen from '~/screens/BoardScreen';

const BoardRoutes = () => {
  const match = useRouteMatch();
  return (
    <>
      <Route exact path={match.path} component={BoardScreen} />
    </>
  );
};

export default withTemplate(BoardRoutes);
