import {
  Divider,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import Board from '~/components/Board';
import {BOARD, load} from '~/features/Board/slice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    padding: '31px 31px',
    display: 'flex',
    flexDirection: 'row',
  },
  margin: {
    marginRight: 16,
  },
  content: {
    flex: 1,
  },
}));

const BoardScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const boards = useSelector((state: RootState) => state[BOARD].boards);

  const [boardId, setBoardId] = useState<number>();

  useEffect(() => {
    dispatch(load());
  }, []);

  useEffect(() => {
    if (boards[0]) {
      setBoardId(boards[0].id);
    }
  }, [boards]);

  const updateBoard = (id: number) => {
    setBoardId(id);
  };

  return (
    <div className={classes.root}>
      <div className={classes.margin}>
        {_.map(boards, (board) => (
          <List key={`board-list-${board.id}`}>
            <ListItem
              button
              component="a"
              onClick={() => updateBoard(board.id)}>
              <ListItemText primary={`${board.name} (${board.course?.name})`} />
            </ListItem>
            <Divider />
          </List>
        ))}
      </div>
      <div className={classes.content}>
        {boardId && <Board boardId={boardId} />}
      </div>
    </div>
  );
};

export default BoardScreen;
