import React, {useEffect} from 'react';
import {RouteProps, Route} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {LOGIN} from '~/features/Login/slice';
import {RootState} from '~/app/rootReducers';
import HomeScreen from '~/screens/HomeScreen';
import {openLoginModal} from '~/features/Modal/slice';

const AuthorizedRoute = (props: RouteProps) => {
  const authorized = useSelector((state: RootState) => state[LOGIN].authorized);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!authorized) {
      dispatch(openLoginModal());
    }
  }, [dispatch, authorized]);

  return authorized ? <Route {...props} /> : <HomeScreen />;
};

export default AuthorizedRoute;
