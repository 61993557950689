import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {School} from '~/types';

interface State {
  loading: boolean;
  schools: School[];
}

let initialState: State = {
  loading: false,
  schools: [],
};

const slice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    load: (state) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<School[]>) => {
      state.loading = false;
      state.schools = payload;
    },
  },
});

export const {load, successLoad} = slice.actions;
export const SCHOOL = slice.name;
export default slice.reducer;
