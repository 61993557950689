import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import {RootState} from '~/app/rootReducers';
import * as SessionSlice from '~/features/Session/slice';
import * as StudentSlice from '~/features/Student/slice';
import * as SessionTimeSlice from '~/features/SessionTime/slice';
import {Session} from '~/types';

interface Props {
  scenarioId: number;
  sessions: Session[];
}

const useStyles = makeStyles({
  container: {
    width: '95%',
    margin: 'auto',
    marginTop: '50px',
  },
  tblContainer: {
    border: 'none',
    boxSizing: 'border-box',
    overflowX: 'auto',
  },
  table: {
    minWidth: (props: Props) => (props.sessions.length + 3) * 180,
  },
  tblHead: {
    fontWeight: 600,
  },
  activeCell: {
    display: 'flex',
  },
  tblCircle: {
    width: 8,
    color: '#33BE63',
    display: 'flex',
    marginRight: 5,
  },
  tblInactCircle: {
    width: 8,
    color: '#DF3826',
    display: 'flex',
    marginRight: 5,
  },
});

const ProgressScreen = (props: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);

  const {scenarioId, sessions} = props;

  const students = useSelector(
    (state: RootState) => state[StudentSlice.STUDENT].students,
  );
  const sessionTimeStat = useSelector(
    (state: RootState) => state[SessionTimeSlice.SESSIONTIME].sessionTimeStat,
  );
  const sessionClear = useSelector(
    (state: RootState) => state[SessionSlice.SESSION].sessionClears,
  );

  useEffect(() => {
    dispatch(StudentSlice.load());
  }, [dispatch]);

  useEffect(() => {
    dispatch(SessionTimeSlice.load(scenarioId));
    dispatch(SessionSlice.loadSessionClear(scenarioId));
  }, [dispatch, scenarioId]);

  return (
    <div className={classes.container}>
      <TableContainer className={classes.tblContainer}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tblHead}>학번</TableCell>
              <TableCell className={classes.tblHead}>이름</TableCell>
              <TableCell className={classes.tblHead}>교육 진행 현황</TableCell>
              {_.map(sessions, (sess) => (
                <TableCell
                  key={`table-header-${sess.id}`}
                  className={classes.tblHead}>
                  {sess.name.replace('\\r\\n', ' ')}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(students, (student) => {
              const timeStat = _.filter(
                sessionTimeStat,
                (s) => s.userId === student.user?.id,
              );

              return (
                <TableRow key={`student-${student.id}`}>
                  <TableCell>{student.studentId}</TableCell>
                  <TableCell>{student.name}</TableCell>
                  <TableCell>
                    {_.find(sessionClear, (s) => s.userId === student.user?.id)
                      ?.done ? (
                      <Typography
                        variant="body2"
                        className={classes.activeCell}>
                        <FiberManualRecordIcon className={classes.tblCircle} />
                        완료
                      </Typography>
                    ) : (
                      <Typography
                        variant="body2"
                        className={classes.activeCell}>
                        <FiberManualRecordIcon
                          className={classes.tblInactCircle}
                        />
                        미완료
                      </Typography>
                    )}
                  </TableCell>
                  {_.map(sessions, (sess) => (
                    <TableCell>
                      {_.find(timeStat, (s) => s.sessionId === sess.id)?.time ??
                        '-'}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProgressScreen;
