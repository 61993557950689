import React from 'react';
import {Modal, Paper, Typography, makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {useCallback} from 'react';
import {PasswordFindType} from '~/types';
import {MODAL, closePasswordModal} from '~/features/Modal/slice';
import PasswordFindForm from '~/forms/PasswordFindForm';
import {findPassword} from '~/features/User/slice';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    width: 331,
    height: 172,
    left: '50%',
    top: '50%',
    transform: 'Translate(-50%,-50%)',
    borderRadius: 2,
  },
  divPassTypo: {
    width: 331,
    height: 44,
    boxShadow: 'inset 0px -1px 0px #DBDEDF',
    padding: '6px 161px 6px 24px',
  },
  passTypo: {
    width: 300,
  },
}));

const PasswordModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector((state: RootState) => state[MODAL].passwordModal);
  const handleClose = () => {
    dispatch(closePasswordModal());
  };

  const handleSubmit = useCallback(
    (data: PasswordFindType) => {
      console.log('PasswordModal', 'handleSubmit', data);
      dispatch(findPassword(data));
    },
    [dispatch],
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.divPassTypo}>
          <Typography className={classes.passTypo} variant="h6">
            Forgot your password
          </Typography>
        </div>
        <PasswordFindForm onSubmit={handleSubmit} />
      </Paper>
    </Modal>
  );
};

export default PasswordModal;
