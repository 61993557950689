import {makeStyles} from '@material-ui/core';
import React from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import {FormGroup, FormGroupType} from '~/types';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    height: '100%',
    paddingLeft: 24,
    paddingRight: 26,
    marginTop: 24,
  },
}));

const FormGroupF = (props: InjectedFormProps<FormGroup>) => {
  const classes = useStyles();
  const {handleSubmit} = props;
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div>
        <label>문제명</label>
        <Field name="name" component="input" type="text" />
      </div>
      <div>
        <label>Type</label>
        <Field name="type" component="select">
          <option />
          <option value={FormGroupType.SUBJECTIVE}>주관식</option>
          <option value={FormGroupType.OBJECTIVE}>객관식</option>
          <option value={FormGroupType.MULTIPLE}>다중선택</option>
        </Field>
      </div>
      <div>
        <label>설명 (option)</label>
        <Field name="explain" component="input" type="text" />
      </div>
      <div>
        <label>이미지 (option)</label>
        <Field name="image" component="input" type="text" />
      </div>
      <div>
        <label>학습목표 (option)</label>
        <Field name="object" component="input" type="text" />
      </div>
      <div>
        <label>해설 (option)</label>
        <Field name="example" component="input" type="text" />
      </div>
      <div>
        <label>순서 (option)</label>
        <Field
          name="order"
          component="input"
          type="number"
          parse={(value: string) => Number(value)}
        />
      </div>
    </form>
  );
};

const FormGroupForm = reduxForm<FormGroup>({
  form: 'FormGroup',
})(FormGroupF);

export default FormGroupForm;
