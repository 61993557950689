import {makeStyles} from '@material-ui/core';
import React from 'react';
import {FieldArray, InjectedFormProps, reduxForm} from 'redux-form';
import {FormGroup} from '~/types';
import {renderTextFormsField} from './common';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '0 25px 15px 25px',

    padding: `0 ${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(
      4,
    )}px`,
    backgroundColor: '#E7F5FC',
  },
  typoStyle: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    marginTop: 10,
    marginBottom: 5,
  },
  txtFieldStyle: {
    width: '100%',
    backgroundColor: '#FFF',
  },
  txtAreaStyle: {
    width: '100%',
    minHeight: 36,
    borderRadius: 2,
    resize: 'none',
    border: '1px solid #DBDEDF',
  },
}));

interface Props {
  readOnly?: boolean;
}

const Subject = ({
  initialValues,
  readOnly,
}: InjectedFormProps<FormGroup, Props> & Props) => {
  const classes = useStyles();
  if (initialValues.forms && initialValues.forms.length > 0) {
    return (
      <form className={classes.root}>
        <FieldArray
          name="forms"
          readOnly={readOnly}
          typoStyle={classes.typoStyle}
          txtFieldStyle={classes.txtFieldStyle}
          txtAreaStyle={classes.txtAreaStyle}
          component={renderTextFormsField}
          rerenderOnEveryChange
        />
      </form>
    );
  } else {
    return <></>;
  }
};

const SubjectForm = reduxForm<FormGroup, Props>({
  // a unique name for the form
  form: 'Subject',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Subject);

export default SubjectForm;
