import React, {useEffect} from 'react';
import {Modal, Paper, Typography, makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {useCallback} from 'react';
import {ProfileType} from '~/types';
import {MODAL, closeProfileModal} from '~/features/Modal/slice';
import ProfileForm from '~/forms/ProfileForm';
import * as schoolReducer from '~/features/School/slice';

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: 'column',
    width: 331,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'Translate(-50%,-50%)',
  },
  title: {
    height: 44,
    padding: '6px 26px',
    borderBottom: '1px solid #DBDEDF',
  },
}));

const ProfileEditModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector((state: RootState) => state[MODAL].profileModal);

  useEffect(() => {
    if (open) {
      dispatch(schoolReducer.load());
    }
  }, [dispatch, open]);

  const handleClose = () => {
    dispatch(closeProfileModal());
  };

  const handleSubmit = useCallback(
    (data: ProfileType) => {
      dispatch(closeProfileModal());
    },
    [dispatch],
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper elevation={0} className={classes.paper}>
        <Typography component="h1" variant="h6" className={classes.title}>
          Edit Profile
        </Typography>
        <ProfileForm onSubmit={handleSubmit} />
      </Paper>
    </Modal>
  );
};

export default ProfileEditModal;
