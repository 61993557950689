import {Box, Container, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import image from '~/Image.png';
import {Session} from '~/types';
import {SERVER_URI} from '~/contants';

const useStyles = makeStyles((theme) => ({
  addInfo: {
    backgroundColor: '#F1F9FF',
    padding: '10px 24px 10px 24px',
    boxShadow: '0 0 0 0.2px #000 inset',
  },
  syllaBox: {
    width: '100%',
    height: '50px',
    margin: 'auto',
    backgroundColor: '#fff',
    boxShadow: '0 0 0 0.2px #000 inset',
  },
  syllaBoxTxt: {
    display: 'inline-block',
    position: 'relative',
    top: 2,
    left: 20,
  },
  syllaImg: {
    width: 40,
    position: 'relative',
    top: 3,
    left: 3,
  },
  syllaBoxIcon: {
    position: 'relative',
    top: '-70%',
    left: '94%',
    boxShadow: '0 0 0 1px #DBDEDF inset',
    borderRadius: '100%',
    width: 32,
    height: 32,
    cursor: 'pointer',
    '@media screen and (max-width:1010px)': {
      left: '89%',
    },
    '@media screen and (max-width:500px)': {
      left: '86%',
    },
  },
  syllaBoxIconIn: {
    position: 'relative',
    top: 7,
    left: 6,
  },
}));

interface Props {
  session: Session;
}

const IntroSylla = ({session}: Props) => {
  const classes = useStyles();

  return (
    <form>
      <div>
        <Container className={classes.addInfo}>
          <Box className={classes.syllaBox}>
            <span className={classes.syllaImg}>
              <img src={image} className={classes.syllaImg} />
            </span>
            <Box className={classes.syllaBoxTxt}>
              <Typography variant="body2">강의계획서.xls</Typography>
              <Typography variant="body2" color="textSecondary">
                {/* (1023K) */}&nbsp;
              </Typography>
            </Box>
            <Box className={classes.syllaBoxIcon}>
              <a
                target="_blank"
                href={`${SERVER_URI}/files/${session?.sessionFile.id}`}
                rel="noopener noreferrer"
                download>
                <GetAppIcon
                  fontSize="small"
                  color="action"
                  className={classes.syllaBoxIconIn}
                />
              </a>
            </Box>
          </Box>
        </Container>
      </div>
    </form>
  );
};

export default IntroSylla;
