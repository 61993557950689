import {takeLatest, call, put, select} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import api from '~/api';
import {
  attachFormGroup,
  deleteFile,
  DeleteSessionFile,
  detachFormGroup,
  load,
  successLoad,
  uploadFile,
  loadSessionClear,
  successLoadSessionClear,
} from './slice';
import {AttachFormGroup, UploadSessionFile} from '~/types';
import {RootState} from '~/app/rootReducers';
import {COURSE} from '../Course/slice';
import * as FormGroup from '../FormGroup/slice';

function* handleLoadSessionClear({payload}: PayloadAction<number>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const resp = yield call(api.getSessionClear, courseId, payload);
    yield put(successLoadSessionClear(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoad({payload: scenarioId}: PayloadAction<number>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const resp = yield call(
      api.getSessionsWithScenarioId,
      scenarioId,
      courseId,
    );
    //const tmp = yield call(loadFile, resp.data, courseId);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUploadFile({payload}: PayloadAction<UploadSessionFile>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const {sessionId, scenarioId, formData} = payload;
    yield call(
      api.uploadSessionFile,
      sessionId,
      courseId,
      scenarioId,
      formData,
    );
    yield put(load(payload.scenarioId));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleAttachFormGroup({payload}: PayloadAction<AttachFormGroup>) {
  try {
    const {session, ...restPayload} = payload;
    yield call(api.attachFormGroup, restPayload);
    yield put(FormGroup.load({session, scenarioId: restPayload.scenarioId}));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleDetachFormGroup({payload}: PayloadAction<AttachFormGroup>) {
  try {
    const {session, ...restPayload} = payload;
    yield call(api.detachFormGroup, restPayload);
    yield put(FormGroup.load({session, scenarioId: restPayload.scenarioId}));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleDeleteFile({payload}: PayloadAction<DeleteSessionFile>) {
  try {
    yield call(api.deleteSessionFile, payload.file.id);
    yield put(load(payload.scenarioId));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchSession() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(loadSessionClear, handleLoadSessionClear);
  yield takeLatest(uploadFile, handleUploadFile);
  yield takeLatest(attachFormGroup, handleAttachFormGroup);
  yield takeLatest(detachFormGroup, handleDetachFormGroup);
  yield takeLatest(deleteFile, handleDeleteFile);
}
