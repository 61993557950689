import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  makeStyles,
  styled,
  InputBase,
  Radio,
  RadioProps,
  Typography,
} from '@material-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {FORMGROUPS, updateScore} from '~/features/FormGroup/slice';
import {ScoreType, Session} from '~/types';
import Link from '@material-ui/core/Link';
import {openAdmPlanModal} from '~/features/Modal/slice';
import AdmPlanModal from '~/modals/AdmPlanModal';
import _ from 'lodash';

interface Props {
  session: Session;
  scenarioId: number;
}
const useStyles = makeStyles((theme) => ({
  root: {
    margin: '100px auto',
    width: '80%',
    borderRadius: 0,
    boxShadow: 'none',
  },
  tableWrap: {
    border: '1px solid #E5E5E5',
  },

  fontBold: {
    fontWeight: 600,
  },
  radioBoxWrap: {
    margin: 0,
    padding: 0,
  },
  input: {
    padding: 0,
  },
  button: {
    marginLeft: '83%',
    marginBottom: 110,
    boxShadow: 'none',
    borderRadius: 2,
  },

  icon: {
    borderRadius: '100%',
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#FFFFFF',
  },
  checkedIcon: {
    borderRadius: '100%',
    backgroundColor: '#2595FF',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
  },
}));

const StyledTableCell = styled(TableCell)({
  border: '1px solid #E5E5E5',
  padding: 14,
});

const StyledLink = styled(Link)({
  color: '#000000',
  textDecoration: 'none',
  cursor: 'pointer',
});

function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      classes={{root: classes.radioBoxWrap}}
      disableRipple
      checkedIcon={<span className={(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const RandomProblemSetting = ({session, scenarioId}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formGroup = useSelector(
    (state: RootState) => state[FORMGROUPS].formGroups?.[session.type]?.[0],
  );

  const [score, setScore] = useState<number>(
    formGroup?.scores?.[0]?.score ?? 6,
  );
  const [total, setTotal] = useState<number>(
    formGroup?.scores?.[0]?.total ?? 10,
  );
  const [thres, setThres] = useState<number>(
    formGroup?.scores?.[0]?.thres ?? 60,
  );

  const [selectedValue, setSelectedValue] = React.useState<ScoreType>(
    ScoreType.DEFAULT,
  );

  useEffect(() => {
    if (formGroup?.scores?.[0]) {
      setScore(formGroup.scores[0].score);
      setTotal(formGroup.scores[0].total ?? 10);
      setThres(formGroup.scores[0].thres ?? 60);
    }
  }, [formGroup]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value as ScoreType);
  };

  const showProblem = useCallback(() => {
    dispatch(openAdmPlanModal());
  }, [dispatch]);

  const handleSave = useCallback(() => {
    dispatch(
      updateScore({
        score: {
          score,
          total,
          thres,
          isActivated: formGroup?.scores?.[0]?.isActivated,
          mode: selectedValue,
          sessionId: session.id,
        },
        formGroup,
        session,
        scenarioId,
      }),
    );
  }, [
    dispatch,
    formGroup,
    score,
    total,
    thres,
    selectedValue,
    session,
    scenarioId,
  ]);

  useEffect(() => {
    if (formGroup?.scores?.[0]?.mode) {
      setSelectedValue(formGroup.scores[0].mode);
    }
  }, [formGroup]);

  console.log(formGroup);

  return (
    <div>
      <TableContainer component={Paper} className={classes.root}>
        <Table aria-label="simple table" className={classes.tableWrap}>
          <TableHead>
            <TableRow>
              <StyledTableCell align="center"></StyledTableCell>
              <StyledTableCell align="left">총 노출 문제 설정</StyledTableCell>
              <StyledTableCell align="left">정답 문항</StyledTableCell>
              <StyledTableCell className={classes.fontBold} align="left">
                통과점수 설정 (%)
              </StyledTableCell>
            </TableRow>

            <TableRow>
              <StyledTableCell align="center">
                <StyledRadio
                  checked={selectedValue === ScoreType.DEFAULT}
                  onChange={handleChange}
                  value={ScoreType.DEFAULT}
                  name="select"
                />
              </StyledTableCell>
              <StyledTableCell align="left">
                <InputBase
                  type="number"
                  value={total}
                  onChange={(e) => setTotal(parseInt(e.target.value))}
                  classes={{input: classes.input}}
                />
                /{formGroup?.forms.length ?? '-'}
              </StyledTableCell>
              <StyledTableCell align="left">
                <InputBase
                  type="number"
                  value={score}
                  onChange={(e) => setScore(parseInt(e.target.value))}
                  classes={{input: classes.input}}
                />
                /
                {
                  _.intersection(
                    _.map(formGroup?.forms, (f) => f.id),
                    formGroup?.answers,
                  ).length
                }
              </StyledTableCell>
              <StyledTableCell align="left">
                <InputBase
                  type="number"
                  value={thres}
                  onChange={(e) => setThres(parseInt(e.target.value))}
                  classes={{input: classes.input}}
                />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell align="center">
                <StyledRadio
                  checked={selectedValue === ScoreType.RANDOM}
                  onChange={handleChange}
                  value={ScoreType.RANDOM}
                  name="select"
                />
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography>무작위 5~10</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography>무작위 1~5</Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <InputBase
                  type="number"
                  value={thres}
                  onChange={(e) => setThres(parseInt(e.target.value))}
                  classes={{input: classes.input}}
                />
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => handleSave()}>
        저장하기
      </Button>
      <AdmPlanModal session={session} scenarioId={scenarioId} />
    </div>
  );
};

export default RandomProblemSetting;
