import React from 'react';
import {Field, reduxForm, InjectedFormProps} from 'redux-form';
import {
  Button,
  makeStyles,
  styled,
  Typography,
  Grid,
  ButtonBase,
} from '@material-ui/core';
import {ProfileType} from '~/types';
import Avatar from '@material-ui/core/Avatar';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TextField from '@material-ui/core/TextField';
import {Autocomplete} from '@material-ui/lab';
import {useDispatch, useSelector} from 'react-redux';
import {closeProfileModal} from '~/features/Modal/slice';
import PersonIconField from '~/components/PersonIconField';
import {RootState} from '~/app/rootReducers';
import {SCHOOL} from '~/features/School/slice';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  fieldWrap: {
    padding: '24px 26px 16px 24px',
  },

  editProfileBtn: {
    width: '100%',
  },

  profileField: {
    height: 64,
    padding: 8,
    borderRadius: 2,
    border: '1px solid rgba(0, 0, 0, 0.23)',
  },

  fileInfo: {
    position: 'fixed',
    left: 90,
    width: 200,
    padding: 2,
  },

  profileSize: {
    width: 48,
    height: 48,
    marginRight: 8,
  },

  closeBtnWrap: {
    position: 'relative',
    top: 3,
    right: 10,
  },

  closeBtn: {
    border: '1px solid #DBDEDF',
    borderRadius: '50%',
  },

  btnRoot: {
    minWidth: 0,
    padding: 8,
  },

  nameField: {
    marginTop: 20,
  },

  selectField: {
    marginTop: 16,
    marginBottom: 16,
  },
  selectTxtField: {
    fontSize: 14,
  },
  buttonWrap: {
    margin: '0px 0px 24px 160px',
  },
}));

const StyledButton = styled(Button)({
  textTransform: 'none',
  margin: '0px 0px 0px 8px ',
  boxShadow: 'none',
});

const Profile = (props: InjectedFormProps<ProfileType>) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const schools = useSelector((state: RootState) => state[SCHOOL].schools);

  const {handleSubmit} = props;

  const handleImageChange = () => {};
  const handleImageDel = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
  };
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div className={classes.fieldWrap}>
        <ButtonBase
          className={classes.editProfileBtn}
          onClick={handleImageChange}>
          <Grid container className={classes.profileField}>
            <Grid item>
              <Avatar
                variant="square"
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                className={classes.profileSize}></Avatar>
            </Grid>
            <Grid item xs={12} sm container className={classes.fileInfo}>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography
                    gutterBottom
                    variant="body2"
                    color="primary"
                    align="left">
                    Filename.svg
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="left"
                    gutterBottom>
                    (1023K)
                  </Typography>
                </Grid>
              </Grid>
              <Grid item className={classes.closeBtnWrap}>
                <Button
                  onClick={handleImageDel}
                  classes={{root: classes.btnRoot}}
                  className={classes.closeBtn}>
                  <DeleteOutlineIcon
                    fontSize="small"
                    color="action"></DeleteOutlineIcon>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ButtonBase>

        <div>
          <Field
            component={PersonIconField}
            className={classes.nameField}
            name="name"
            id="name"
            placeholder="Your Name"
          />
        </div>

        <div className={classes.selectField}>
          <Autocomplete
            id="schoolSelect"
            options={schools}
            getOptionLabel={(option) => option.name}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.selectTxtField}
                label="School Selection"
                variant="outlined"
              />
            )}
          />
        </div>

        <div>
          <Field
            component={PersonIconField}
            name="studentId"
            id="studentId"
            placeholder="Student ID"
          />
        </div>
      </div>

      <div className={classes.buttonWrap}>
        <StyledButton
          type="button"
          variant="outlined"
          size="small"
          onClick={() => dispatch(closeProfileModal())}>
          Cancel
        </StyledButton>
        <StyledButton
          type="submit"
          variant="contained"
          color="primary"
          size="small">
          Submit
        </StyledButton>
      </div>
    </form>
  );
};

const ProfileForm = reduxForm<ProfileType>({
  // a unique name for the form
  form: 'Profile',
})(Profile);
export default ProfileForm;
