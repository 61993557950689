import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {COURSE, load, saveCurrent} from '~/features/Course/slice';
import {USER} from '~/features/User/slice';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  makeStyles,
  styled,
  Theme,
  Link,
} from '@material-ui/core';
import {Course as CourseType, UserRoleType} from '~/types';
import CourseCreateModal from '~/modals/CourseCreateModal';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: '#E7F5FC',
    width: '90%',
    margin: 'auto',
    height: '720px',
    padding: 40,
  },
  item: {
    display: 'inline-block',
    height: '513px',
    width: '400px',
    border: '0.5px solid #000000',
    borderRadius: '2px',
    marginRight: 40,
    '&:last-child': {
      marginRight: 0,
    },
  },
  cardHeader: {
    height: 44,
    padding: '5px 0px 0px 16px',
  },
  mediaImg: {width: 400, height: 400, filter: 'grayscale(100%)'},
  startImg: {width: 400, height: 400, filter: 'none'},
  actionField: {
    width: 240,
    marginLeft: 20,
  },
  cardAction: {
    width: 52,
    height: 40,
  },
  cardContainer: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: '50px 0',
  },
}));

const StartButton = styled(Button)({
  position: 'relative',
  top: 15,
  left: 310,
  textTransform: 'none',
  height: '40px',
  width: '52px',
  color: '#fff',
  backgroundColor: '#2595FF',
  borderRadius: '2px',
  fontSize: '14px',
});

interface ICourseProps {
  result?: boolean;
}

const CourseScreen = ({result}: ICourseProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const courses = useSelector((state: RootState) => state[COURSE].courses);
  const user = useSelector((state: RootState) => state[USER].user);
  const role = useSelector((state: RootState) => state[USER].user?.role.role);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (user) {
      dispatch(load(user.id));
    }
  }, [dispatch, user]);

  const handleClick = useCallback(
    (course: CourseType) => {
      dispatch(saveCurrent(course));
      let dest;
      if (role === UserRoleType.PROF || result) {
        if (result) {
          dest = `/result/${course.id}`;
        } else {
          dest = `/settings`;
        }
      } else if (role === UserRoleType.ADMIN) {
        dest = '/admin-class/problems';
      } else {
        dest = `/class/${course.id}`;
      }
      history.push(dest, {});
    },
    [dispatch, history, role, result],
  );

  const handleCreate = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <div className={classes.container}>
      {role !== UserRoleType.STUDENT && (
        <div>
          <Button variant="contained" color="primary" onClick={handleCreate}>
            생성
          </Button>
        </div>
      )}
      <div className={classes.cardContainer}>
        {_.chain(courses)
          .filter((c) => {
            if (role === UserRoleType.STUDENT) {
              const student = user?.student;
              if (student) {
                const index = _.findIndex(
                  student.courseIds,
                  (cid) => cid === c.id,
                );
                if (index > -1) {
                  const activate = student.activates[index];
                  return activate || !!result;
                }
                return false;
              }
              return false;
            }
            return true;
          })
          .map((course) => {
            return (
              <Card key={`course-${course.id}`} className={classes.item}>
                <CardHeader
                  className={classes.cardHeader}
                  title={course.name}
                  titleTypographyProps={{
                    style: {fontSize: 14, fontWeight: 600},
                  }}
                />
                <CardMedia
                  className={`${classes.mediaImg} ${classes.startImg}`}
                  image={`/images/course-${course.type}.jpg`}
                />
                <CardActions className={classes.cardAction}>
                  <Link
                    key={course.id}
                    onClick={() => handleClick(course)}
                    style={{
                      textDecoration: 'none',
                    }}>
                    <StartButton type="button" size="small">
                      Start
                    </StartButton>
                  </Link>
                </CardActions>
              </Card>
            );
          })
          .value()}
      </div>
      {role !== UserRoleType.STUDENT && (
        <CourseCreateModal open={open} onClose={() => setOpen(false)} />
      )}
    </div>
  );
};

export default CourseScreen;
