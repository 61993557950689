import {makeStyles} from '@material-ui/core';
import React from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import {FormGroup} from '~/types';
import {renderRadioButton} from './common';

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 16,
  },
  buttonStyle: {},
  radioBtnWrap: {
    padding: '0 10px',
  },
}));

interface Props {
  seq: number;
  hideSeq?: boolean;
  readOnly?: boolean;
}

const Quiz = ({
  initialValues,
  hideSeq,
  readOnly,
}: InjectedFormProps<FormGroup, Props> & Props) => {
  const classes = useStyles();
  return (
    <form>
      <div className={classes.radioBtnWrap}>
        <Field
          name="uanswer.content"
          buttonStyle={classes.buttonStyle}
          items={initialValues.forms}
          hideSeq={hideSeq}
          component={renderRadioButton}
          readOnly={readOnly}
        />
      </div>
    </form>
  );
};

const QuizForm = reduxForm<FormGroup, Props>({
  // a unique name for the form
  form: 'Quiz',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(Quiz);

export default QuizForm;
