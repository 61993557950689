import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import api from '~/api';
import {RootState} from '~/app/rootReducers';
import {UserInfo} from '~/types';
import {COURSE} from '../Course/slice';
import {USER} from '../User/slice';
import {create, load, successLoad, update} from './slice';

function* handleLoad() {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const userId = yield select((state: RootState) => state[USER].user?.id);
    const resp = yield call(api.getUserInfo, courseId, userId);
    yield put(successLoad(resp.data[0]));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleCreate({payload}: PayloadAction<UserInfo>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const userId = yield select((state: RootState) => state[USER].user?.id);
    const resp = yield call(api.createUserInfo, {...payload, courseId, userId});
    yield put(successLoad(resp.data[0]));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUpdate({payload}: PayloadAction<UserInfo>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const userId = yield select((state: RootState) => state[USER].user?.id);
    const resp = yield call(api.updateUserInfo, payload.id, {
      ...payload,
      courseId,
      userId,
    });
    yield put(successLoad(resp.data[0]));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchUserInfo() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(create, handleCreate);
  yield takeLatest(update, handleUpdate);
}
