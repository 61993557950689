import {makeStyles, Snackbar, Theme} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import withTemplate from '~/hoc/withTemplate';
import {Route, useRouteMatch} from 'react-router-dom';
import CourseScreen from '~/screens/CourseScreen';
import StudentResultScreen from '~/screens/StudentResultScreen';
import GradingResultScreen from '~/screens/GradingResultScreen';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {closeToast, TOAST} from '~/features/Toast/slice';
import {Alert} from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    overflow: 'auto',
  },
  top: {
    padding: '12px 0 12px 24px',
    color: 'rgba(73, 80, 85, 1)',
    borderBottom: '1px solid #DBDEDF',
    cursor: 'default',
  },
  header: {
    width: '90%',
    marginLeft: 'auto',
    color: 'rgba(73, 80, 85, 1)',
    borderBottom: '1px solid #DBDEDF',
    cursor: 'default',
  },
  headerChoice: {
    display: 'inline-block',
    padding: '13px 36px 11px 24px',
  },
  headerClass: {
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 600,
  },
  headerClassA: {
    margin: '0 20px 0 0 ',
    padding: '13px 10px 15px 10px',
    color: 'rgba(73, 80, 85, 1)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: ' rgba(37, 149, 255, 1)',
      borderBottom: '3px solid #2595FF',
      borderRadius: '2px 2px 0px 0px',
    },
  },
  active: {
    color: ' rgba(37, 149, 255, 1)',
    borderBottom: '3px solid #2595FF',
    borderRadius: '2px 2px 0px 0px',
    textDecoration: 'none',
  },
}));

const ResultRoutes = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const classes = useStyles();

  const {message} = useSelector((state: RootState) => state[TOAST]);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [message]);

  const handleClose = useCallback(() => {
    setOpen(false);
    dispatch(closeToast());
  }, []);

  return (
    <div className={classes.root}>
      <Route
        exact
        path={`${match.path}`}
        render={() => <CourseScreen result />}
      />
      <Route exact path={`${match.path}/:id`} component={StudentResultScreen} />
      <Route
        exact
        path={`${match.path}/:id/grading`}
        component={GradingResultScreen}
      />

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withTemplate(ResultRoutes);
