import {
  AppBar,
  Box,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// using ES6 modules
// import {Document} from 'react-pdf/dist/esm/entry.webpack';
import {Document, Page, pdfjs} from 'react-pdf';

import {RootState} from '~/app/rootReducers';
import {CHART, load} from '~/features/Chart/slice';
import {Session} from '~/types';

import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyle = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.2)',
  },
  tabPanel: {
    height: '70vh',
    overflow: 'auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

interface Props {
  scenarioId: number;
  session: Session;
}

const ChartScreen = ({scenarioId, session}: Props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const dispatch = useDispatch();
  const classes = useStyle();
  const charts = useSelector((state: RootState) =>
    _.sortBy(state[CHART].charts, ['seq']),
  );

  const [value, setValue] = React.useState(0);
  const [numPages, setNumPages] = useState<{[key: number]: number}>({});
  const [scale, setScale] = useState(1.5);

  useEffect(() => {
    dispatch(load(scenarioId));
  }, [dispatch, scenarioId]);

  useEffect(() => {
    // setNumPages((p) => {
    //   for (let chart of charts) {
    //     p = {...p, [chart.id]: 0};
    //   }
    //   return p;
    // });
  }, [charts]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const onDocumentLoadSuccess = (id: number, nPage: number) => {
    setNumPages((p) => {
      return {...p, [id]: nPage};
    });
  };

  const handleZoomIn = () => {
    setScale((s) => s + 0.2);
  };

  const handleZoomOut = () => {
    setScale((s) => s - 0.2);
  };

  console.log(numPages);

  return (
    <div className={classes.container}>
      {session.sessionDescriptions.length > 0 && (
        <>
          <Typography>{session.sessionDescriptions[0].description}</Typography>
          <br />
        </>
      )}
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="simple tabs example">
          {_.map(charts, (chart, index) => (
            <Tab
              key={`chart-tab-${chart.id}`}
              label={chart.name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {_.map(charts, (chart, index) => (
        <TabPanel key={`tab-panel-${chart.id}`} value={value} index={index}>
          <IconButton
            aria-label="zoom-in"
            className={classes.margin}
            onClick={() => handleZoomIn()}>
            <ZoomInIcon />
          </IconButton>

          <IconButton
            aria-label="zoom-out"
            className={classes.margin}
            onClick={() => handleZoomOut()}>
            <ZoomOutIcon />
          </IconButton>
          <div className={classes.tabPanel}>
            <Document
              file={chart.url}
              onLoadSuccess={({numPages: nPage}: any) =>
                onDocumentLoadSuccess(chart.id, nPage)
              }>
              {Array.from(new Array(numPages[chart.id]), (el, indx) => (
                <Page
                  key={`page_${index}_${indx + 1}`}
                  scale={scale}
                  pageNumber={indx + 1}
                />
              ))}
            </Document>
          </div>
        </TabPanel>
      ))}
    </div>
  );
};

export default ChartScreen;
