import React, {useCallback, useEffect, useState} from 'react';
import {Route, useHistory, useLocation, useRouteMatch} from 'react-router-dom';

import {makeStyles, Theme} from '@material-ui/core/styles';
import ProblemScreen from '~/screens/ProblemScreen';
import {Typography, IconButton, Link} from '@material-ui/core';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import * as Scenario from '~/features/Scenario/slice';
import {SCENARIO} from '~/features/Scenario/slice';
import * as Session from '~/features/Session/slice';
import {SESSION} from '~/features/Session/slice';
import {
  Scenario as ScenarioT,
  Session as SessionT,
  SessionType,
  ProblemState,
} from '~/types';

import AddBoxIcon from '@material-ui/icons/AddBox';
import ScenarioCreateModal from '~/modals/ScenarioCreateModal';
import {COURSE} from '~/features/Course/slice';
import OptionScreen from '~/screens/OptionScreen';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    width: '90%',
    marginLeft: 'auto',
    color: 'rgba(73, 80, 85, 1)',
    borderBottom: '1px solid #DBDEDF',
    cursor: 'default',
  },
  headerChoice: {
    display: 'inline-block',
    padding: '13px 36px 11px 24px',
  },
  headerClass: {
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 600,
  },
  headerClassA: {
    margin: '0 20px 0 0 ',
    padding: '13px 10px 15px 10px',
    color: 'rgba(73, 80, 85, 1)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: ' rgba(37, 149, 255, 1)',
      borderBottom: '3px solid #2595FF',
      borderRadius: '2px 2px 0px 0px',
    },
  },
  active: {
    color: ' rgba(37, 149, 255, 1)',
    borderBottom: '3px solid #2595FF',
    borderRadius: '2px 2px 0px 0px',
    textDecoration: 'none',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menubar: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '76%',
    cursor: 'pointer',
  },
  menuSession: {
    color: 'rgba(73, 80, 85, 1)',
    padding: '12px 2px 6px 2px',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'none',
      color: ' rgba(37, 149, 255, 1)',
      borderBottom: '3px solid #2595FF',
      borderRadius: '2px 2px 0px 0px',
    },
  },
}));

const EX_FIELDS = [SessionType.INTRO, SessionType.SIMULATION];

const ProblemRoutes = () => {
  const match = useRouteMatch();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<ProblemState>();
  const {scenario, session} = location.state;

  const course = useSelector((state: RootState) => state[COURSE].current);
  const scenarios = useSelector(
    (state: RootState) => state[SCENARIO].scenarios,
  );
  const sessions = useSelector((state: RootState) => state[SESSION].sessions);

  const [sOpen, setSOpen] = useState<boolean>(false);

  useEffect(() => {
    if (course) {
      dispatch(Scenario.load(course.id));
    }
  }, [dispatch, course]);

  useEffect(() => {
    if (scenario) {
      dispatch(Session.load(scenario.id));
    }
  }, [dispatch, scenario]);

  const updateSessions = useCallback(
    (scenario: ScenarioT) => {
      history.replace({pathname: '/admin-class/problems', state: {scenario}});
    },
    [history],
  );

  const handleSessions = useCallback(
    (session: SessionT) => {
      history.replace({
        pathname: '/admin-class/problems',
        state: {...history.location.state, session, formGroup: undefined},
      });
    },
    [history],
  );

  const renderHeader = useCallback(() => {
    return (
      <div className={classes.header}>
        <Typography variant="h6" className={classes.headerChoice}>
          시나리오 선택
        </Typography>

        <IconButton aria-label="delete" onClick={() => setSOpen(true)}>
          <AddBoxIcon />
        </IconButton>
        <Typography variant="body2" className={classes.headerClass}>
          {_.map(scenarios, (scen) => {
            return (
              <Link
                className={`${classes.headerClassA} ${
                  scenario?.id === scen.id ? classes.active : ''
                }`}
                key={`scenario-${scen.id}`}
                onClick={() => updateSessions(scen)}>
                {scen.name}
              </Link>
            );
          })}
        </Typography>

        <div className={classes.container}>
          <div className={classes.menubar}>
            {_.filter(
              sessions,
              (sess) => !_.includes(EX_FIELDS, sess.type),
            ).map((sess) => (
              <Link
                className={`${classes.menuSession} ${
                  session?.id === sess.id ? classes.active : ''
                }`}
                key={`link-${sess.id}`}
                onClick={() => handleSessions(sess)}>
                {sess.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    );
  }, [scenarios, sessions, session, scenario, updateSessions, handleSessions]);

  return (
    <div>
      <div>{renderHeader()}</div>
      <div>
        <Route exact path={`${match.path}`} component={ProblemScreen} />
        <Route exact path={`${match.path}/options`} component={OptionScreen} />
      </div>

      <ScenarioCreateModal open={sOpen} onClose={() => setSOpen(false)} />
    </div>
  );
};

export default ProblemRoutes;
