import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FormGroup, ScenarioType} from '~/types';

export interface CreateFormGroup {
  scenarioId: number;
  scenarioType: ScenarioType;
  sessionId: number;
  formGroup: FormGroup;
}

interface State {
  loading: boolean;
  formGroups: FormGroup[];
}

let initialState: State = {
  loading: false,
  formGroups: [],
};

const slice = createSlice({
  name: 'candidate-form-groups',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<ScenarioType>) => {
      state.loading = true;
    },
    create: (state, _action: PayloadAction<CreateFormGroup>) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<FormGroup[]>) => {
      state.loading = false;
      state.formGroups = payload;
    },
    update: (state, _action: PayloadAction<Partial<FormGroup>>) => {
      state.loading = false;
    },
  },
});

export const {load, create, update, successLoad} = slice.actions;
export const CANDIDATEFG = slice.name;
export default slice.reducer;
