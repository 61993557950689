import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FormCategory, Session} from '~/types';

interface State {
  loading: boolean;
  formCategories: FormCategory[];
}

let initialState: State = {
  loading: false,
  formCategories: [],
};

const slice = createSlice({
  name: 'form-categories',
  initialState,
  reducers: {
    load: (
      state,
      _action: PayloadAction<{scenarioId: number; session: Session}>,
    ) => {
      state.loading = true;
    },
    successLoad: (state, action: PayloadAction<FormCategory[]>) => {
      state.loading = false;
      state.formCategories = action.payload;
    },
    attach: (
      state,
      _action: PayloadAction<{
        formCategoryId: number;
        scenarioId: number;
        session: Session;
      }>,
    ) => {
      state.loading = true;
    },
    detach: (
      state,
      _action: PayloadAction<{
        formCategoryId: number;
        scenarioId: number;
        session: Session;
      }>,
    ) => {
      state.loading = true;
    },
    attachFormGroup: (
      state,
      _action: PayloadAction<{
        formCategoryId: number;
        formGroupIds: number[];
        scenarioId: number;
        session: Session;
      }>,
    ) => {
      state.loading = true;
    },
    detachFormGroup: (
      state,
      _action: PayloadAction<{
        formCategoryId: number;
        formGroupIds: number[];
        scenarioId: number;
        session: Session;
      }>,
    ) => {
      state.loading = true;
    },
  },
});

export const {
  load,
  attach,
  detach,
  attachFormGroup,
  detachFormGroup,
  successLoad,
} = slice.actions;
export const FORMCATEGORIES = slice.name;
export default slice.reducer;
