import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  styled,
  makeStyles,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import {deleteFile, uploadFile} from '~/features/Session/slice';
import {Session, SessionFile, SessionType} from '~/types';

interface Props {
  sessions: Session[];
  scenarioId: number;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '85%',
  },
  tableWrap: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
    border: 'none',
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headWrap: {
    width: '76.7%',
  },
  headTr: {
    display: 'flex',
    width: '100%',
  },
  headTd: {
    fontWeight: 600,
    '&:first-child': {
      width: '20%',
    },
    '&:nth-child(2)': {
      width: '20%',
    },
    '&:nth-child(3)': {
      width: '40%',
    },
    '&:last-child': {
      width: '20%',
    },
  },
  bodyWrap: {
    width: '76.7%',
  },
  bodyTr: {
    display: 'flex',
    width: '100%',
  },
  bodyTd: {
    '&:first-child': {
      width: '20%',
    },
    '&:nth-child(2)': {
      width: '20%',
    },
    '&:nth-child(3)': {
      width: '40%',
    },
    '&:last-child': {
      width: '20%',
    },
  },
}));

const StyledLink = styled(Link)({
  cursor: 'pointer',
});

const EX_FIELDS = [SessionType.SIMULATION, SessionType.ADM_PLAN_RESULT];

const UploadScreen = ({sessions, scenarioId}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [file, setFile] = useState<File>();

  const handleUpload = useCallback(
    (sessionId: number) => {
      if (file) {
        let formData = new FormData();
        formData.append('file', file);
        dispatch(uploadFile({scenarioId, sessionId, formData}));
      }
    },
    [dispatch, scenarioId, file],
  );

  const handleDelete = (file: SessionFile) => {
    dispatch(deleteFile({scenarioId, file}));
  };

  const onChange = (e: any) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  return (
    <div className={classes.root}>
      <TableContainer component={Paper} className={classes.tableWrap}>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead className={classes.headWrap}>
            <TableRow className={classes.headTr}>
              <TableCell className={classes.headTd}>학습과정</TableCell>
              <TableCell className={classes.headTd} align="left">
                자료명
              </TableCell>
              <TableCell className={classes.headTd} align="left">
                자료업로드
              </TableCell>
              <TableCell className={classes.headTd} align="left">
                자료 삭제
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.bodyWrap}>
            {_.filter(
              sessions,
              (sess) => !_.includes(EX_FIELDS, sess.type),
            ).map((row) => (
              <TableRow key={row.name} className={classes.bodyTr}>
                <TableCell
                  className={classes.bodyTd}
                  component="th"
                  scope="row">
                  {row.name}
                </TableCell>
                <TableCell className={classes.bodyTd} align="left">
                  {row.sessionFile?.name}
                </TableCell>
                <TableCell className={classes.bodyTd} align="left">
                  <input type="file" onChange={onChange} />
                  <StyledLink
                    onClick={() => handleUpload(row.id)}
                    underline="none"
                    color="inherit">
                    자료올리기
                  </StyledLink>
                </TableCell>
                <TableCell className={classes.bodyTd} align="left">
                  <StyledLink
                    onClick={() => handleDelete(row.sessionFile)}
                    underline="none"
                    color="inherit">
                    업로드 자료 삭제
                  </StyledLink>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UploadScreen;
