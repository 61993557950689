import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CreateScenario, Scenario} from '~/types';

interface State {
  loading: boolean;
  scenarios: Scenario[];
}

let initialState: State = {
  loading: false,
  scenarios: [],
};

const slice = createSlice({
  name: 'scenario',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<Scenario[]>) => {
      state.loading = false;
      state.scenarios = payload;
    },
    create: (state, _action: PayloadAction<CreateScenario>) => {
      state.loading = false;
    },
  },
});

export const {load, create, successLoad} = slice.actions;
export const SCENARIO = slice.name;
export default slice.reducer;
