import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LoginType, LoginResponse, JwtToken} from '~/types';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';

interface State {
  loading: boolean;
  authorized: boolean;
  token: string | undefined;
  error: string | undefined;
}

let initialState: State = {
  loading: false,
  authorized: false,
  token: undefined,
  error: undefined,
};

const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login: (state, _action: PayloadAction<LoginType>) => {
      state.loading = true;
      state.error = undefined;
    },
    logout: (state) => {
      state.authorized = false;
      state.token = undefined;
      state.error = undefined;
    },
    successLogin: (state, action: PayloadAction<LoginResponse>) => {
      state.loading = false;
      state.authorized = true;
      state.token = action.payload.token;
      state.error = action.payload.error;
    },
    failLogin: (state, action: PayloadAction<LoginResponse>) => {
      state.loading = false;
      state.authorized = false;
      state.token = undefined;
      state.error = action.payload.error;
      window.alert('가입하지 않은 이메일이거나, 잘못된 비밀번호입니다.');
    },
    validToken: (state) => {
      if (state.token) {
        const token = jwtDecode<JwtToken>(state.token);
        const exp = moment.unix(token.exp);
        if (exp > moment()) {
          state.authorized = true;
        }
      }
    },
  },
});

export const {
  login,
  logout,
  successLogin,
  failLogin,
  validToken,
} = slice.actions;
export const LOGIN = slice.name;
export default slice.reducer;
