import React, {useEffect} from 'react';
import {Modal, Paper, Typography, makeStyles} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {useCallback} from 'react';
import {SignupType} from '~/types';
import {MODAL, closeSignupModal} from '~/features/Modal/slice';
import SignupForm from '~/forms/SignupForm';
import * as schoolReducer from '~/features/School/slice';
import {create} from '~/features/User/slice';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    flexDirection: 'column',
    alignItems: 'center',
    width: 331,
    height: 'auto',
    left: '50%',
    top: '50%',
    transform: 'Translate(-50%,-50%)',
    borderRadius: 2,
  },
  divTypo: {
    width: 331,
    height: 44,
    boxShadow: 'inset 0px -1px 0px #DBDEDF',
    padding: '6px 161px 6px 24px',
  },
  typo: {
    color: '#495055',
    display: 'flex',
  },
}));

const SignupModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector((state: RootState) => state[MODAL].signupModal);

  useEffect(() => {
    if (open) {
      dispatch(schoolReducer.load());
    }
  }, [dispatch, open]);

  const handleClose = () => {
    dispatch(closeSignupModal());
  };

  const handleSubmit = useCallback(
    (data: SignupType) => {
      dispatch(
        create({
          username: data.username,
          name: data.name,
          studentId: data.studentId,
          schoolId: parseInt(data.schoolId),
          password: data.password,
        }),
      );
    },
    [dispatch],
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.divTypo}>
          <Typography className={classes.typo} variant="h6">
            Create account
          </Typography>
        </div>
        <SignupForm onSubmit={handleSubmit} />
      </Paper>
    </Modal>
  );
};

export default SignupModal;
