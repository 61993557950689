import {InputAdornment, makeStyles, TextField} from '@material-ui/core';
import React from 'react';
import {WrappedFieldProps} from 'redux-form';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

interface FieldProps {
  label: string;
}

const useStyles = makeStyles((theme) => ({
  fieldStyle: {
    borderRadius: 2,
    fontSize: 14,
  },
}));

const PersonIconField = ({
  label,
  input,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  const classes = useStyles();

  return (
    <TextField
      label={label}
      placeholder={label}
      {...input}
      {...custom}
      variant="outlined"
      margin="dense"
      required
      fullWidth
      type="string"
      size="small"
      InputProps={{
        className: classes.fieldStyle,
        startAdornment: (
          <InputAdornment position="start">
            <PermIdentityIcon fontSize="small" color="disabled" />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PersonIconField;
