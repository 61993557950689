import {configureStore} from '@reduxjs/toolkit';
import {all} from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import {persistReducer, persistStore, createTransform} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import {LOGIN} from '~/features/Login/slice';
import rootReducer, {RootState} from './rootReducers';
import {watchLogin} from '~/features/Login/saga';
import {watchUser} from '~/features/User/saga';
import {watchCourse} from '~/features/Course/saga';
import {watchScenario} from '~/features/Scenario/saga';
import {watchSession} from '~/features/Session/saga';
import {watchSchool} from '~/features/School/saga';
import {watchProblemOutcome} from '~/features/ProblemOutcome/saga';
import {watchFormGroup} from '~/features/FormGroup/saga';
import {COURSE} from '~/features/Course/slice';
import {watchFormCategories} from '~/features/FormCategory/saga';
import {watchStudent} from '~/features/Student/saga';
import {watchCandidateFG} from '~/features/CandidateFG/saga';
import {watchCandidateForm} from '~/features/CandidateForm/saga';
import {watchForms} from '~/features/Forms/saga';
import {watchFormBasis} from '~/features/FormBasis/saga';
import {watchCandidateFC} from '~/features/CandidateFC/saga';
import {watchChart} from '~/features/Chart/saga';
import {watchBoard} from '~/features/Board/saga';
import {watchPost} from '~/features/Post/saga';
import {watchUserInfo} from '~/features/UserInfo/saga';
import {watchSessionTime} from '~/features/SessionTime/saga';
import {watchAnswers} from '~/features/Answers/saga';
import {watchUserScore} from '~/features/UserScore/saga';

const persistConfig = {
  key: 'root',
  whitelist: [LOGIN, COURSE],
  storage,
  transforms: [
    createTransform((inboundState: any, key) => {
      if (key === COURSE) {
        return {current: inboundState.current};
      } else if (key === LOGIN) {
        return {...inboundState, authorized: false, error: undefined};
      }
      return {...inboundState, authorized: false};
    }),
  ],
};

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

function* rootSaga() {
  yield all([
    watchLogin(),
    watchUser(),
    watchCourse(),
    watchScenario(),
    watchSession(),
    watchSchool(),
    watchProblemOutcome(),
    watchFormGroup(),
    watchFormCategories(),
    watchStudent(),
    watchCandidateFG(),
    watchCandidateForm(),
    watchForms(),
    watchFormBasis(),
    watchCandidateFC(),
    watchChart(),
    watchSessionTime(),
    watchAnswers(),
    watchBoard(),
    watchPost(),
    watchUserInfo(),
    watchUserScore(),
  ]);
}

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware({});

  const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [sagaMiddleware],
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);
  return {store, persistor};
};

export type AppDipatch = ReturnType<typeof createStore>;
export default createStore;
