import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React, {useState} from 'react';
import {WrappedFieldProps} from 'redux-form';

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

interface FieldProps {
  label: string;
}

const useStyles = makeStyles((theme) => ({
  idStyle: {
    borderRadius: 2,
    fontSize: 14,
  },
  idIcon: {
    fontSize: 15,
    color: '#a7adb2',
  },
  eyeIcon: {
    width: 17,
  },
}));

const PasswordTextField = ({
  label,
  input,
  meta: {touched, invalid, error},
  ...custom
}: WrappedFieldProps & FieldProps) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  return (
    <TextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
      variant="outlined"
      margin="dense"
      required
      fullWidth
      type={showPassword ? 'text' : 'password'}
      size="small"
      autoComplete="current-password"
      InputProps={{
        className: classes.idStyle,
        startAdornment: (
          <InputAdornment position="start">
            <LockOutlinedIcon fontSize="small" color="disabled" />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              style={{padding: '11px 0px'}}>
              {showPassword ? (
                <VisibilityOutlinedIcon
                  color="disabled"
                  className={classes.eyeIcon}
                />
              ) : (
                <VisibilityOffOutlinedIcon
                  color="disabled"
                  className={classes.eyeIcon}
                />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextField;
