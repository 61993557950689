import {Button, Grid, makeStyles, Typography} from '@material-ui/core';
import React from 'react';
import {Link, useRouteMatch} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  grid: {
    width: 800,
    height: 696,
  },

  button: {
    width: 375,
    height: 333,
    border: 'none',
    borderRadius: 0,
    backgroundColor: '#ECECEC',
    '&:hover': {
      backgroundColor: '#CFE8FF',
      width: 375,
      height: 333,
    },
  },
}));

const SettingScreen = () => {
  const classes = useStyles();
  const match = useRouteMatch();

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container spacing={3}>
        <Grid item xs={6}>
          <Button
            className={classes.button}
            variant="outlined"
            component={Link}
            to={`${match.path}/upload`}>
            <Typography variant="h6">자료업로드</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.button}
            variant="outlined"
            component={Link}
            to={`${match.path}/study`}>
            <Typography variant="h6">학습설정</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.button}
            variant="outlined"
            component={Link}
            to={`${match.path}/student`}>
            <Typography variant="h6">학생설정</Typography>
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.button}
            variant="outlined"
            component={Link}
            to={`${match.path}/progress`}>
            <Typography variant="h6">진도확인</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SettingScreen;
