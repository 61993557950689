import React from 'react';
import {
  Modal,
  Paper,
  Typography,
  Grid,
  makeStyles,
  Link,
  styled,
  Button,
} from '@material-ui/core';
import LoginForm from '~/forms/LoginForm';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {login} from '~/features/Login/slice';
import {useCallback} from 'react';
import {LoginType} from '~/types';
import {
  MODAL,
  closeLoginModal,
  openPasswordModal,
  openSignupModal,
} from '~/features/Modal/slice';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {submit} from 'redux-form';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'Translate(-100px,-150px)',
    width: 331,
    height: 315,
  },

  title: {
    height: 44,
    padding: '6px 24px',
    borderBottom: '1px solid #DBDEDF',
  },

  gridWrap: {
    padding: '0px 24px',
  },

  checkbox: {
    fontSize: 14,
  },

  remember: {
    marginTop: -10,
  },

  buttonWrap: {
    margin: '16px 0px 24px 160px',
  },
}));

const StyledButton = styled(Button)({
  textTransform: 'none',
  margin: '0px 0px 0px 8px ',
});

const LoginModal = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const open = useSelector((state: RootState) => state[MODAL].loginModal);

  const handleClose = () => {
    dispatch(closeLoginModal());
  };

  const openPassword = useCallback(() => {
    dispatch(closeLoginModal());
    dispatch(openPasswordModal());
  }, [dispatch]);

  const openSignup = () => {
    dispatch(closeLoginModal());
    dispatch(openSignupModal());
  };

  const handleSubmit = useCallback(
    (data: LoginType) => {
      dispatch(login(data));
    },
    [dispatch],
  );

  function handleEnterkey(event: React.KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      dispatch(submit('login'));
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onKeyDown={handleEnterkey}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper elevation={0} className={classes.paper}>
        <Typography component="h1" variant="h6" className={classes.title}>
          Log in
        </Typography>
        <LoginForm onSubmit={handleSubmit} />

        <Grid container className={classes.gridWrap}>
          <Grid item xs className={classes.remember}>
            <FormControlLabel
              value="remember"
              control={<Checkbox color="primary" />}
              label="Remember me"
              labelPlacement="end"
              classes={{label: classes.checkbox}}
            />
          </Grid>
          <Grid item xs style={{textAlign: 'right'}}>
            <Link
              href="#"
              variant="caption"
              underline="none"
              onClick={openPassword}>
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Typography variant="caption">No account?</Typography>
            <Link href="#" underline="none" onClick={openSignup}>
              <Typography variant="caption"> Create one!</Typography>
            </Link>
          </Grid>
        </Grid>

        <div className={classes.buttonWrap}>
          <StyledButton
            onClick={() => dispatch(closeLoginModal())}
            type="submit"
            variant="outlined"
            size="small">
            Cancel
          </StyledButton>
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => dispatch(submit('login'))}
            size="small">
            Submit
          </StyledButton>
        </div>
      </Paper>
    </Modal>
  );
};

export default LoginModal;
