import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {FormCategory} from '~/types';

interface State {
  loading: boolean;
  formCategories: FormCategory[];
}

let initialState: State = {
  loading: false,
  formCategories: [],
};

const slice = createSlice({
  name: 'candidate-form-category',
  initialState,
  reducers: {
    load: (state) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<FormCategory[]>) => {
      state.loading = false;
      state.formCategories = payload;
    },
  },
});

export const {load, successLoad} = slice.actions;
export const CANDIDATEFC = slice.name;
export default slice.reducer;
