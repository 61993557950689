import {PayloadAction} from '@reduxjs/toolkit';
import {takeLatest, call, put} from 'redux-saga/effects';
import api from '~/api';
import {load, successLoad} from './slice';

function* handleLoad({payload: scenarioId}: PayloadAction<number>) {
  try {
    const resp = yield call(api.getPOsWithScenarioId, scenarioId);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchProblemOutcome() {
  yield takeLatest(load, handleLoad);
}
