import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Course, CreateCourse} from '~/types';

interface State {
  loading: boolean;
  courses: Course[];
  courseTmpl: Course[];
  current: Course | undefined;
}

let initialState: State = {
  loading: false,
  courses: [],
  courseTmpl: [],
  current: undefined,
};

const slice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    loadTmpl: (state) => {
      state.loading = true;
    },
    create: (state, _action: PayloadAction<CreateCourse>) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<Course[]>) => {
      state.loading = false;
      state.courses = payload;
    },
    successLoadTmpl: (state, {payload}: PayloadAction<Course[]>) => {
      state.loading = false;
      state.courseTmpl = payload;
    },
    saveCurrent: (state, {payload}: PayloadAction<Course>) => {
      state.current = payload;
    },
  },
});

export const {
  load,
  loadTmpl,
  create,
  saveCurrent,
  successLoad,
  successLoadTmpl,
} = slice.actions;
export const COURSE = slice.name;
export default slice.reducer;
