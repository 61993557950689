import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CreateScore, FormGroup, Score, Session, SessionType} from '~/types';

interface State {
  loading: boolean;
  formGroups: {[key: string]: FormGroup[]};
}

interface LoadPayload {
  type: SessionType;
  formGroups: FormGroup[];
}

export interface UpdateScore {
  score: Score;
  formGroup: FormGroup;
  session: Session;
  scenarioId: number;
}

export interface AttachForm {
  scenarioId: number;
  session: Session;
  formGroupId: number;
  formIds: number[];
}

export interface SaveFormGroupPayload {
  scenarioId: number;
  session: Session;
  formGroup: Partial<FormGroup> & Pick<FormGroup, 'id'>;
}

export interface SaveAnswerPayload {
  scenarioId: number;
  session: Session;
  formGroups: (Partial<FormGroup> & Pick<FormGroup, 'id'>)[];
}

let initialState: State = {
  loading: false,
  formGroups: {},
};

const slice = createSlice({
  name: 'form-groups',
  initialState,
  reducers: {
    load: (
      state,
      _action: PayloadAction<{scenarioId?: number; session?: Session}>,
    ) => {
      state.loading = true;
    },
    successLoad: (
      state,
      {payload: {type, formGroups}}: PayloadAction<LoadPayload>,
    ) => {
      state.loading = false;
      state.formGroups[type] = formGroups;
    },
    update: (state, _action: PayloadAction<SaveFormGroupPayload>) => {
      state.loading = true;
    },
    updateAnswer: (state, _action: PayloadAction<SaveAnswerPayload>) => {
      state.loading = true;
    },
    updateScore: (state, _action: PayloadAction<UpdateScore>) => {
      state.loading = true;
    },
    attachForms: (state, _action: PayloadAction<AttachForm>) => {
      state.loading = true;
    },
    detachForms: (state, _action: PayloadAction<AttachForm>) => {
      state.loading = true;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
    clear: (state) => {
      state.formGroups = {};
    },
  },
});

export const {
  load,
  attachForms,
  detachForms,
  successLoad,
  update,
  updateAnswer,
  updateScore,
  clearLoading,
  clear,
} = slice.actions;
export const FORMGROUPS = slice.name;
export default slice.reducer;
