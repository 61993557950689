import React, {useCallback} from 'react';
import {
  Button,
  createStyles,
  makeStyles,
  Modal,
  Paper,
  styled,
  Typography,
} from '@material-ui/core';
import StudentAddForm from '~/forms/StudentAddForm';
import {Student} from '~/types';
import {Theme} from '@material-ui/core/styles/createMuiTheme';
import {useDispatch} from 'react-redux';
import {submit} from 'redux-form';
import {create} from '~/features/Student/slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      width: 331,
      height: 220,
      flexDirection: 'column',
      margin: 'auto',
    },
    title: {
      fontWeight: 700,
      color: 'rgba(73, 80, 85, 1)',
      padding: '10px 0 10px 30px',
      borderBottom: '1px solid #DBDEDF',
    },
    buttonWrap: {
      width: '90%',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    btncancel: {
      backgroundColor: '#F9FAFA',
    },
  }),
);

interface Props {
  open: boolean;
  onClose: () => void;
}

const StyledButton = styled(Button)({
  textTransform: 'none',
  margin: '0px 0px 0px 8px ',
  borderRadius: 2,
  boxShadow: 'none',
  fontWeight: 600,
});

const StudentAddModal = ({open, onClose}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (data: Student) => {
      dispatch(create(data));
    },
    [dispatch],
  );

  const submitForm = () => {
    dispatch(submit('student'));
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}>
      <Paper className={classes.container}>
        <Typography variant="h6" className={classes.title}>
          이름/학번 입력
        </Typography>

        <StudentAddForm onSubmit={handleSubmit} />
        <div className={classes.buttonWrap}>
          <StyledButton
            type="submit"
            variant="contained"
            color="primary"
            onClick={submitForm}
            size="small">
            Add
          </StyledButton>
          <StyledButton
            className={classes.btncancel}
            onClick={onClose}
            type="submit"
            variant="outlined"
            size="small">
            Cancel
          </StyledButton>
        </div>
      </Paper>
    </Modal>
  );
};

export default StudentAddModal;
