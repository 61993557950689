import axios from 'axios';
import _ from 'lodash';
import {AttachForm} from '~/features/FormGroup/slice';
import {
  Answer,
  AttachFormGroup,
  Course,
  CreateScenario,
  CreateSessionTime,
  CreateUser,
  Form,
  FormGroup,
  IPasswordReset,
  LoginType,
  PasswordFindType,
  Post,
  ScenarioType,
  Score,
  SessionFile,
  Student,
  UserInfo,
  UserScore,
} from '~/types';

const login = (param: LoginType) => {
  return axios.post('/users/login', param);
};

const getFormCategories = () => {
  return axios.get(`/form-categories`);
};

const getUser = () => {
  const filter = {
    include: [
      {relation: 'student'},
      {relation: 'school'},
      {relation: 'role'},
      {relation: 'courses'},
    ],
  };
  return axios.get(`/users/me?filter=${JSON.stringify(filter)}`);
};

const findPassword = (payload: PasswordFindType) => {
  return axios.post('/users/find-password', payload);
};

const resetPassword = (payload: IPasswordReset) => {
  return axios.post('/users/reset-password', _.omit(payload, 'verifyPassword'));
};

const getUserInfo = (courseId: number, userId: number) => {
  const filter = {
    where: {
      courseId,
      userId,
    },
  };
  return axios.get(`/user-infos?filter=${JSON.stringify(filter)}`);
};

const createUserInfo = (userInfo: UserInfo) => {
  return axios.post(`/user-infos`, userInfo);
};

const updateUserInfo = (userInfoId: number, userInfo: UserInfo) => {
  return axios.put(`/user-infos/${userInfoId}`, userInfo);
};

const getBoards = () => {
  return axios.get('/boards/me');
};

const getPostsWithBoardId = (boardId: number, page: number) => {
  const filter = {
    order: ['createdAt DESC'],
    limit: 5,
    skip: page * 5,
  };
  return axios.get(`/boards/${boardId}/posts?filter=${JSON.stringify(filter)}`);
};

const createPostWithBoardId = (boardId: number, post: Post) => {
  return axios.post(`/boards/${boardId}/posts`, post);
};

const updatePost = (postId: number, post: Post) => {
  return axios.put(`/posts/${postId}`, post);
};

const deletePost = (postId: number) => {
  return axios.delete(`/posts/${postId}`);
};

const getCourses = () => {
  const filter = {
    where: {
      schoolId: {
        eq: null,
      },
    },
  };
  return axios.get(`/courses?filter=${JSON.stringify(filter)}`);
};

const createSessionTime = (payload: CreateSessionTime) => {
  return axios.post('/session-times', payload);
};

const updateSessionTime = (sessionTimeId: number) => {
  return axios.patch(`/session-times/${sessionTimeId}/now`);
};

const getSessionTimeStat = (courseId: number, scenarioId: number) => {
  const filter = {
    where: {
      courseId: {
        eq: courseId,
      },
      scenarioId: {
        eq: scenarioId,
      },
    },
  };
  return axios.get(`/session-times/stat?filter=${JSON.stringify(filter)}`);
};

const createCourse = (userId: number, payload: Omit<Course, 'id'>) => {
  return axios.post(`/users/${userId}/courses`, payload);
};

const createCourseWithTmplId = (
  userId: number,
  tmplId: number,
  payload: Omit<Course, 'id' | 'type'>,
) => {
  return axios.post(`/users/${userId}/courses/${tmplId}`, payload);
};

const getCoursesWithSchoolId = (schoolId: number) => {
  return axios.get(`/schools/${schoolId}/courses`);
};

const getScenariosWithCourselId = (courseId: number) => {
  return axios.get(`/courses/${courseId}/scenarios`);
};

const getCoursesWithUserId = (userId: number) => {
  return axios.get(`/users/${userId}/courses`);
};

const getChartsWithScenarioId = (scenarioId: number) => {
  return axios.get(`/scenarios/${scenarioId}/charts`);
};

const getSessionsWithScenarioId = (scenarioId: number, courseId: number) => {
  const filter = {
    include: [
      {relation: 'sessionFile', scope: {where: {courseId, scenarioId}}},
    ],
  };
  return axios.get(
    `/scenarios/${scenarioId}/sessions?filter=${JSON.stringify(filter)}`,
  );
};

const getPOsWithScenarioId = (scenarioId: number) => {
  const filter = {
    include: [{relation: 'sessions'}],
  };
  return axios.get(
    `/scenarios/${scenarioId}/problem-outcomes?filter=${JSON.stringify(
      filter,
    )}`,
  );
};

const getFormGroup = (scenarioType: ScenarioType) => {
  const filter = {where: {scenarioType}};
  return axios.get(`/form-groups?filter=${JSON.stringify(filter)}`);
};

const getFormCategoriesWithScenarioSessionId = (
  scenarioId: number,
  sessionId: number,
  relationWhere?: Partial<Answer>,
) => {
  const filter = {
    include: [
      {
        relation: 'formGroups',
        scope: {
          include: [
            // {
            //   relation: 'answers',
            //   scope: {where: relationWhere, order: 'createdAt DESC'},
            // },
            {relation: 'forms'},
            {relation: 'scores'},
          ],
        },
      },
    ],
  };
  return axios.get(
    `/scenarios/${scenarioId}/form-categories?filter=${JSON.stringify(
      filter,
    )}&sessionId=${sessionId}`,
  );
};

const getFormGroupWithFormCategoryId = (formCatId: number) => {
  return axios.get(`/form-categories/${formCatId}/form-groups`);
};

const getFormGroupsWithScenarioSessionId = (
  scenarioId: number,
  sessionId: number,
  courseId: number,
) => {
  return axios.get(
    `/scenarios/${scenarioId}/form-groups?sessionId=${sessionId}&courseId=${courseId}`,
  );
};

const getFormsWithFromGroupId = (
  formGroupId: number,
  relationWhere: Partial<Answer>,
) => {
  const filter = {
    include: [
      {relation: 'formBasis'},
      {
        relation: 'answers',
        scope: {where: relationWhere, order: 'createdAt DESC'},
      },
    ],
  };
  return axios.get(
    `/form-groups/${formGroupId}/forms?filter=${JSON.stringify(filter)}`,
  );
};

const getScoresWithFormGroupId = (
  formGroupId: number,
  courseId: number,
  scenarioId: number,
  formCategoryId: number,
) => {
  const filter = {
    where: {
      courseId,
      scenarioId,
      formCategoryId,
    },
  };
  return axios.get(
    `/form-groups/${formGroupId}/scores?filter=${JSON.stringify(filter)}`,
  );
};

const getFormBasisWithFromId = (formId: number) => {
  return axios.get(`/forms/${formId}/form-bases`);
};

const getSchools = () => {
  const filter = {
    where: {
      isActivated: true,
    },
  };
  return axios.get(`/schools?filter=${JSON.stringify(filter)}`);
};

const createAnswer = (answer: Partial<Answer>) => {
  return axios.post(`/answers`, answer);
};

const updateAnswer = (answer: Partial<Answer>) => {
  return axios.put(
    `/answers/${answer.id}`,
    _.omitBy(answer, (a) => a === null),
  );
};

const uploadSessionFile = (
  sessionId: number,
  courseId: number,
  scenarioId: number,
  formData: FormData,
) => {
  return axios.post(
    `/sessions/${sessionId}/files?courseId=${courseId}&scenarioId=${scenarioId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

const deleteSessionFile = (sessionFileId: number) => {
  return axios.delete(`/session-files/${sessionFileId}`);
};

const uploadFormImage = (
  courseId: number,
  scenarioId: number,
  formId: number,
  formData: FormData,
) => {
  return axios.post(
    `/forms/${formId}/files?courseId=${courseId}&scenarioId=${scenarioId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};

const uploadStudents = (
  schoolId: number,
  courseId: number,
  formData: FormData,
) => {
  return axios.post(
    `/schools/${schoolId}/files?courseId=${courseId}`,
    formData,
  );
};

const checkAnswer = (setId: number) => {
  return axios.get(`/random-form-sets/${setId}/check-answer`);
};

const createScoreWithFromGroupId = (
  formGroupId: number,
  score: Omit<Score, 'id'>,
) => {
  return axios.post(`/form-groups/${formGroupId}/scores`, score);
};

const updateScore = (score: Score) => {
  console.log('updateScore', score);
  return axios.put(
    `/scores/${score.id}`,
    _.pickBy(score, (k) => k !== undefined && k !== null),
  );
};

const getSessionFile = (sessionId: number, courseId: number) => {
  const filter = {
    where: {
      courseId,
    },
  };
  return axios.get<SessionFile[]>(
    `/sessions/${sessionId}/session-files?filter=${JSON.stringify(filter)}`,
  );
};

const createUser = (user: CreateUser) => {
  return axios.post('/signup', user);
};

const getStudents = (schoolId: number, courseId: number) => {
  const filter = {
    include: [{relation: 'user'}],
  };
  return axios.get(
    `/schools/${schoolId}/students?courseId=${courseId}&filter=${JSON.stringify(
      filter,
    )}`,
  );
};

const updateStudent = (studentId: number, student: Student) => {
  return axios.put(
    `/students/${studentId}`,
    _.omitBy(student, (d) => d === null),
  );
};

const createStudent = (
  courseId: number,
  schoolId: number,
  student: Student,
) => {
  return axios.post(
    `/students?courseId=${courseId}&schoolId=${schoolId}`,
    student,
  );
};

const createScenario = (courseId: number, scenario: CreateScenario) => {
  return axios.post(`/courses/${courseId}/scenarios`, scenario);
};

// const createFormGroup = (
//   scenarioId: number,
//   sessionId: number,
//   formGroup: FormGroup,
// ) => {
//   return axios.post(
//     `/scenarios/${scenarioId}/form-groups?sessionId=${sessionId}`,
//     formGroup,
//   );
// };

const createFormGroup = (formGroup: FormGroup) => {
  return axios.post(`/form-groups`, formGroup);
};

const attachFormGroup = (payload: Omit<AttachFormGroup, 'session'>) => {
  return axios.post('/form-groups/attach', payload);
};

const detachFormGroup = (payload: Omit<AttachFormGroup, 'session'>) => {
  return axios.post('/form-groups/detach', payload);
};

const getForms = (scenarioType: ScenarioType) => {
  const filter = {where: {scenarioType}};
  return axios.get(`/forms?filter=${JSON.stringify(filter)}`);
};

const getFormsWithFormGroupId = (formGroupId: number) => {
  return axios.get(`/form-groups/${formGroupId}/forms`);
};

const attachForms = (payload: Omit<AttachForm, 'session' | 'scenarioId'>) => {
  return axios.post('/forms/attach', payload);
};

const detachForms = (payload: Omit<AttachForm, 'session' | 'scenarioId'>) => {
  return axios.post('/forms/detach', payload);
};

const attachFormCategory = (
  formCategoryId: number,
  payload: {scenarioId: number; sessionId: number},
) => {
  return axios.post(`/form-categories/${formCategoryId}/attach`, payload);
};

const detachFormCategory = (
  formCategoryId: number,
  payload: {scenarioId: number; sessionId: number},
) => {
  return axios.post(`/form-categories/${formCategoryId}/detach`, payload);
};

const getFormBasis = () => {
  return axios.get('/form-bases');
};

const createForm = (form: Omit<Form, 'id'>) => {
  return axios.post('/forms', form);
};

const updateFormGroup = (
  formGroupId: number,
  formGroup: Partial<FormGroup>,
) => {
  return axios.patch(`/form-groups/${formGroupId}`, formGroup);
};

const updateForm = (formId: number, form: any) => {
  return axios.patch(`/forms/${formId}`, form);
};

const createFormCategory = (name: string) => {
  return axios.post(`/form-categories`, {name});
};

const attachFormGroupToFC = (
  formCategoryId: number,
  payload: {scenarioId: number; sessionId: number; formGroupIds: number[]},
) => {
  return axios.post(
    `/form-categories/${formCategoryId}/form-groups/attach`,
    payload,
  );
};

const detachFormGroupToFC = (
  formCategoryId: number,
  payload: {scenarioId: number; sessionId: number; formGroupIds: number[]},
) => {
  return axios.post(
    `/form-categories/${formCategoryId}/form-groups/detach`,
    payload,
  );
};

const getSessionClear = (courseId: number, scenarioId: number) => {
  const filter = {
    where: {
      courseId: {
        eq: courseId,
      },
      scenarioId: {
        eq: scenarioId,
      },
    },
  };
  return axios.get(
    `/answers/clear?filter=${JSON.stringify(filter)}&scenarioId=${scenarioId}`,
  );
};

const getAnswers = (
  courseId: number,
  scenarioId: number,
  sessionId: number,
  userId?: number,
) => {
  const filter = {
    where: {
      courseId: {
        eq: courseId,
      },
      scenarioId: {
        eq: scenarioId,
      },
      sessionId: {
        eq: sessionId,
      },
      ...(userId !== undefined
        ? {
            userId: {
              eq: userId,
            },
          }
        : {}),
    },
  };
  return axios.get(`/answers?filter=${JSON.stringify(filter)}`);
};

const getUserScore = (
  courseId: number,
  scenarioId?: number,
  sessionId?: number,
  userId?: number,
) => {
  const filter = {
    where: {
      courseId,
      scenarioId,
      sessionId,
      ...(userId !== undefined ? {userId} : {}),
    },
  };

  return axios.get(`/user-scores?filter=${JSON.stringify(filter)}`);
};

const createUserScore = (payload: UserScore) => {
  return axios.post(`/user-scores`, payload);
};

const updateUserScore = (id: number, payload: UserScore) => {
  return axios.put(`/user-scores/${id}`, payload);
};

export default {
  getUserScore,
  createUserScore,
  updateUserScore,
  login,
  getUser,
  getCourses,
  getStudents,
  updateStudent,
  createUser,
  createSessionTime,
  updateSessionTime,
  getSessionTimeStat,
  createCourseWithTmplId,
  getCoursesWithSchoolId,
  getCoursesWithUserId,
  getSchools,
  getScenariosWithCourselId,
  getSessionsWithScenarioId,
  getPOsWithScenarioId,
  getChartsWithScenarioId,
  getFormGroupsWithScenarioSessionId,
  getFormCategoriesWithScenarioSessionId,
  getFormGroupWithFormCategoryId,
  getFormsWithFromGroupId,
  getFormBasisWithFromId,
  createAnswer,
  createScoreWithFromGroupId,
  updateAnswer,
  getSessionFile,
  uploadSessionFile,
  deleteSessionFile,
  getScoresWithFormGroupId,
  updateScore,
  uploadStudents,
  getFormGroup,
  createCourse,
  createScenario,
  createFormGroup,
  attachFormGroup,
  detachFormGroup,
  getForms,
  getFormsWithFormGroupId,
  attachForms,
  detachForms,
  getFormBasis,
  createForm,
  updateFormGroup,
  updateForm,
  getFormCategories,
  attachFormCategory,
  detachFormCategory,
  attachFormGroupToFC,
  detachFormGroupToFC,
  uploadFormImage,
  checkAnswer,
  getBoards,
  getPostsWithBoardId,
  createPostWithBoardId,
  deletePost,
  updatePost,
  getUserInfo,
  createUserInfo,
  updateUserInfo,
  createStudent,
  findPassword,
  resetPassword,
  getSessionClear,
  getAnswers,
  createFormCategory,
};
