import {Box, makeStyles, Typography} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import AdmPlanForm from '~/forms/AdmPlanForm';
import QuizForm from '~/forms/QuizForm';
import SubjectForm from '~/forms/SubjectForm';
import {FormGroup, FormGroupType, Session, UserRoleType} from '~/types';

const useStyle = makeStyles((theme) => ({
  answerBox: {
    backgroundColor: '#E7F5FC',
    padding: 20,
    marginBottom: 30,
    fontWeight: 600,
    boxShadow: '0 0 0 0.2px #000 inset',
  },
  eduGoal: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  quizForm: {
    marginBottom: 30,
  },
  titleWrap: {
    '&:nth-child(n+2)': {
      marginTop: 40,
    },
  },
}));

interface Props {
  session: Session;
  formGroup: FormGroup;
  handleSubmit?: (formGroup: FormGroup) => void;
  onChange?: () => void;
  // role: UserRoleType;
  showAnswer?: boolean;
  index: number;
}

const Quiz = ({
  session,
  formGroup,
  showAnswer,
  index,
  handleSubmit,
  onChange,
}: Props) => {
  const classes = useStyle();
  return (
    <div>
      <div className={classes.titleWrap}>
        <Typography>
          {index + 1}. {formGroup.name}
        </Typography>
        {formGroup.explain && <div>{formGroup.explain}</div>}
        {formGroup.image && <img src={formGroup.image} />}
      </div>
      <div className={classes.quizForm}>
        {formGroup.type === FormGroupType.SUBJECTIVE && (
          <SubjectForm
            initialValues={formGroup}
            form={`${session.type}-${formGroup.id}`}
            onChange={onChange}
            onSubmit={handleSubmit}
          />
        )}
        {formGroup.type === FormGroupType.OBJECTIVE && (
          <QuizForm
            initialValues={{
              ...formGroup,
            }}
            form={`${session.type}-${formGroup.id}`}
            onChange={onChange}
            onSubmit={handleSubmit}
            seq={index}
            readOnly={showAnswer}
            hideSeq
          />
        )}
        {formGroup.type === FormGroupType.MULTIPLE && (
          <AdmPlanForm
            initialValues={formGroup}
            form={`${session.type}-${formGroup.id}`}
            onChange={onChange}
            onSubmit={handleSubmit}
            showName
          />
        )}
      </div>
      {showAnswer && (
        <div className={classes.answerBox}>
          <Typography color="primary" className={classes.eduGoal}>
            (학습목표 {formGroup.object}) 답:
            {_.findIndex(formGroup.forms, (f) =>
              _.includes(formGroup.answers, f.id),
            ) + 1}
          </Typography>
          <br />
          {formGroup.example}
          <br />
        </div>
      )}
    </div>
  );
};

export default Quiz;
