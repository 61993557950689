import {
  AppBar,
  CircularProgress,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isDirty, stopSubmit} from 'redux-form';
import {RootState} from '~/app/rootReducers';
import {FORMGROUPS} from '~/features/FormGroup/slice';
import AdmPlanForm from '~/forms/AdmPlanForm';
import QuizForm from '~/forms/QuizForm';
import SubjectForm from '~/forms/SubjectForm';
import {FormCategory, FormGroup, FormGroupType, Session} from '~/types';
import {FORMCATEGORIES, load} from '~/features/FormCategory/slice';
import axios from 'axios';
import TabPanel from '~/components/TabPanel';

const useStyle = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  container: {
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
  },
  quizForm: {
    marginBottom: 20,
  },
  tabPanel: {
    overflow: 'auto',
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.2)',
  },
}));

interface Props {
  scenarioId: number;
  session: Session;
}

const MSEScreen = ({scenarioId, session}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyle();
  const formGroups = useSelector(
    (state: RootState) => state[FORMGROUPS].formGroups[session.type],
  );
  const {loading} = useSelector((state: RootState) => state[FORMGROUPS]);
  const [formCategories, setFormCategories] = useState<FormCategory[]>([]);
  const [fcGroups, setFcGroups] = useState<{[key: number]: number[]}>({});
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    const loadFormCategories = async () => {
      const resp = await axios.get(
        `/scenarios/${scenarioId}/form-categories?sessionId=${session.id}`,
      );
      setFormCategories(resp.data);
      const ids = _.map(resp.data, (gc) => gc.id);
      const filter = {
        where: {
          formCategoryId: {
            inq: ids,
          },
        },
      };
      const resp2 = await axios.get(
        `/form-category-form-group-rels?filter=${JSON.stringify(filter)}`,
      );
      const data = _.chain(resp2.data)
        .groupBy((d) => d.formCategoryId)
        .reduce((result, values, key) => {
          return {...result, [key]: _.map(values, (v) => v.formGroupId)};
        }, {})
        .value();

      setFcGroups(data);
    };

    loadFormCategories();
  }, [dispatch, scenarioId, session]);

  // useEffect(() => {
  //   if (!formGroups) {
  //     dispatch(load({scenarioId, session}));
  //   }
  // }, [dispatch, scenarioId, session, formGroups]);

  // const handleSubmit = useCallback(
  //   (formGroup: FormGroup) => {
  //     const formName = `${session.type}-${formGroup.id}`;
  //     if (isDirty(formName)(state)) {
  //       dispatch(updateAnswer({formName, scenarioId, session, formGroup}));
  //     } else {
  //       dispatch(stopSubmit(formName));
  //     }
  //   },
  //   [dispatch, state, scenarioId, session],
  // );
  const handleSubmit = () => {};

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <div className={classes.container}>
      <Typography variant="h6">※ Mental Status Examination</Typography>

      {session.sessionDescriptions.length > 0 && (
        <>
          <Typography>{session.sessionDescriptions[0].description}</Typography>
          <br />
        </>
      )}
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="simple tabs example">
          {_.map(formCategories, (cat, index) => (
            <Tab key={`mse-tab-${cat.id}`} label={cat.name} />
          ))}
        </Tabs>
        {/* {loading && <LinearProgress />} */}
      </AppBar>

      {_.map(fcGroups, (fgList, key) => {
        const fcIndex = _.findIndex(
          formCategories,
          (fc) => fc.id === parseInt(key),
        );
        const fc = formCategories[fcIndex];
        const fgs = _.chain(formGroups)
          .filter((fg) => _.includes(fgList, fg.id))
          .sortBy((fg) => fg.order)
          .value();

        return (
          <TabPanel
            key={`tab-panel-${key}`}
            className={classes.tabPanel}
            value={value}
            index={fcIndex}>
            {_.map(fgs, (formGroup, index) => {
              return (
                <div key={`form-group-${index}`} className={classes.quizForm}>
                  <div>
                    <Typography>{`${formGroup.name}`}</Typography>
                    {formGroup.explain && <div>{formGroup.explain}</div>}
                    {formGroup.image && <img src={formGroup.image} />}
                  </div>
                  {formGroup.type === FormGroupType.OBJECTIVE && (
                    <QuizForm
                      initialValues={{
                        ...formGroup,
                      }}
                      form={`${session.type}-${formGroup.id}`}
                      onSubmit={handleSubmit}
                      seq={index}
                      hideSeq
                    />
                  )}
                  {formGroup.type === FormGroupType.SUBJECTIVE && (
                    <SubjectForm
                      initialValues={formGroup}
                      form={`${session.type}-${formGroup.id}`}
                      onSubmit={handleSubmit}
                    />
                  )}
                  {formGroup.type === FormGroupType.MULTIPLE && (
                    <AdmPlanForm
                      initialValues={formGroup}
                      form={`${session.type}-${formGroup.id}`}
                      onSubmit={handleSubmit}
                      showName
                    />
                  )}
                </div>
              );
            })}
          </TabPanel>
        );
      })}
    </div>
  );
};

export default MSEScreen;
