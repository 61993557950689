import {makeStyles, Typography} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isDirty, stopSubmit} from 'redux-form';
import {RootState} from '~/app/rootReducers';
import {FORMGROUPS, load, updateAnswer} from '~/features/FormGroup/slice';
import SubjectForm from '~/forms/SubjectForm';
import {FormGroup, Session} from '~/types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
  },
}));

interface Props {
  scenarioId: number;
  session: Session;
}

const PsySOAPIEScreen = ({scenarioId, session}: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const formGroups = useSelector(
    (state: RootState) => state[FORMGROUPS].formGroups[session.type],
  );
  const state = useSelector((state: RootState) => state);

  // useEffect(() => {
  //   if (!formGroups) {
  //     dispatch(load({scenarioId, session}));
  //   }
  // }, [dispatch, scenarioId, session, formGroups]);

  // const handleSubmit = useCallback(
  //   (formGroup: FormGroup) => {
  //     const formName = `${session.type}-${formGroup.id}`;
  //     if (isDirty(formName)(state)) {
  //       dispatch(updateAnswer({formName, scenarioId, session, formGroup}));
  //     } else {
  //       dispatch(stopSubmit(formName));
  //     }
  //   },
  //   [dispatch, state, scenarioId, session],
  // );

  const handleSubmit = () => {};

  return (
    <div className={classes.root}>
      <Typography variant="h6">※ SOAPIE 간호기록 단계</Typography>
      {_.map(formGroups, (formGroup, index) => (
        <div key={`subject-form-${formGroup.id}`}>
          <Typography>{`${index + 1}. ${formGroup.name}`}</Typography>
          {formGroup.explain && <div>{formGroup.explain}</div>}
          {formGroup.image && <img src={formGroup.image} />}
          <SubjectForm
            initialValues={formGroup}
            form={`${session.type}-${formGroup.id}`}
            onSubmit={handleSubmit}
          />
        </div>
      ))}
    </div>
  );
};

export default PsySOAPIEScreen;
