import {
  AppBar,
  Button,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {RootState} from '~/app/rootReducers';
import {FORMCATEGORIES, load} from '~/features/FormCategory/slice';
import {Session} from '~/types';
import OutlinedFlagIcon from '@material-ui/icons/OutlinedFlag';
import PsyIntervDetail2 from './PsyIntervDetail2';
import TabPanel from '~/components/TabPanel';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(4)}px ${theme.spacing(8)}px`,
  },
  titleTxt: {
    border: 0,
    height: '1px',
    backgroundColor: ' #DBDEDF',
  },
  menuChoice: {
    width: '95%',
    flexWrap: 'wrap',
    float: 'right',
    marginBottom: 40,
  },
  menuWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: '1px solid rgba(219, 222, 223, 1)',
    '&:last-child': {
      borderBottom: 0,
    },
  },
  menuBtnWrap: {
    flex: '0.5',
    padding: 10,
  },
  menuBtn: {
    width: 280,
    color: '#2595FF',
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#F1F9FF',
      border: '1px solid #2595FF',
    },
    '&:active': {
      border: '1px solid #246DD3',
      color: '#246DD3',
      backgroundColor: '#F1F9FF',
    },
  },
  menuBtn2: {
    width: 280,
    color: '#C3C7C8',
    fontWeight: 600,
    cursor: 'default',
  },
  btnIcon: {
    marginRight: -8,
  },
  menuDesc: {
    flex: 1,
    padding: 10,
    fontWeight: 700,
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: 'solid 1px rgba(0,0,0,0.2)',
  },
  tabPanel: {
    overflow: 'auto',
  },
}));

interface Props {
  scenarioId: number;
  session: Session;
}

const PsyIntervention = ({scenarioId, session}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const formCategories = useSelector(
    (state: RootState) => state[FORMCATEGORIES].formCategories,
  );
  const [value, setValue] = React.useState(0);

  console.log('PsyIntervention', formCategories);
  useEffect(() => {
    dispatch(load({scenarioId, session}));
  }, [dispatch, scenarioId, session]);

  const handleRoute = (id: number) => {
    history.push(`/scenario/${scenarioId}/${session.seq}/${id}`, {session});
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
    },
    [],
  );

  return (
    <div className={classes.container}>
      <Typography variant="h6">※ 치료적 간호중재</Typography>
      {session.sessionDescriptions.length > 0 && (
        <>
          <Typography>{session.sessionDescriptions[0].description}</Typography>
          <br />
        </>
      )}
      <hr className={classes.titleTxt} />
      <AppBar position="static" color="transparent" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="simple tabs example">
          {_.chain(formCategories)
            .filter((f) => !!f.isActivated)
            .map((cat, index) => (
              <Tab key={`chart-tab-${cat.id}`} label={cat.name} />
            ))
            .value()}
        </Tabs>
        {/* {loading && <LinearProgress />} */}
      </AppBar>
      {_.chain(formCategories)
        .filter((f) => !!f.isActivated)
        .map((fc, index) => {
          return (
            <TabPanel
              key={`tab-panel-${fc.id}`}
              className={classes.tabPanel}
              value={value}
              index={index}>
              <PsyIntervDetail2 formCatId={fc.id} session={session} />
            </TabPanel>
          );
        })
        .value()}
      {/* <div className={classes.menuChoice}>
        <Typography variant="body2">메뉴를 선택하세요</Typography>
        <hr className={classes.titleTxt} />
        {_.map(formCategories, (cat) => {
          return (
            <div key={`form-category-${cat.id}`} className={classes.menuWrap}>
              <div className={classes.menuBtnWrap}>
                {cat.id === 13 ? (
                  <Button
                    disableRipple
                    startIcon={<OutlinedFlagIcon className={classes.btnIcon} />}
                    variant="outlined"
                    onClick={() => handleRoute(cat.id)}
                    className={classes.menuBtn2}>
                    {cat.name}
                  </Button>
                ) : (
                  <Button
                    disableRipple
                    variant="outlined"
                    onClick={() => handleRoute(cat.id)}
                    className={classes.menuBtn}>
                    {cat.name}
                  </Button>
                )}
              </div>
              <div className={classes.menuDesc}>
                {_.split(cat.comment, '\\r\\n').map((s, index) => (
                  <div key={index}>{s}</div>
                ))}
              </div>
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default PsyIntervention;
