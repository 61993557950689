import React, {useCallback, useEffect, useState} from 'react';
import {useRouteMatch, Route} from 'react-router-dom';
import {Link, makeStyles, Theme, Typography} from '@material-ui/core';
import withTemplate from '~/hoc/withTemplate';
import ProgressScreen from '~/screens/ProgressScreen';
import SettingScreen from '~/screens/SettingScreen';
import StudyScreen from '~/screens/StudyScreen';
import UploadScreen from '~/screens/UploadScreen';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {COURSE} from '~/features/Course/slice';
import _ from 'lodash';
import * as Session from '~/features/Session/slice';
import {SESSION} from '~/features/Session/slice';
import * as Scenario from '~/features/Scenario/slice';
import {SCENARIO} from '~/features/Scenario/slice';
import StudentScreen from '~/screens/StudentScreen';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  top: {
    padding: '12px 0 12px 24px',
    color: 'rgba(73, 80, 85, 1)',
    borderBottom: '1px solid #DBDEDF',
    cursor: 'default',
  },
  header: {
    width: '90%',
    marginLeft: 'auto',
    color: 'rgba(73, 80, 85, 1)',
    borderBottom: '1px solid #DBDEDF',
    cursor: 'default',
  },
  headerChoice: {
    display: 'inline-block',
    padding: '13px 36px 11px 24px',
  },
  headerClass: {
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 600,
  },
  headerClassA: {
    margin: '0 20px 0 0 ',
    padding: '13px 10px 15px 10px',
    color: 'rgba(73, 80, 85, 1)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: ' rgba(37, 149, 255, 1)',
      borderBottom: '3px solid #2595FF',
      borderRadius: '2px 2px 0px 0px',
    },
  },
  active: {
    color: ' rgba(37, 149, 255, 1)',
    borderBottom: '3px solid #2595FF',
    borderRadius: '2px 2px 0px 0px',
    textDecoration: 'none',
  },
}));

const ScreenName = {
  score: '성적확인',
  progress: '진도확인',
  upload: '자료업로드',
  study: '학습설정',
  student: '학생설정',
};

const SettingRoutes = () => {
  const match = useRouteMatch();
  const screenMatch = useRouteMatch<{screen: keyof typeof ScreenName}>(
    '/settings/:screen',
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const course = useSelector((state: RootState) => state[COURSE].current);
  const scenarios = useSelector(
    (state: RootState) => state[SCENARIO].scenarios,
  );
  const sessions = useSelector((state: RootState) => state[SESSION].sessions);

  const [scenarioId, setScenarioId] = useState<number>();

  useEffect(() => {
    if (course) {
      dispatch(Scenario.load(course.id));
    }
  }, [dispatch, course]);

  useEffect(() => {
    if (scenarioId) {
      dispatch(Session.load(scenarioId));
    }
  }, [dispatch, scenarioId]);

  const updateSessions = useCallback((scenId: number) => {
    setScenarioId(scenId);
  }, []);

  const renderHeader = useCallback(
    (screen: keyof typeof ScreenName) => {
      return (
        <div key={`screen-${screen}`}>
          <div className={classes.top}>
            <Typography variant="h6">{ScreenName[screen]}</Typography>
          </div>
          {screen !== 'student' && (
            <div className={classes.header}>
              <Typography variant="h6" className={classes.headerChoice}>
                시나리오 선택
              </Typography>
              <Typography variant="body2" className={classes.headerClass}>
                {_.map(scenarios, (scenario) => {
                  return (
                    <Link
                      className={`${classes.headerClassA} ${
                        scenarioId === scenario.id ? classes.active : ''
                      }`}
                      key={`scenario-${scenario.id}`}
                      onClick={() => updateSessions(scenario.id)}>
                      {scenario.name}
                    </Link>
                  );
                })}
              </Typography>
            </div>
          )}
        </div>
      );
    },
    [screenMatch, scenarios, scenarioId, updateSessions],
  );

  const renderUpload = useCallback(
    () =>
      scenarioId && (
        <UploadScreen sessions={sessions} scenarioId={scenarioId} />
      ),
    [sessions, scenarioId],
  );

  const renderStudy = useCallback(
    () =>
      scenarioId && <StudyScreen sessions={sessions} scenarioId={scenarioId} />,
    [sessions, scenarioId],
  );

  const renderProgress = useCallback(
    () =>
      scenarioId && (
        <ProgressScreen scenarioId={scenarioId} sessions={sessions} />
      ),
    [sessions, scenarioId],
  );

  return (
    <div className={classes.root}>
      {screenMatch && renderHeader(screenMatch.params.screen)}
      <Route exact path={`${match.path}`} component={SettingScreen} />
      <Route exact path={`${match.path}/student`} component={StudentScreen} />
      {/* <Route exact path={`${match.path}/score`} component={ScoreScreen} /> */}
      <Route exact path={`${match.path}/progress`} render={renderProgress} />
      <Route exact path={`${match.path}/upload`} render={renderUpload} />
      <Route exact path={`${match.path}/study`} render={renderStudy} />
    </div>
  );
};

export default withTemplate(SettingRoutes);
