import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ProblemOutcome} from '~/types';

interface State {
  loading: boolean;
  pos: ProblemOutcome[];
}

let initialState: State = {
  loading: false,
  pos: [],
};

const slice = createSlice({
  name: 'po',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<ProblemOutcome[]>) => {
      state.loading = false;
      state.pos = payload;
    },
  },
});

export const {load, successLoad} = slice.actions;
export const PO = slice.name;
export default slice.reducer;
