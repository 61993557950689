import {makeStyles} from '@material-ui/core';
import React from 'react';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import {FormGroup, FormGroupType, Post} from '~/types';
import {renderTextField} from './common';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    height: '100%',
  },
  field: {
    marginBottom: 16,
  },
}));

const PostF = (props: InjectedFormProps<Post>) => {
  const classes = useStyles();
  const {handleSubmit} = props;
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div className={classes.field}>
        <Field
          id="title"
          name="title"
          label="제목"
          fullWidth
          component={renderTextField}
        />
      </div>
      <div className={classes.field}>
        <Field
          id="content"
          name="content"
          label="내용"
          fullWidth
          component={renderTextField}
        />
      </div>
    </form>
  );
};

const PostForm = reduxForm<Post>({
  form: 'Post',
})(PostF);

export default PostForm;
