import {PayloadAction} from '@reduxjs/toolkit';
import {takeLatest, call, put, getContext} from 'redux-saga/effects';
import {History} from 'history';
import api from '~/api';
import {CreateUser, IPasswordReset, PasswordFindType} from '~/types';
import {closePasswordModal, closeSignupModal} from '../Modal/slice';
import {clear, create, findPassword, load, resetPassword, successLoad} from './slice';
import { logout } from '../Login/slice';

function* handleLoad() {
  try {
    const {data: user} = yield call(api.getUser);
    yield put(successLoad(user));
  } catch (err) {
    console.log('error', err);
    yield put(clear());
    yield put(logout());
  }
}

function* handleCreate({payload}: PayloadAction<CreateUser>) {
  try {
    yield call(api.createUser, payload);
    yield put(closeSignupModal());
  } catch (err) {
    console.log('error', err.response.status);
    const errorCode = err.response.status;
    if (errorCode === 409) {
      alert('이미 등록된 학생입니다');
    } else if (errorCode === 404) {
      alert('등록된 학생이 없습니다');
    } else {
      alert('다시 시도해주세요');
    }
  }
}

function* handleFindPassword({payload}: PayloadAction<PasswordFindType>) {
  try {
    yield call(api.findPassword, payload);
    alert('이메일을 발송하였습니다. 확인 후 비밀번호를 재설정해주세요.');
    yield put(closePasswordModal());
  } catch (err) {
    console.log('error', err);
    alert('에러가 발생하였습니다. 잠시후 다시 시도해주세요.');
    yield put(closePasswordModal());
  }
}

function* handleResetPassword({
  payload,
}: PayloadAction<IPasswordReset & {history: History}>) {
  const {history, ...restPayload} = payload;
  try {
    yield call(api.resetPassword, restPayload);
    alert('비밀번호 변경이 완료되었습니다. 다시 로그인하세요.');
    history.push('/');
  } catch (err) {
    console.log('error', err);
    const errorCode = err.response.status;
    if (errorCode === 404) {
      alert(
        '경로가 잘못되었거나, 변경 가능 시간이 초과되었습니다. 다시 비밀번호 찾기를 시도해주세요.',
      );
      history.push('/find-password');
    }
  }
}

export function* watchUser() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(create, handleCreate);
  yield takeLatest(findPassword, handleFindPassword);
  yield takeLatest(resetPassword, handleResetPassword);
}
