import {
  Button,
  CircularProgress,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  styled,
  TextField,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {COURSE} from '~/features/Course/slice';
import {SCENARIO} from '~/features/Scenario/slice';
import {SESSION} from '~/features/Session/slice';
import * as UserScoreAction from '~/features/UserScore/slice';
import * as AnswersAction from '~/features/Answers/slice';
import * as StudentAction from '~/features/Student/slice';
import * as ScenarioAction from '~/features/Scenario/slice';
import * as SessionAction from '~/features/Session/slice';
import * as FormGroupAction from '~/features/FormGroup/slice';
import * as FormCategoryAction from '~/features/FormCategory/slice';
import {Link} from '@material-ui/core';
import {
  Form,
  FormGroup,
  FormGroupType,
  Session,
  SessionType,
  UserScore,
} from '~/types';
import {useLocation} from 'react-router-dom';
import QontoConnector from '~/components/QontoConnector';
import Quiz from '~/components/Quiz';
import SubjectForm from '~/forms/SubjectForm';
import moment from 'moment-timezone';

const StyledStepLabel = withStyles({
  labelContainer: {
    maxWidth: 100,
    maxHeight: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  label: {
    fontWeight: 600,
    fontSize: 14,
  },
})(StepLabel);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  top: {
    padding: '12px 0 12px 24px',
    color: 'rgba(73, 80, 85, 1)',
    borderBottom: '1px solid #DBDEDF',
    cursor: 'default',
  },
  header: {
    width: '90%',
    marginLeft: 'auto',
    color: 'rgba(73, 80, 85, 1)',
    borderBottom: '1px solid #DBDEDF',
    cursor: 'default',
  },
  headerChoice: {
    display: 'inline-block',
    padding: '13px 36px 11px 24px',
  },
  headerClass: {
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 600,
  },
  headerClassA: {
    margin: '0 20px 0 0 ',
    padding: '13px 10px 15px 10px',
    color: 'rgba(73, 80, 85, 1)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: ' rgba(37, 149, 255, 1)',
      borderBottom: '3px solid #2595FF',
      borderRadius: '2px 2px 0px 0px',
    },
  },
  active: {
    color: ' rgba(37, 149, 255, 1)',
    borderBottom: '3px solid #2595FF',
    borderRadius: '2px 2px 0px 0px',
    textDecoration: 'none',
  },

  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menubar: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '76%',
    cursor: 'pointer',
  },
  menuSession: {
    color: 'rgba(73, 80, 85, 1)',
    padding: '12px 2px 6px 2px',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'none',
      color: ' rgba(37, 149, 255, 1)',
      borderBottom: '3px solid #2595FF',
      borderRadius: '2px 2px 0px 0px',
    },
  },

  stepperWrap: {
    padding: '17px 0',
  },
  contextArea: {
    margin: 'auto',
    marginBottom: 50,
    width: '90%',
    boxShadow: '0.2px solid rgba(0, 0, 0, 1)',
  },
  titleStyle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    margin: '30px auto',
    width: '90%',
  },
  scoreText: {
    width: 80,
  },
}));

const EX_FIELDS = [
  SessionType.INTRO,
  SessionType.PRE_QUIZ,
  SessionType.POST_QUIZ,
  SessionType.SIMULATION,
  SessionType.CHART,
  SessionType.ADM_PLAN,
  SessionType.ADM_PLAN_RESULT,
  SessionType.PSY_PROBLEM,
  SessionType.PSY_PLAN,
  SessionType.MSE,
];

const GradingResultScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation<{
    userId: number;
    scenarioId: number;
    sessionId: number;
  }>();

  const answers = useSelector(
    (state: RootState) => state[AnswersAction.ANSWER].answers,
  );

  const students = useSelector(
    (state: RootState) => state[StudentAction.STUDENT].students,
  );
  const userScores = useSelector(
    (state: RootState) => state[UserScoreAction.USERSCORE].userScores,
  );
  const usLoading = useSelector(
    (state: RootState) => state[UserScoreAction.USERSCORE].loading,
  );
  const course = useSelector((state: RootState) => state[COURSE].current);
  const sessions = useSelector((state: RootState) => state[SESSION].sessions);
  const scenarios = useSelector(
    (state: RootState) => state[SCENARIO].scenarios,
  );
  const formGroupsRedux = useSelector(
    (state: RootState) => state[FormGroupAction.FORMGROUPS].formGroups,
  );
  const formCategoriesRedux = useSelector(
    (state: RootState) =>
      state[FormCategoryAction.FORMCATEGORIES].formCategories,
  );
  const [scenarioId, setScenarioId] = useState<number | undefined>(
    location.state.scenarioId,
  );
  const [session, setSession] = useState<Session | undefined>(
    _.find(sessions, (sess) => sess.id === location.state.sessionId),
  );
  const [scoreVal, setScoreVal] = useState<number | undefined>(0);
  const [userScore, setUserScore] = useState<UserScore>();
  const [activeStep, setActiveStep] = useState(0);
  const [formGroups, setFormGroups] = useState<{[key: string]: FormGroup[]}>(
    {},
  );
  const [formGroup, setFormGroup] = useState<FormGroup | undefined>(
    session && formGroups[session.type]?.[activeStep],
  );
  const [formLength, setFormLength] = useState(0);

  useEffect(() => {
    if (session?.type === SessionType.PSY_INTERVENTION) {
      setFormGroups((fgs) => ({
        ...fgs,
        [SessionType.PSY_INTERVENTION]: _.chain(formCategoriesRedux)
          .map((fc) =>
            _.map(fc.formGroups, (fg) => ({
              ...fg,
              name: `${fc.name}-${fg.name}`,
              formCategoryId: fc.id,
            })),
          )
          .flattenDeep()
          .value(),
      }));
    } else {
      setFormGroups((fgs) => ({
        ...fgs,
        ...formGroupsRedux,
      }));
    }
  }, [session, formGroupsRedux, formCategoriesRedux]);

  useEffect(() => {
    if (session) {
      setFormLength(formGroups[session.type]?.length);
    }
  }, [session, formGroups]);

  useEffect(() => {
    if (formGroup) {
      const sc = _.find(userScores, (us) => {
        if (session?.type === SessionType.PSY_INTERVENTION) {
          return (
            us.formGroupId === formGroup.id &&
            us.formCategoryId === formGroup.formCategoryId
          );
        } else {
          return us.formGroupId === formGroup.id;
        }
      });
      if (sc) {
        setScoreVal(sc.score);
        setUserScore(sc);
        return;
      }
    }
    setUserScore(undefined);
    setScoreVal(0);
  }, [formGroup, userScores, session]);

  useEffect(() => {
    if (scenarioId && session) {
      dispatch(
        UserScoreAction.load({
          userId: location.state.userId,
          scenarioId,
          sessionId: session.id,
        }),
      );
    }
  }, [location, scenarioId, session]);

  useEffect(() => {
    if (session) {
      setFormGroup(formGroups[session.type]?.[activeStep]);
    }
  }, [session, formGroups, activeStep]);

  useEffect(() => {
    if (scenarioId && session) {
      dispatch(
        AnswersAction.load({
          scenarioId,
          sessionId: session.id,
          userId: location.state.userId,
        }),
      );
    }
  }, [dispatch, scenarioId, session]);

  useEffect(() => {
    dispatch(StudentAction.load());
  }, [dispatch]);

  useEffect(() => {
    if (course) {
      dispatch(ScenarioAction.load(course.id));
    }
  }, [dispatch, course]);

  useEffect(() => {
    if (scenarioId) {
      dispatch(SessionAction.load(scenarioId));
    }
  }, [dispatch, scenarioId]);

  useEffect(() => {
    if (scenarioId && session) {
      dispatch(FormGroupAction.load({scenarioId, session}));
      dispatch(FormCategoryAction.load({scenarioId, session}));
    }
  }, [dispatch, scenarioId, session]);

  useEffect(() => {
    setSession(
      _.find(sessions, (sess) => sess.id === location.state.sessionId),
    );
  }, [location.state, sessions]);

  const updateSessions = useCallback((scenId: number | undefined) => {
    setScenarioId(scenId);
  }, []);

  const renderStudentInfo = () => {
    const stu = _.find(students, (st) => st.user?.id === location.state.userId);
    return (
      <div style={{display: 'flex', margin: 32}}>
        <Typography variant="h6" style={{marginRight: 16}}>
          학번: {stu?.studentId}
        </Typography>
        <Typography variant="h6">이름: {stu?.name}</Typography>
      </div>
    );
  };

  const renderHeader = useCallback(() => {
    return (
      <div key={`screen-진도확인`}>
        <div className={classes.top}>
          <Typography variant="h6">성적확인</Typography>
        </div>
        <div className={classes.header}>
          <Typography variant="h6" className={classes.headerChoice}>
            시나리오 선택
          </Typography>
          <Typography variant="body2" className={classes.headerClass}>
            {_.map(scenarios, (scenario) => {
              return (
                <Link
                  className={`${classes.headerClassA} ${
                    scenarioId === scenario.id ? classes.active : ''
                  }`}
                  key={`scenario-${scenario.id}`}
                  onClick={() => {
                    updateSessions(scenario.id);
                    setSession(undefined);
                  }}>
                  {scenario.name}
                </Link>
              );
            })}
          </Typography>
        </div>
      </div>
    );
  }, [scenarios, scenarioId, updateSessions]);

  const renderScenarioStepper = () =>
    session && (
      <Stepper
        connector={<QontoConnector />}
        className={classes.stepperWrap}
        activeStep={activeStep}
        alternativeLabel>
        {_.map(formGroups[session.type], (fg, indx) => (
          <Step key={fg.name} onClick={() => setActiveStep(indx)}>
            <StyledStepLabel style={{cursor: 'pointer'}}>
              {fg.name}
            </StyledStepLabel>
          </Step>
        ))}
      </Stepper>
    );

  const handleScoreChange = (val: string, score: number) => {
    const intVal = parseInt(val);

    if (val === '') {
      setScoreVal(undefined);
    }

    if (!isNaN(intVal)) {
      if (intVal < 0) {
        setScoreVal(0);
      } else if (intVal > score) {
        setScoreVal(score);
      } else if (intVal >= 0 && intVal <= score) {
        setScoreVal(intVal);
      }
    }
  };

  const handleUpdateScore = () => {
    if (
      course &&
      scenarioId &&
      session &&
      formGroup &&
      scoreVal !== undefined
    ) {
      if (userScore) {
        dispatch(
          UserScoreAction.update({
            ...userScore,
            score: scoreVal,
          }),
        );
      } else {
        dispatch(
          UserScoreAction.create({
            score: scoreVal,
            userId: location.state.userId,
            courseId: course.id,
            scenarioId,
            sessionId: session.id,
            formGroupId: formGroup.id,
            formCategoryId: formGroup.formCategoryId,
          }),
        );
      }
    }
  };

  const handlePrev = () => {
    setActiveStep((a) => a - 1);
  };

  const handleNext = () => {
    setActiveStep((a) => a + 1);
  };

  const renderProblem = useCallback(() => {
    if (formGroup) {
      const ans = _.filter(answers, (an) => {
        if (session?.type === SessionType.PSY_INTERVENTION) {
          return (
            an.userId === location.state.userId &&
            an.formGroupId === formGroup.id &&
            an.formCategoryId === formGroup.formCategoryId
          );
        } else {
          return (
            an.userId === location.state.userId &&
            an.formGroupId === formGroup.id
          );
        }
      });
      const newFormGroup = {
        ...formGroup,
        forms: _.map(formGroup?.forms, (f) => {
          const formAns = _.chain(ans)
            .filter((a) => a.formId === f.id)
            .sortBy((a) => moment(a.updatedAt))
            .reverse()
            .value()[0];
          return {
            ...f,
            answers: formAns ? [formAns] : [],
          } as Form;
        }),
      } as FormGroup;
      let score = 0;
      if (session?.type === SessionType.PSY_INTERVENTION) {
        score =
          _.find(
            newFormGroup.scores,
            (s) => s.formCategoryId === formGroup.formCategoryId,
          )?.score ?? 0;
      } else {
        score =
          _.chain(newFormGroup.scores)
            .filter((s) => s.formCategoryId === null)
            .first()
            .value()?.score ?? 0;
      }

      return (
        <div>
          <div className={classes.titleStyle}>
            <Typography variant="h6">※ {newFormGroup.name}</Typography>
          </div>

          <div className={classes.contextArea}>
            {newFormGroup.type === FormGroupType.SUBJECTIVE && (
              <SubjectForm
                readOnly
                initialValues={newFormGroup}
                // form={`formgroups-${newFormGroup.id}`}
              />
            )}
            {newFormGroup.type === FormGroupType.OBJECTIVE && session && (
              <Quiz session={session} formGroup={newFormGroup} index={0} />
            )}
            <br />
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 16,
                  minWidth: 120,
                }}>
                <TextField
                  className={classes.scoreText}
                  value={scoreVal}
                  onChange={(e) => handleScoreChange(e.target.value, score)}
                />
                <div>/ {score}점</div>
              </div>
              {usLoading && <CircularProgress size={24} color="primary" />}
              <Button
                variant="contained"
                color="primary"
                disabled={usLoading}
                onClick={() => handleUpdateScore()}>
                저장
              </Button>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 16,
              }}>
              {activeStep > 0 && (
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{marginRight: 16}}
                  onClick={() => handlePrev()}>
                  이전문제
                </Button>
              )}
              {activeStep !== formLength - 1 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => handleNext()}>
                  다음문제
                </Button>
              )}
            </div>
          </div>
        </div>
      );
    }
  }, [
    answers,
    formGroup,
    activeStep,
    location,
    scoreVal,
    session,
    answers,
    usLoading,
    formLength,
  ]);

  return (
    <div>
      <div>{renderHeader()}</div>

      {scenarioId && (
        <div className={classes.container}>
          <div className={classes.menubar}>
            {_.filter(
              sessions,
              (sess) => !_.includes(EX_FIELDS, sess.type),
            ).map((sess) => (
              <Link
                className={`${classes.menuSession} ${
                  session?.id === sess.id ? classes.active : ''
                }`}
                key={`link-${sess.id}`}
                onClick={() => {
                  setSession(sess);
                  setActiveStep(0);
                }}>
                {sess.name.replace('\\r\\n', ' ')}
              </Link>
            ))}
          </div>
        </div>
      )}
      {renderStudentInfo()}
      {renderScenarioStepper()}
      {renderProblem()}
    </div>
  );
};

export default GradingResultScreen;
