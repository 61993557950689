import React, {useEffect} from 'react';
import withTemplate from '~/hoc/withTemplate';
import LoginModal from '~/modals/LoginModal';
import PasswordModal from '~/modals/PasswordModal';
import SignupModal from '~/modals/SignupModal';
import {useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {LOGIN} from '~/features/Login/slice';
import {useHistory} from 'react-router-dom';
import {Button, makeStyles} from '@material-ui/core';

import mainBg from '~/assets/main_bg.jpg';
import logo from '~/assets/logo.png';
import {UserRoleType} from '~/types';
import {USER} from '~/features/User/slice';

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    backgroundImage: `url(${mainBg})`,
    backgroundPosition: 'center center',
    justifyContent: 'flex-end',
  },
  container: {
    display: 'flex',
    alignSelf: 'flex-end',
    flexDirection: 'column',
  },
  btn: {
    alignSelf: 'center',
    width: 118,
    marginBottom: 10,
  },
}));

const HomeScreen = () => {
  const classes = useStyle();
  const history = useHistory();
  const authorized = useSelector((state: RootState) => state[LOGIN].authorized);
  const role = useSelector((state: RootState) => state[USER].user?.role.role);

  useEffect(() => {
    if (authorized) {
      history.push('/');
    }
  }, [authorized, history]);

  const handleStart = () => {
    history.push('/class');
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {authorized && role === UserRoleType.STUDENT && (
          <div className={classes.btn}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => handleStart()}>
              교육 시작
            </Button>
          </div>
        )}
        <img src={logo} alt="logo" />
      </div>
      <LoginModal />
      <PasswordModal />
      <SignupModal />
    </div>
  );
};
export default withTemplate(HomeScreen);
