import {Typography, makeStyles} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {isDirty, stopSubmit} from 'redux-form';
import {RootState} from '~/app/rootReducers';
import {FORMCATEGORIES} from '~/features/FormCategory/slice';
import {updateAnswer} from '~/features/FormGroup/slice';
import SubjectForm from '~/forms/SubjectForm';
import {FormGroup, Session} from '~/types';

const useStyles = makeStyles((theme) => ({
  titleWrap: {
    margin: '5px 40px',
    '&:nth-child(n+2)': {
      marginTop: 40,
    },
  },
}));

const PsyIntervDetail2 = ({
  session,
  formCatId,
}: {
  session: Session;
  formCatId: number;
}) => {
  const formCat = useSelector((state: RootState) => {
    const Fcat = _.find(
      state[FORMCATEGORIES].formCategories,
      (cat) => cat.id === formCatId,
    );
    if (Fcat) {
      return {
        ...Fcat,
        formGroups: _.map(Fcat.formGroups, (fg) => {
          return {
            ...fg,
            forms: _.map(fg.forms, (form) => {
              return {
                ...form,
                answers: [
                  _.chain(form.answers)
                    .sortBy((a) => moment(a.updatedAt))
                    .reverse()
                    .value()[0],
                ],
              };
            }),
          };
        }),
      };
    }
    return Fcat;
  });
  const state = useSelector((state: RootState) => state);

  const classes = useStyles();

  // const handleSubmit = useCallback(
  //   (formGroup: FormGroup) => {
  //     const formName = `${session.type}-${formGroup.id}`;
  //     if (isDirty(formName)(state)) {
  //       dispatch(
  //         updateAnswer({
  //           formName,
  //           scenarioId: parseInt(scenarioId),
  //           session,
  //           formGroup,
  //         }),
  //       );
  //     } else {
  //       dispatch(stopSubmit(formName));
  //     }
  //   },
  //   [dispatch, state, scenarioId, session],
  // );

  const handleSubmit = () => {};

  return (
    <div>
      {_.map(formCat?.formGroups ?? [], (formGroup) => (
        <div key={`subject-form-${formGroup.id}`} className={classes.titleWrap}>
          <Typography variant="h6">※ {formGroup.name}</Typography>

          {formGroup.explain && <div>{formGroup.explain}</div>}
          {formGroup.image && <img src={formGroup.image} />}

          <SubjectForm
            initialValues={formGroup}
            form={`${session.type}-${formCat?.name}-${formGroup.id}`}
            onSubmit={handleSubmit}
          />
        </div>
      ))}
    </div>
  );
};

export default PsyIntervDetail2;
