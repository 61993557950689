import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  Modal,
  Paper,
  Select,
  styled,
  TextField,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {COURSE, create, load, loadTmpl} from '~/features/Course/slice';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import {USER} from '~/features/User/slice';
import {UserRoleType} from '~/types';
import api from '~/api';

interface Props {
  open: boolean;
  onClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    flexDirection: 'column',
    width: 331,
    height: 260,
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'Translate(-50%,-50%)',
  },
  title: {
    height: 44,
    fontWeight: 700,
    padding: '6px 26px',
    borderBottom: '1px solid #DBDEDF',
  },
  contentWrap: {
    display: 'flex',
    height: '80%',
    padding: '24px',
  },
  select: {
    color: '#a7adb2',
    fontWeight: 400,
  },
  selectInput: {
    padding: 10.5,
  },
  selectItemTitle: {
    color: '#a7adb2',
  },
  selectItem: {
    color: '#a7adb2',
  },

  buttonWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 8,
  },
}));

const StyledButton = styled(Button)({
  textTransform: 'none',
  boxShadow: 'none',
  borderRadius: 2,
  '&:first-child': {
    marginRight: 8,
  },
});

const CourseCreateModal = ({open, onClose}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const courses = useSelector((state: RootState) => state[COURSE].courseTmpl);
  const user = useSelector((state: RootState) => state[USER].user);
  const role = useSelector((state: RootState) => state[USER].user?.role.role);

  const [courseName, setCourseName] = useState<string>();
  const [courseId, setCourseId] = useState<number>();

  useEffect(() => {
    dispatch(loadTmpl());
  }, [dispatch]);

  const handleChange = (evt: any) => {
    setCourseId(evt.target.value);
  };

  const handleSave = useCallback(async () => {
    if (courseName && courseId && user) {
      // dispatch(create({tmplId: courseId, name: courseName}));
      await api.createCourseWithTmplId(user.id, courseId, {
        name: courseName,
        schoolId: user.schoolId,
      });
      dispatch(load(user.id));
      onClose();
    }
  }, [dispatch, user, courseName, courseId]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description">
      <Paper elevation={0} className={classes.paper}>
        <Typography component="h1" variant="h6" className={classes.title}>
          클래스 생성
        </Typography>
        <div className={classes.contentWrap}>
          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                placeholder="Class Name"
                type="string"
                size="small"
                id="classname"
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PermIdentityIcon fontSize="small" color="disabled" />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs>
              <FormControl variant="outlined" fullWidth>
                <Select
                  native
                  labelId="demo-simple-select-label"
                  id="selectclass"
                  value={courseId}
                  className={classes.select}
                  classes={{
                    root: classes.selectInput,
                  }}
                  onChange={handleChange}>
                  <option key="course-0" className={classes.selectItemTitle}>
                    Class template selection
                  </option>
                  {_.map(courses, (course) => (
                    <option
                      key={`course-${course.id}`}
                      value={course.id}
                      className={classes.selectItem}>
                      {course.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <div className={classes.buttonWrap}>
              <StyledButton
                onClick={() => onClose()}
                type="button"
                variant="outlined"
                size="small">
                Cancel
              </StyledButton>
              <StyledButton
                variant="contained"
                size="small"
                color="primary"
                onClick={handleSave}>
                Add
              </StyledButton>
            </div>
          </Grid>
        </div>
      </Paper>
    </Modal>
  );
};

export default CourseCreateModal;
