import React, {useCallback} from 'react';
import {makeStyles, styled} from '@material-ui/core/styles';
import {
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  AppBar,
  Toolbar,
  Typography,
  Link,
  Avatar,
  Grid,
} from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {openLoginModal, openProfileModal} from '~/features/Modal/slice';
import {LOGIN, logout} from '~/features/Login/slice';
import {RootState} from '~/app/rootReducers';
import {useHistory} from 'react-router-dom';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import {USER} from '~/features/User/slice';
import {UserRoleType} from '~/types';

const useStyles = makeStyles({
  rootWarp: {
    boxShadow: 'none',
    backgroundColor: '#FFFFFF',
    borderBottom: '1px solid #DBDEDF',
  },
  root: {
    minHeight: 56,
    padding: 0,
  },
  menuWarp: {
    flexGrow: 1,
    height: '100%',
  },
  title: {
    paddingLeft: 16,
    paddingRight: 18,
    color: '#000000',
    cursor: 'default',
  },
  profileWrap: {
    paddingRight: 13.25,
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
  },

  profileSize: {
    width: 32,
    height: 32,
  },
  profileIcon: {
    fontSize: 15,
    color: '#495055',
  },
  popper: {
    zIndex: 99,
  },
  paper: {
    position: 'fixed',
    boxShadow: 'none',
    border: '1px solid #DBDEDF',
    top: 20,
    left: -220,
  },

  profileMenuWrap: {
    padding: 0,
    width: 240,
    height: 170,
    borderRadius: 2,

    '&:after': {
      borderTop: '0px solid transparent',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
      borderBottom: '9px solid #FFFFFF',
      content: '""',
      position: 'absolute',
      top: -9,
      left: 201,
    },

    '&:before': {
      borderTop: '0px solid transparent',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid #DBDEDF',
      content: '""',
      position: 'absolute',
      top: -10,
      left: 200,
    },
  },

  profileMenuItem: {
    '&:first-child': {
      padding: '20px 12px',
      '&:hover': {
        backgroundColor: '#FFFFFF',
        cursor: 'default',
      },
    },
    '&:nth-child(2)': {
      padding: '12px 16px',
    },
    '&:last-child': {
      padding: '12px 16px',
    },
  },
  profileMenuItemMore: {
    borderBottom: '1px solid #DBDEDF',
    borderTop: '1px solid #DBDEDF',
  },

  profileSize2: {
    width: 40,
    height: 40,
    marginRight: 12,
  },
});

const StyledLink = styled(Link)({
  color: '#495055',
  padding: '16px 18px',
  cursor: 'poninter',
  '&:hover': {
    color: '#2595FF',
    textDecoration: 'none',
    borderTop: '4px solid #2595FF',
    cursor: 'pointer',
  },
});

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const authorized = useSelector((state: RootState) => state[LOGIN].authorized);
  const role = useSelector((state: RootState) => state[USER].user?.role.role);
  const user = useSelector((state: RootState) => state[USER].user);

  const doOpenLogin = useCallback(() => {
    dispatch(openLoginModal());
  }, [dispatch]);

  const showMenu = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = useCallback(() => {
    dispatch(logout());
    history.push('/');
    setOpen(false);
  }, [history, dispatch]);

  const handleProfile = useCallback(() => {
    dispatch(openProfileModal());
  }, [dispatch]);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <div>
      <AppBar position="static" className={classes.rootWarp}>
        <Toolbar className={classes.root}>
          <Typography variant="h6" className={classes.title}>
            VR_PMCPS
          </Typography>
          <div className={classes.menuWarp}>
            {role && (
              <>
                <StyledLink
                  onClick={() =>
                    history.push(
                      role === 'prof'
                        ? '/prof-class'
                        : role === 'admin'
                        ? '/admin-class'
                        : '/class',
                    )
                  }
                  variant="body2">
                  My Class
                </StyledLink>
                <StyledLink
                  onClick={() =>
                    history.push(role === 'prof' ? '/result' : '/result')
                  }
                  variant="body2">
                  My Result
                </StyledLink>

                <StyledLink
                  onClick={() => history.push('/board')}
                  variant="body2">
                  Board
                </StyledLink>
              </>
            )}
          </div>
          <Button
            ref={anchorRef}
            disableRipple
            className={classes.profileWrap}
            onClick={authorized ? showMenu : doOpenLogin}
            color="primary">
            <div className={classes.profile}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                className={classes.profileSize}
              />
              <ArrowDropDownIcon
                className={classes.profileIcon}></ArrowDropDownIcon>
            </div>
          </Button>

          <Popper
            className={classes.popper}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal>
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}>
                <Paper className={classes.paper}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      className={classes.profileMenuWrap}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}>
                      <MenuItem
                        className={classes.profileMenuItem}
                        disableRipple>
                        <Avatar
                          alt="Remy Sharp"
                          src="/static/images/avatar/1.jpg"
                          className={classes.profileSize2}
                        />
                        <Grid container>
                          <Grid item xs>
                            <Typography variant="body2" display="block">
                              {user?.student?.name}
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography
                              variant="caption"
                              display="block"
                              color="textSecondary">
                              {user?.school?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                      <MenuItem
                        onClick={handleProfile}
                        className={`${classes.profileMenuItem} ${classes.profileMenuItemMore}`}>
                        <PersonOutlineIcon
                          color="disabled"
                          fontSize="small"
                          style={{
                            marginRight: 14,
                          }}
                        />
                        <Typography variant="body2">Profile</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={handleLogout}
                        className={classes.profileMenuItem}>
                        <ExitToAppSharpIcon
                          color="disabled"
                          fontSize="small"
                          style={{
                            marginRight: 14,
                          }}
                        />
                        <Typography variant="body2"> Log out</Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
