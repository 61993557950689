import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Form, ScenarioType} from '~/types';

export interface CreateForm {
  scenarioType: ScenarioType;
  form: Omit<Form, 'id'>;
}

interface State {
  loading: boolean;
  forms: Form[];
}

let initialState: State = {
  loading: false,
  forms: [],
};

const slice = createSlice({
  name: 'candidate-form',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<ScenarioType>) => {
      state.loading = true;
    },
    create: (state, _action: PayloadAction<CreateForm>) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<Form[]>) => {
      state.loading = false;
      state.forms = payload;
    },
    update: (state, _action: PayloadAction<Partial<Form>>) => {
      state.loading = false;
    },
  },
});

export const {load, create, update, successLoad} = slice.actions;
export const CANDIDATEFORM = slice.name;
export default slice.reducer;
