import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {UserInfo, UserScore} from '~/types';

interface State {
  loading: boolean;
  userScores: UserScore[];
}

let initialState: State = {
  loading: false,
  userScores: [],
};

const slice = createSlice({
  name: 'user-score',
  initialState,
  reducers: {
    load: (
      state,
      _action: PayloadAction<{
        scenarioId?: number;
        sessionId?: number;
        userId?: number;
      }>,
    ) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<UserScore[]>) => {
      state.loading = false;
      state.userScores = payload;
    },
    create: (state, _action: PayloadAction<UserScore>) => {
      state.loading = true;
    },
    update: (state, _action: PayloadAction<UserScore>) => {
      state.loading = true;
    },
  },
});

export const {load, successLoad, create, update} = slice.actions;
export const USERSCORE = slice.name;
export default slice.reducer;
