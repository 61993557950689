import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useHistory, useParams} from 'react-router-dom';
import {RootState} from '~/app/rootReducers';
import {load, SCENARIO} from '~/features/Scenario/slice';
import * as Session from '~/features/Session/slice';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const useStyles = makeStyles((theme: Theme) => ({
  prevBtn: {
    width: 119,
    height: 40,
    fontWeight: 500,
  },
  container: {
    backgroundColor: '#E7F5FC',
    width: '90%',
    margin: 'auto',
    height: '720px',
    padding: 40,
  },
  item: {
    display: 'inline-block',
    height: '513px',
    width: '400px',
    border: '0.5px solid #000000',
    borderRadius: '2px',
    marginRight: 40,
    '&:last-child': {
      marginRight: 0,
    },
  },
  cardHeader: {
    height: 44,
    padding: '5px 0px 0px 16px',
  },
  mediaImg: {width: 400, height: 400},
  actionField: {
    width: 240,
    marginLeft: 20,
  },
  cardAction: {
    width: 52,
    height: 40,
  },
  cardContainer: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: '50px 0',
  },
}));

const StyledButton = styled(Button)({
  textTransform: 'none',
  margin: '8px 0px 0px 319px ',
  backgroundColor: '#2595FF',
  height: '40px',
  width: '52px',
  color: '#fff',
  position: 'relative',
  top: 11,
  left: -9,
  borderRadius: '2px',
  fontSize: '14px',
});

interface ParamTypes {
  courseId: string;
}

const ScenarioScreen = () => {
  const {courseId} = useParams<ParamTypes>();
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const scenarios = useSelector(
    (state: RootState) => state[SCENARIO].scenarios,
  );

  useEffect(() => {
    dispatch(load(parseInt(courseId)));
  }, [dispatch, courseId]);

  const handleGoBack = () => {
    history.goBack();
  };

  const handleScenClick = (scenarioId: number) => {
    dispatch(Session.load(scenarioId));
    history.push(`/scenario/${scenarioId}/1`);
  };

  return (
    <div className={classes.container}>
      <div>
        <Button
          className={classes.prevBtn}
          variant="contained"
          color="primary"
          startIcon={<ArrowBackIosIcon />}
          onClick={handleGoBack}>
          이전단계
        </Button>
      </div>
      <div className={classes.cardContainer}>
        {_.map(scenarios, (scen) => (
          <Card key={`card-${scen.id}`} className={classes.item}>
            <CardHeader
              className={classes.cardHeader}
              title={`${scen.name}${
                scen.description ? `. ${scen.description}` : ''
              }`}
              titleTypographyProps={{
                style: {fontSize: 14, fontWeight: 600},
              }}
            />
            <CardMedia
              className={classes.mediaImg}
              image={`/images/${scen.thm}`}
              style={{width: 400, height: 400}}
            />
            <CardActions className={classes.cardAction}>
              {/* <Link
                key={scen.id}
                to={`/scenario/${scen.id}/1`}
                style={{
                  textDecoration: 'none',
                  color: '#fff',
                }}>
                <StyledButton type="submit" size="small">
                  Start
                </StyledButton>
              </Link> */}
              <StyledButton
                type="submit"
                size="small"
                onClick={() => handleScenClick(scen.id)}>
                Start
              </StyledButton>
            </CardActions>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ScenarioScreen;
