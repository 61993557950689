import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core';
import {detect} from 'detect-browser';
import {Scenario} from '~/types';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

interface Props {
  scenario: Scenario;
}

const SimulationScreen = ({scenario}: Props) => {
  const notSupport = useMemo(() => {
    const browser = detect();
    if (browser) {
      if (_.includes(['iOS', 'Android OS'], browser.os)) {
        return true;
      }
    }
    return false;
  }, []);

  console.log(
    'SimulationScreen',
    scenario,
    scenario.youtube,
    notSupport,
    detect(),
  );

  return (
    <div>
      {scenario.simulation && !notSupport && (
        <div
          dangerouslySetInnerHTML={{
            __html: `<iframe src="${scenario?.simulation}" width="1216" height="800" />`,
          }}
        />
      )}
      {scenario.youtube && notSupport && (
        <iframe
          width="100%"
          height="315"
          src={scenario.youtube}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen></iframe>
      )}
    </div>
  );
};

export default SimulationScreen;
