import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import api from '~/api';
import {RootState} from '~/app/rootReducers';
import {
  create,
  CreatePost,
  DeletePost,
  deletePost,
  load,
  loadMore,
  POST,
  successLoad,
  successLoadMore,
  update,
} from './slice';

function* handleLoad({payload}: PayloadAction<number>) {
  try {
    const page = yield select((state: RootState) => state[POST].page);
    const resp = yield call(api.getPostsWithBoardId, payload, page);
    yield put(successLoad(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleLoadMore({payload}: PayloadAction<number>) {
  try {
    const page = yield select((state: RootState) => state[POST].page);
    const resp = yield call(api.getPostsWithBoardId, payload, page);
    yield put(successLoadMore(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleCreate({payload}: PayloadAction<CreatePost>) {
  try {
    const resp = yield call(
      api.createPostWithBoardId,
      payload.boardId,
      payload.post,
    );
    yield put(load(payload.boardId));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleDelete({payload}: PayloadAction<DeletePost>) {
  try {
    const resp = yield call(api.deletePost, payload.postId);
    yield put(load(payload.boardId));
  } catch (err) {
    console.log('error', err);
  }
}

function* handleUpdate({payload}: PayloadAction<CreatePost>) {
  try {
    const resp = yield call(api.updatePost, payload.post.id, payload.post);
    yield put(load(payload.boardId));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchPost() {
  yield takeLatest(load, handleLoad);
  yield takeLatest(loadMore, handleLoadMore);
  yield takeLatest(create, handleCreate);
  yield takeLatest(deletePost, handleDelete);
  yield takeLatest(update, handleUpdate);
}
