import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  AttachFormGroup,
  Session,
  SessionClear,
  SessionFile,
  UploadSessionFile,
} from '~/types';

export interface DeleteSessionFile {
  scenarioId: number;
  file: SessionFile;
}

interface State {
  loading: boolean;
  sessions: Session[];
  sessionClears: SessionClear[];
}

let initialState: State = {
  loading: false,
  sessions: [],
  sessionClears: [],
};

const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    clear: (state) => {
      state.sessions = [];
    },
    load: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    loadSessionClear: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    successLoadSessionClear: (
      state,
      {payload}: PayloadAction<SessionClear[]>,
    ) => {
      state.loading = false;
      state.sessionClears = payload;
    },
    successLoad: (state, {payload}: PayloadAction<Session[]>) => {
      state.loading = false;
      state.sessions = payload;
    },
    uploadFile: (state, _action: PayloadAction<UploadSessionFile>) => {
      state.loading = true;
    },
    attachFormGroup: (state, _action: PayloadAction<AttachFormGroup>) => {
      state.loading = false;
    },
    detachFormGroup: (state, _action: PayloadAction<AttachFormGroup>) => {
      state.loading = false;
    },
    deleteFile: (state, _action: PayloadAction<DeleteSessionFile>) => {
      state.loading = false;
    },
  },
});

export const {
  load,
  loadSessionClear,
  successLoadSessionClear,
  clear,
  attachFormGroup,
  detachFormGroup,
  uploadFile,
  successLoad,
  deleteFile,
} = slice.actions;
export const SESSION = slice.name;
export default slice.reducer;
