import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  LinearProgressProps,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {COURSE} from '~/features/Course/slice';
import {SCENARIO} from '~/features/Scenario/slice';
import {SESSION} from '~/features/Session/slice';
import * as UserScoreAction from '~/features/UserScore/slice';
import * as AnswersAction from '~/features/Answers/slice';
import * as StudentAction from '~/features/Student/slice';
import * as ScenarioAction from '~/features/Scenario/slice';
import * as SessionAction from '~/features/Session/slice';
import * as FormGroupAction from '~/features/FormGroup/slice';
import * as FormCategoryAction from '~/features/FormCategory/slice';
import Api from '~/api';
import {Link} from '@material-ui/core';
import {
  Form,
  FormGroup,
  ProblemOutcome,
  Scenario,
  ScenarioType,
  Session,
  SessionType,
  Student,
  UserRoleType,
} from '~/types';
import {useHistory, useLocation} from 'react-router-dom';
import GradingProblemModal from '~/modals/GradingProblemModal';
import {USER} from '~/features/User/slice';
import {exportToCSV} from '~/utils';
import axios from 'axios';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  top: {
    padding: '12px 0 12px 24px',
    color: 'rgba(73, 80, 85, 1)',
    borderBottom: '1px solid #DBDEDF',
    cursor: 'default',
  },
  header: {
    width: '90%',
    marginLeft: 'auto',
    color: 'rgba(73, 80, 85, 1)',
    borderBottom: '1px solid #DBDEDF',
    cursor: 'default',
  },
  headerChoice: {
    display: 'inline-block',
    padding: '13px 36px 11px 24px',
  },
  headerClass: {
    display: 'inline-block',
    cursor: 'pointer',
    textDecoration: 'none',
    fontWeight: 600,
  },
  headerClassA: {
    margin: '0 20px 0 0 ',
    padding: '13px 10px 15px 10px',
    color: 'rgba(73, 80, 85, 1)',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: ' rgba(37, 149, 255, 1)',
      borderBottom: '3px solid #2595FF',
      borderRadius: '2px 2px 0px 0px',
    },
  },
  active: {
    color: ' rgba(37, 149, 255, 1)',
    borderBottom: '3px solid #2595FF',
    borderRadius: '2px 2px 0px 0px',
    textDecoration: 'none',
  },

  container: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  menubar: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
    cursor: 'pointer',
  },
  menuSession: {
    color: 'rgba(73, 80, 85, 1)',
    padding: '12px 2px 6px 2px',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'none',
      color: ' rgba(37, 149, 255, 1)',
      borderBottom: '3px solid #2595FF',
      borderRadius: '2px 2px 0px 0px',
    },
  },
  tblContainer: {
    border: 'none',
    boxSizing: 'border-box',
    padding: theme.spacing(2),
    paddingTop: 0,
    marginTop: theme.spacing(2),
    height: 'calc(100vh - 21px - 144px - 57px - 97px)',
    overflow: 'auto',
  },
  table: {
    tableLayout: 'fixed',
    margin: 'auto',
  },
  tblHead: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tooltipLabel: {
    fontSize: 16,
  },
}));

function LinearProgressWithLabel(
  props: JSX.IntrinsicAttributes & LinearProgressProps,
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${_.round(
          props.value ?? 0,
          2,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

type SCENARIO_POS_TYPE = {[key: string]: SessionType[]};
const ADM_PO1: SCENARIO_POS_TYPE = {
  all: [
    SessionType.SUBJECT,
    SessionType.PRE_QUIZ,
    SessionType.ASIS_TOBE,
    SessionType.ADM_PLAN,
    SessionType.ADM_ACTION,
    SessionType.ADM_EXPLORING,
    SessionType.POST_QUIZ,
  ],
};

const ADM_PO4: SCENARIO_POS_TYPE = {
  낙상관리: [
    SessionType.ADM_PLAN,
    SessionType.ADM_ACTION,
    SessionType.ADM_EXPLORING,
  ],
  물품관리: [SessionType.ADM_PLAN, SessionType.ADM_ACTION],
  수혈관리: [SessionType.ADM_PLAN, SessionType.ADM_ACTION],
  퇴원관리: [
    SessionType.ADM_PLAN,
    SessionType.ADM_ACTION,
    SessionType.ADM_EXPLORING,
  ],
  약물관리: [SessionType.ADM_PLAN, SessionType.ADM_ACTION],
};

const ADM_PO5: SCENARIO_POS_TYPE = {
  낙상관리: [
    SessionType.ADM_PLAN,
    SessionType.ADM_ACTION,
    SessionType.ADM_EXPLORING,
  ],
  물품관리: [SessionType.ADM_PLAN, SessionType.ADM_ACTION],
  퇴원관리: [
    SessionType.ADM_PLAN,
    SessionType.ADM_ACTION,
    SessionType.ADM_EXPLORING,
  ],
  약물관리: [SessionType.ADM_PLAN],
};

const ADM_PO7: SCENARIO_POS_TYPE = {
  낙상관리: [SessionType.ADM_PLAN, SessionType.ADM_ACTION],
  수혈관리: [
    SessionType.ADM_PLAN,
    SessionType.ADM_ACTION,
    SessionType.ADM_EXPLORING,
  ],
  약물관리: [SessionType.SUBJECT, SessionType.ADM_PLAN, SessionType.ADM_ACTION],
};

const ADM_PO9: SCENARIO_POS_TYPE = {
  물품관리: [
    SessionType.ADM_PLAN,
    SessionType.ADM_ACTION,
    SessionType.ADM_EXPLORING,
  ],
  퇴원관리: [SessionType.ADM_PLAN, SessionType.ADM_EXPLORING],
  약물관리: [SessionType.ADM_PLAN, SessionType.ADM_EXPLORING],
};

const ADM_PO10: SCENARIO_POS_TYPE = {
  낙상관리: [SessionType.ADM_PLAN, SessionType.ADM_EXPLORING],
  수혈관리: [SessionType.ADM_PLAN, SessionType.ADM_ACTION],
  약물관리: [SessionType.ADM_PLAN, SessionType.ADM_ACTION],
};

const PSY_PO1: SCENARIO_POS_TYPE = {
  all: [
    SessionType.SUBJECT,
    SessionType.PRE_QUIZ,
    SessionType.MSE,
    SessionType.PSY_PROBLEM,
    SessionType.PSY_PLAN,
    SessionType.PSY_INTERVENTION,
    SessionType.PSY_SOAPIE,
    SessionType.POST_QUIZ,
  ],
};

const PSY_PO2: SCENARIO_POS_TYPE = {
  all: [
    SessionType.SUBJECT,
    SessionType.PRE_QUIZ,
    SessionType.PSY_INTERVENTION,
    SessionType.PSY_SOAPIE,
    SessionType.POST_QUIZ,
  ],
};

const PSY_PO3: SCENARIO_POS_TYPE = {
  all: [
    SessionType.SUBJECT,
    SessionType.PRE_QUIZ,
    SessionType.MSE,
    SessionType.PSY_PROBLEM,
    SessionType.PSY_PLAN,
    SessionType.PSY_INTERVENTION,
    SessionType.PSY_SOAPIE,
    SessionType.POST_QUIZ,
  ],
};

const EX_FIELDS = [
  SessionType.INTRO,
  SessionType.SIMULATION,
  SessionType.CHART,
  SessionType.ADM_PLAN_RESULT,
];

const StudentResultScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<{scenarioId?: number; sessionId?: number}>();
  const {scenarioId, sessionId} = location.state;

  const user = useSelector((state: RootState) => state[USER].user);
  const role = useSelector((state: RootState) => state[USER].user?.role.role);
  const formCategories = useSelector(
    (state: RootState) =>
      state[FormCategoryAction.FORMCATEGORIES].formCategories,
  );
  const answers = useSelector(
    (state: RootState) => state[AnswersAction.ANSWER].answers,
  );
  const course = useSelector((state: RootState) => state[COURSE].current);
  const sessions = useSelector((state: RootState) => state[SESSION].sessions);
  const studentsReducer = useSelector(
    (state: RootState) => state[StudentAction.STUDENT].students,
  );
  const scenarios = useSelector(
    (state: RootState) => state[SCENARIO].scenarios,
  );
  const formGroups = useSelector(
    (state: RootState) => state[FormGroupAction.FORMGROUPS].formGroups,
  );
  const fgLoading = useSelector(
    (state: RootState) => state[FormGroupAction.FORMGROUPS].loading,
  );
  const userScores = useSelector(
    (state: RootState) => state[UserScoreAction.USERSCORE].userScores,
  );
  // const [scenarioId, setScenarioId] = useState<number>();
  const [session, setSession] = useState<Session | undefined>(
    _.find(sessions, (sess) => sess.id === sessionId) ??
      _.filter(sessions, (sess) => !_.includes(EX_FIELDS, sess.type))?.[0],
  );
  const [students, setStudents] = useState<Student[]>([]);
  const [formGroup, setFormGroup] = useState<FormGroup[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);
  const [reportStatus, setReportStatus] = useState<string>();
  const classes = useStyles({
    sessions: (session && formGroups[session.type]) ?? [],
  });
  const [loadingPO1, setLoadingPO1] = useState(false);
  const [processedStu, setProcessedStu] = useState(0);

  useEffect(() => {
    const requestReport = async (courseId: number) => {
      try {
        const resp = await axios({
          url: `/reports/${courseId}`,
          method: 'GET',
        });
        console.log('request report', courseId, resp.data.status);
        setReportStatus(resp.data.status);
      } catch (err) {
        setReportStatus('');
      }
    };

    if (course?.id) {
      requestReport(course.id);
    }
  }, [course]);

  useEffect(() => {
    setStudents(studentsReducer);
  }, [studentsReducer]);

  useEffect(() => {
    if (session && scenarioId) {
      dispatch(FormCategoryAction.load({scenarioId, session}));
    } else if (scenarioId) {
      const sess = _.find(
        sessions,
        (s) => s.type === SessionType.PSY_INTERVENTION,
      );
      if (sess) {
        dispatch(FormCategoryAction.load({scenarioId, session: sess}));
      }
    }
  }, [sessions, session, scenarioId]);

  useEffect(() => {
    setSession(_.find(sessions, (sess) => sess.id === sessionId));
  }, [sessions, sessionId]);

  useEffect(() => {
    if (scenarioId && session) {
      if (role === UserRoleType.STUDENT) {
        dispatch(
          AnswersAction.load({
            scenarioId,
            sessionId: session.id,
            userId: user?.id,
          }),
        );
      } else {
        dispatch(AnswersAction.load({scenarioId, sessionId: session.id}));
      }
      dispatch(UserScoreAction.load({scenarioId, sessionId: session.id}));
    } else if (scenarioId) {
      dispatch(UserScoreAction.load({scenarioId}));
    } else {
      dispatch(UserScoreAction.load({}));
    }
  }, [dispatch, scenarioId, session]);

  useEffect(() => {
    if (role === UserRoleType.STUDENT) {
      const student = user?.student;
      if (student) {
        setStudents([
          {
            ...student,
            user: user,
          },
        ]);
      }
    } else {
      dispatch(StudentAction.load());
    }
  }, [dispatch]);

  useEffect(() => {
    if (course) {
      dispatch(ScenarioAction.load(course.id));
    }
  }, [dispatch, course]);

  useEffect(() => {
    if (scenarioId) {
      dispatch(SessionAction.load(scenarioId));
    }
  }, [dispatch, scenarioId]);

  useEffect(() => {
    if (scenarioId && session) {
      dispatch(FormGroupAction.load({scenarioId, session}));
    }
  }, [dispatch, scenarioId, session]);

  const updateSessions = useCallback(
    (scenId: number | undefined) => {
      history.push(`/result/${course?.id}`, {scenarioId: scenId});
    },
    [history, course],
  );

  const handleGrading = (userId?: number) => {
    if (userId) {
      history.push(`/result/${course?.id}/grading`, {
        userId,
        scenarioId,
        sessionId: session?.id,
      });
    }
  };

  const renderHeader = useCallback(() => {
    return (
      <div key={`screen-진도확인`}>
        <div className={classes.top}>
          <Typography variant="h6">성적확인</Typography>
        </div>
        <div className={classes.header}>
          <Typography variant="h6" className={classes.headerChoice}>
            시나리오 선택
          </Typography>
          <Typography variant="body2" className={classes.headerClass}>
            <Link
              className={`${classes.headerClassA} ${
                !scenarioId ? classes.active : ''
              }`}
              onClick={() => {
                updateSessions(undefined);
                setSession(undefined);
              }}>
              종합성적확인
            </Link>
            {_.map(scenarios, (scenario) => {
              return (
                <Link
                  className={`${classes.headerClassA} ${
                    scenarioId === scenario.id ? classes.active : ''
                  }`}
                  key={`scenario-${scenario.id}`}
                  onClick={() => {
                    updateSessions(scenario.id);
                    setSession(sessions?.[0]);
                  }}>
                  {scenario.name}
                </Link>
              );
            })}
          </Typography>
        </div>
      </div>
    );
  }, [scenarios, scenarioId, updateSessions]);

  const renderTable = useCallback(() => {
    // const sesss = _.filter(sessions, (s) => !_.includes(EX_FIELDS, s.type));
    const data =
      session && !fgLoading
        ? _.chain(students)
            .map((stu) => {
              if (
                !_.includes(
                  [
                    ...EX_FIELDS,
                    SessionType.PRE_QUIZ,
                    SessionType.POST_QUIZ,
                    SessionType.ADM_PLAN,
                    SessionType.PSY_PROBLEM,
                    SessionType.PSY_PLAN,
                    SessionType.PSY_INTERVENTION,
                    SessionType.MSE,
                  ],
                  session.type,
                )
              ) {
                return {
                  stu: stu,
                  학번: stu.studentId,
                  이름: stu.name,
                  ...renderScoreResult(formGroups[session.type], stu),
                } as {[key: string]: any};
              }

              if (
                _.includes(
                  [
                    SessionType.ADM_PLAN,
                    SessionType.PSY_PLAN,
                    SessionType.PSY_PROBLEM,
                  ],
                  session.type,
                )
              ) {
                return {
                  stu: stu,
                  학번: stu.studentId,
                  이름: stu.name,
                  ...renderAmdPlanResult(formGroups[session.type], stu),
                } as {[key: string]: any};
              }

              if (
                _.includes(
                  [SessionType.PRE_QUIZ, SessionType.POST_QUIZ],
                  session.type,
                )
              ) {
                return {
                  stu: stu,
                  학번: stu.studentId,
                  이름: stu.name,
                  ...renderPrePostResult(formGroups[session.type], stu),
                } as {[key: string]: any};
              }

              if (_.includes([SessionType.MSE], session.type)) {
                return {
                  stu: stu,
                  학번: stu.studentId,
                  이름: stu.name,
                  ...renderMSEResult(formGroups[session.type], stu),
                } as {[key: string]: any};
              }

              if (_.includes([SessionType.PSY_INTERVENTION], session.type)) {
                return {
                  stu: stu,
                  학번: stu.studentId,
                  이름: stu.name,
                  ...renderPsyIntervResult(stu),
                } as {[key: string]: any};
              }
            })
            .compact()
            .value()
        : [];

    return (
      <div style={{display: 'flex', flexDirection: 'column', paddingTop: 4}}>
        {fgLoading ? <LinearProgress /> : undefined}
        {role !== UserRoleType.STUDENT && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0 16px',
            }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                const scenario = _.find(
                  scenarios,
                  (scen) => scen.id === scenarioId,
                );
                const newD = _.map(data, (d) => {
                  const {stu, ...restD} = d;
                  return restD;
                });
                session &&
                  exportToCSV(
                    {[session.name.replace(/\\r\\n/g, ' ')]: newD},
                    `${scenario?.name?.replace(
                      /\\r\\n/g,
                      ' ',
                    )}_${session.name.replace(/\\r\\n/g, ' ')}`,
                  );
              }}>
              엑셀 다운로드
            </Button>
          </div>
        )}

        <TableContainer className={classes.tblContainer}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tblHead}>학번</TableCell>
                <TableCell className={classes.tblHead}>이름</TableCell>
                {session &&
                  !fgLoading &&
                  !_.includes(
                    [
                      SessionType.PSY_INTERVENTION,
                      SessionType.MSE,
                      SessionType.PRE_QUIZ,
                      SessionType.POST_QUIZ,
                    ],
                    session.type,
                  ) &&
                  _.map(formGroups[session?.type], (fg) => (
                    <TableCell
                      key={`table-header-${fg.id}`}
                      className={classes.tblHead}>
                      <Tooltip
                        title={fg.name}
                        classes={{tooltip: classes.tooltipLabel}}
                        placement="top">
                        <div>{fg.name}</div>
                      </Tooltip>
                    </TableCell>
                  ))}
                {session &&
                  !fgLoading &&
                  _.includes(
                    [SessionType.PRE_QUIZ, SessionType.POST_QUIZ],
                    session.type,
                  ) && (
                    <>
                      <TableCell
                        key={`table-header-pre-post-sum`}
                        className={classes.tblHead}>
                        <div>합계</div>
                      </TableCell>
                      {_.map(formGroups[session?.type], (fg) => (
                        <TableCell
                          key={`table-header-${fg.id}`}
                          className={classes.tblHead}>
                          <Tooltip
                            title={fg.name}
                            classes={{tooltip: classes.tooltipLabel}}
                            placement="top">
                            <div>{fg.name}</div>
                          </Tooltip>
                        </TableCell>
                      ))}
                    </>
                  )}
                {session &&
                  !fgLoading &&
                  _.includes([SessionType.PSY_INTERVENTION], session.type) &&
                  _.chain(formCategories)
                    .map((fc) =>
                      _.map(fc.formGroups, (fg) => ({id: fc.id, fg})),
                    )
                    .flattenDeep()
                    .map((fc) => {
                      const fg = fc.fg;
                      return (
                        <TableCell
                          key={`table-header-psy-interv-${fc.id}-${fg.id}`}
                          className={classes.tblHead}>
                          <Tooltip
                            title={fg.name}
                            classes={{tooltip: classes.tooltipLabel}}
                            placement="top">
                            <div>{fg.name}</div>
                          </Tooltip>
                        </TableCell>
                      );
                    })
                    .value()}
                {session &&
                  !fgLoading &&
                  _.includes([SessionType.MSE], session.type) && (
                    <TableCell
                      key={`table-header-mse-sum`}
                      className={classes.tblHead}>
                      <div>합계</div>
                    </TableCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(data, (d, indx) => (
                <TableRow
                  onClick={() => {
                    if (role === UserRoleType.STUDENT) {
                      return;
                    }
                    if (
                      session &&
                      !_.includes(
                        [
                          ...EX_FIELDS,
                          SessionType.PRE_QUIZ,
                          SessionType.POST_QUIZ,
                          SessionType.ADM_PLAN,
                          SessionType.PSY_PROBLEM,
                          SessionType.PSY_PLAN,
                          SessionType.MSE,
                        ],
                        session.type,
                      )
                    ) {
                      handleGrading(d.stu.user?.id);
                    } else if (
                      session &&
                      _.includes(
                        [
                          SessionType.PRE_QUIZ,
                          SessionType.POST_QUIZ,
                          SessionType.PSY_PLAN,
                          SessionType.PSY_PROBLEM,
                          SessionType.MSE,
                          SessionType.ADM_PLAN,
                        ],
                        session.type,
                      )
                    ) {
                      console.log('find forms...');
                      const fgs = _.map(formGroups[session.type], (fg) => {
                        const ans = _.chain(answers)
                          .filter((a) => a.userId === d.stu.user?.id)
                          .value();
                        const fgAns = _.chain(ans)
                          .filter((a) => a.formGroupId === fg.id)
                          .first()
                          .value();
                        return {
                          ...fg,
                          uanswer: fgAns,
                          forms: _.map(fg?.forms, (f) => {
                            const formAns = _.find(
                              ans,
                              (a) =>
                                a.formId === f.id && fg?.id === a.formGroupId,
                            );
                            return {
                              ...f,
                              answers: formAns ? [formAns] : [],
                            } as Form;
                          }),
                        } as FormGroup;
                      });
                      console.log(fgs);
                      setFormGroup(fgs);
                      setOpenModal(true);
                    }
                  }}
                  key={`tbl-body-student-${d['학번']}-${indx}`}
                  hover>
                  {_.keys(d)
                    .filter((key) => key !== 'stu')
                    .map((key) => (
                      <TableCell
                        key={`tbl-cell-student-${d['학번']}-${key}-${indx}`}>
                        {d[key]}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }, [session, formGroups, students, fgLoading]);

  const getCourseTotal = useCallback(
    (stu: Student, scen: Scenario) => {
      return _.chain(userScores)
        .filter((us) => us.userId === stu.user?.id && us.scenarioId === scen.id)
        .sumBy('score')
        .value();
    },
    [userScores],
  );

  const getPOScore = useCallback(
    async (
      prefix: string,
      data: any[],
      fgs: any[],
      scenario: Scenario,
      stu: Student,
      PSY_PO1: SessionType[],
    ) => {
      const sessIds = _.filter(data, (sess) =>
        _.includes(PSY_PO1, sess.type),
      ).map((sess) => sess.id);
      let totalScores = _.chain(fgs)
        .map((f) => f.scores?.[0]?.score ?? 0)
        .sum()
        .value();
      let score = 0;
      const sc = _.filter(
        userScores,
        (s) =>
          s.scenarioId === scenario.id &&
          _.includes(sessIds, s.sessionId) &&
          s.userId === stu.user?.id,
      );

      score = score + _.sumBy(sc, (s) => s.score);
      if (_.includes(PSY_PO1, SessionType.MSE)) {
        const mseSess = _.find(data, (sess) => sess.type === SessionType.MSE);
        const filter = {
          where: {
            courseId: {
              eq: course?.id,
            },
            scenarioId: {
              eq: scenario.id,
            },
            sessionId: {
              eq: mseSess.id,
            },
            userId: {
              eq: stu.user?.id,
            },
          },
        };
        const {data: ans} = await axios.get(
          `/answers?filter=${JSON.stringify(filter)}`,
        );
        const mseFgs = _.filter(
          fgs,
          (fg) => fg.sessionId === mseSess.id && fg.type !== 'subjective',
        );

        const mseScore = _.chain(mseFgs)
          .map((fg) => {
            const fgAns = _.chain(ans)
              .filter((a) => a.formGroupId === fg.id)
              .map((a) => {
                if (fg.type === 'multiple') {
                  if (a.content === 'true') {
                    return a.formId;
                  }
                } else {
                  return Number.parseInt(a.content);
                }
              })
              .compact()
              .value();
            if (fg.answers === null || fg.answers?.length === 0) {
              if (fgAns.length === 0) {
                return fg.scores?.[0]?.score || 0;
              } else {
                return 0;
              }
            } else {
              if (
                _.intersection(fg.answers, fgAns).length === fg.answers?.length
              ) {
                return fg.scores?.[0]?.score || 0;
              } else {
                return 0;
              }
            }
          })
          .sum()
          .value();
        // console.log('mseScore', mseScore);
        score = score + mseScore;
      }
      if (_.includes(PSY_PO1, SessionType.PRE_QUIZ)) {
        const preSess = _.find(
          data,
          (sess) => sess.type === SessionType.PRE_QUIZ,
        );
        const filter = {
          where: {
            courseId: {
              eq: course?.id,
            },
            scenarioId: {
              eq: scenario.id,
            },
            sessionId: {
              eq: preSess.id,
            },
            userId: {
              eq: stu.user?.id,
            },
          },
        };

        const {data: ans} = await axios.get(
          `/answers?filter=${JSON.stringify(filter)}`,
        );
        const mseFgs = _.filter(fgs, (fg) => fg.sessionId === preSess.id);

        const result = _.map(mseFgs, (fg) => {
          const fgAns = _.chain(ans)
            .filter((a) => a.formGroupId === fg.id)
            .first()
            .value();
          return {
            [fg.name]:
              fgAns && _.includes(fg.answers, Number.parseInt(fgAns.content)),
          };
        });
        totalScores += mseFgs.length;
        score += _.chain(result)
          .map((r) => _.values(r))
          .flattenDeep()
          .compact()
          .value().length;
        // console.log(mseFgs, result);
      }

      if (_.includes(PSY_PO1, SessionType.POST_QUIZ)) {
        const preSess = _.find(
          data,
          (sess) => sess.type === SessionType.POST_QUIZ,
        );
        const filter = {
          where: {
            courseId: {
              eq: course?.id,
            },
            scenarioId: {
              eq: scenario.id,
            },
            sessionId: {
              eq: preSess.id,
            },
            userId: {
              eq: stu.user?.id,
            },
          },
        };

        const {data: ans} = await axios.get(
          `/answers?filter=${JSON.stringify(filter)}`,
        );
        const mseFgs = _.filter(fgs, (fg) => fg.sessionId === preSess.id);

        const result = _.map(mseFgs, (fg) => {
          const fgAns = _.chain(ans)
            .filter((a) => a.formGroupId === fg.id)
            .first()
            .value();
          return {
            [fg.name]:
              fgAns && _.includes(fg.answers, Number.parseInt(fgAns.content)),
          };
        });
        totalScores += mseFgs.length;
        score += _.chain(result)
          .map((r) => _.values(r))
          .flattenDeep()
          .compact()
          .value().length;
      }
      let psyProblem: 'P' | 'F' | undefined = undefined;
      if (_.includes(PSY_PO1, SessionType.PSY_PROBLEM)) {
        const preSess = _.find(
          data,
          (sess) => sess.type === SessionType.PSY_PROBLEM,
        );
        const mseFgs = _.filter(fgs, (fg) => fg.sessionId === preSess.id);
        const fg = _.chain(mseFgs?.[0]?.randomFormSets)
          .filter(
            (fs) =>
              fs.scenarioId === scenario.id &&
              fs.sessionId === preSess.id &&
              fs.userId === stu.user?.id,
          )
          .maxBy((o) => o.trial)
          .value();
        psyProblem = fg && fg.passed ? 'P' : 'F';
      }

      let psyPlan: 'P' | 'F' | undefined = undefined;
      if (_.includes(PSY_PO1, SessionType.PSY_PLAN)) {
        const preSess = _.find(
          data,
          (sess) => sess.type === SessionType.PSY_PLAN,
        );
        const mseFgs = _.filter(fgs, (fg) => fg.sessionId === preSess.id);
        const fg = _.chain(mseFgs?.[0]?.randomFormSets)
          .filter(
            (fs) =>
              fs.scenarioId === scenario.id &&
              fs.sessionId === preSess.id &&
              fs.userId === stu.user?.id,
          )
          .maxBy((o) => o.trial)
          .value();
        psyPlan = fg && fg.passed ? 'P' : 'F';
      }

      let asIsToBe: 'P' | 'F' | undefined = undefined;
      if (_.includes(PSY_PO1, SessionType.ASIS_TOBE)) {
        const preSess = _.find(
          data,
          (sess) => sess.type === SessionType.ASIS_TOBE,
        );
        const mseFgs = _.filter(fgs, (fg) => fg.sessionId === preSess.id);
        const fg = _.chain(mseFgs?.[0]?.randomFormSets)
          .filter(
            (fs) =>
              fs.scenarioId === scenario.id &&
              fs.sessionId === preSess.id &&
              fs.userId === stu.user?.id,
          )
          .maxBy((o) => o.trial)
          .value();
        psyPlan = fg && fg.passed ? 'P' : 'F';
      }

      let admPlan: 'P' | 'F' | undefined = undefined;
      if (_.includes(PSY_PO1, SessionType.ADM_PLAN)) {
        const preSess = _.find(
          data,
          (sess) => sess.type === SessionType.ADM_PLAN,
        );
        const mseFgs = _.filter(fgs, (fg) => fg.sessionId === preSess.id);
        const fg = _.chain(mseFgs?.[0]?.randomFormSets)
          .filter(
            (fs) =>
              fs.scenarioId === scenario.id &&
              fs.sessionId === preSess.id &&
              fs.userId === stu.user?.id,
          )
          .maxBy((o) => o.trial)
          .value();
        psyPlan = fg && fg.passed ? 'P' : 'F';
      }
      return {
        이름: stu.name,
        학번: stu.studentId,
        [`${prefix} - 총점`]: totalScores,
        [`${prefix} - 학생점수`]: score,
        ...(psyProblem ? {[`${prefix} - 간호문제`]: psyProblem} : {}),
        ...(psyPlan ? {[`${prefix} - 간호계획`]: psyPlan} : {}),
        ...(asIsToBe ? {[`${prefix} - AsIsToBe`]: asIsToBe} : {}),
        ...(admPlan ? {[`${prefix} - 간호관리활동 계획수립`]: admPlan} : {}),
      };
    },
    [userScores],
  );

  const handlePO1Download = useCallback(
    async (SCEN_POS: {[key: string]: {[key: string]: SessionType[]}}) => {
      setLoadingPO1(true);
      setProcessedStu(0);

      const wb = XLSX.utils.book_new();
      for await (let scenario of scenarios) {
        // const scenario = scenarios[0];
        const {data} = await axios.get(
          `/scenarios/${scenario.id}/sessions?filter=${JSON.stringify({
            where: {courseId: course?.id},
          })}`,
        );

        let result = await _.reduce(
          students,
          async (promise, stu, idx) => {
            const res_stu = await promise.then();
            console.log(
              new Date(),
              'generating po1 for student',
              idx,
              stu.name,
              stu.user,
            );
            setProcessedStu((p) => p + 1);
            if (stu.user) {
              const res_stt = await _.reduce(
                SCEN_POS,
                // POS,
                async (promise, POS, key) => {
                  const result = await promise.then();

                  let value: SessionType[] = [];
                  if (_.has(POS, 'all')) {
                    value = POS['all'];
                  } else if (_.has(POS, scenario.name)) {
                    value = POS[scenario.name];
                  } else {
                    return {...result};
                  }

                  // let fgs1 = await _.reduce(
                  //   data,
                  //   async (promise, sess) => {
                  //     const res = await promise.then();
                  //     if (_.includes(value, sess.type)) {
                  //       const resp2 = await axios.get(
                  //         `/scenarios/${scenario.id}/form-groups?courseId=${course?.id}&sessionId=${sess.id}`,
                  //       );
                  //       return [
                  //         ...res,
                  //         _.map(resp2.data, (d) => ({
                  //           ...d,
                  //           sessionId: sess.id,
                  //         })),
                  //       ];
                  //     }
                  //     return [...res];
                  //   },
                  //   Promise.resolve<any[]>([]),
                  // );
                  let fgs1 = await Promise.all(
                    _.map(data, async (sess) => {
                      if (_.includes(value, sess.type)) {
                        const resp2 = await axios.get(
                          `/scenarios/${scenario.id}/form-groups?courseId=${course?.id}&sessionId=${sess.id}`,
                        );
                        return [
                          _.map(resp2.data, (d) => ({
                            ...d,
                            sessionId: sess.id,
                          })),
                        ];
                      }
                      return [];
                    }),
                  );
                  let fgs = _.flattenDeep(fgs1);

                  return {
                    ...result,
                    // ...(await getPOScore(key, data, fgs, scenario, stu, POS)),
                    ...(await getPOScore(key, data, fgs, scenario, stu, value)),
                  };
                },
                Promise.resolve({}),
              );
              return [...res_stu, res_stt];
            }
            return res_stu;
          },
          Promise.resolve<any[]>([]),
        );

        result = _.filter(result, (r) => _.keys(r).length > 0);

        if (result.length > 0) {
          const ws = XLSX.utils.json_to_sheet(result);
          XLSX.utils.book_append_sheet(wb, ws, `${scenario.name}_채점결과`);
        }
      }
      const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
      const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      FileSaver.saveAs(blob, `${_.keys(SCEN_POS).join('_')}_채점결과.xlsx`);

      setLoadingPO1(false);
    },
    [scenarios, course, students],
  );

  const poDownloadBtnMarkup = useMemo(() => {
    if (course?.type === ScenarioType.PSY) {
      return (
        <>
          {loadingPO1 && (
            <div style={{flex: 1}}>
              <LinearProgressWithLabel
                value={
                  (processedStu / (students.length * scenarios.length)) * 100
                }
              />
            </div>
          )}

          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              margin: '0 8px',
            }}
            disabled={loadingPO1}
            onClick={() => handlePO1Download({PO1: PSY_PO1})}>
            PO1 다운로드
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loadingPO1}
            style={{
              margin: '0 8px',
            }}
            onClick={() => handlePO1Download({PO2: PSY_PO2})}>
            PO2 다운로드
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loadingPO1}
            style={{
              margin: '0 8px',
            }}
            onClick={() => handlePO1Download({PO3: PSY_PO3})}>
            PO3 다운로드
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loadingPO1}
            style={{
              margin: '0 8px',
            }}
            onClick={() =>
              handlePO1Download({
                PO1: PSY_PO1,
                PO2: PSY_PO2,
                PO3: PSY_PO3,
              })
            }>
            PO별 다운로드
          </Button> */}
        </>
      );
    } else if (course?.type === ScenarioType.ADM) {
      return (
        <>
          {loadingPO1 && (
            <div style={{flex: 1}}>
              <LinearProgressWithLabel
                value={
                  (processedStu / (students.length * scenarios.length)) * 100
                }
              />
            </div>
          )}
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              margin: '0 8px',
            }}
            disabled={loadingPO1}
            onClick={() => handlePO1Download({PO1: ADM_PO1})}>
            PO1 다운로드
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loadingPO1}
            style={{
              margin: '0 8px',
            }}
            onClick={() => handlePO1Download({PO4: ADM_PO4})}>
            PO4 다운로드
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loadingPO1}
            style={{
              margin: '0 8px',
            }}
            onClick={() => handlePO1Download({PO5: ADM_PO5})}>
            PO5 다운로드
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loadingPO1}
            style={{
              margin: '0 8px',
            }}
            onClick={() => handlePO1Download({PO7: ADM_PO7})}>
            PO7 다운로드
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loadingPO1}
            style={{
              margin: '0 8px',
            }}
            onClick={() => handlePO1Download({PO9: ADM_PO9})}>
            PO9 다운로드
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loadingPO1}
            style={{
              margin: '0 8px',
            }}
            onClick={() => handlePO1Download({PO10: ADM_PO10})}>
            PO10 다운로드
          </Button>
          {/* <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loadingPO1}
            style={{
              margin: '0 8px',
            }}
            onClick={() =>
              handlePO1Download({
                PO1: ADM_PO1,
                PO4: ADM_PO4,
                PO5: ADM_PO5,
                PO7: ADM_PO7,
                PO9: ADM_PO9,
                PO10: ADM_PO10,
              })
            }>
            PO별 다운로드
          </Button> */}
        </>
      );
    }
  }, [
    course,
    scenarios,
    students,
    processedStu,
    handlePO1Download,
    loadingPO1,
  ]);

  const renderCourseTable = useCallback(() => {
    const data = _.map(students, (stu) => {
      return {
        학번: stu.studentId,
        이름: stu.name,
        ..._.reduce(
          scenarios,
          (result, scen) => {
            return {...result, [scen.name]: getCourseTotal(stu, scen)};
          },
          {},
        ),
      };
    }) as {[key: string]: any};
    return (
      <>
        {role !== UserRoleType.STUDENT && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            {poDownloadBtnMarkup}
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => exportToCSV({종합성적: data}, '종합성적')}
              style={{
                margin: '0 8px',
              }}>
              엑셀 다운로드
            </Button>
          </div>
        )}
        <TableContainer className={classes.tblContainer}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tblHead}>학번</TableCell>
                <TableCell className={classes.tblHead}>이름</TableCell>
                {_.map(scenarios, (scen) => (
                  <TableCell
                    key={`table-header-total-${scen.name}`}
                    className={classes.tblHead}>
                    <Tooltip
                      title={scen.name.replace(/\\r\\n/g, ' ')}
                      classes={{tooltip: classes.tooltipLabel}}
                      placement="top">
                      <div>{scen.name.replace(/\\r\\n/g, ' ')}</div>
                    </Tooltip>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(data, (d, indx) => (
                <TableRow key={`tbl-body-student-${d['학번']}-${indx}`} hover>
                  <TableCell>{d['학번']}</TableCell>
                  <TableCell>{d['이름']}</TableCell>
                  {_.map(scenarios, (scen) => (
                    <TableCell
                      key={`tbl-body-total-${d['학번']}-${scen.name}-${indx}`}>
                      {d[scen.name]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }, [scenarios, students, poDownloadBtnMarkup]);

  const getSessionTotal = useCallback(
    (sess: Session, stu: Student) => {
      // if (stu.studentId === '14010348') {
      //   console.log(
      //     sess,
      //     stu,
      //     userScores,
      //     _.chain(userScores)
      //       .filter(
      //         (us) =>
      //           us.userId === stu.user?.id &&
      //           us.scenarioId === scenarioId &&
      //           us.sessionId === sess.id,
      //       )
      //       .value(),
      //   );
      // }

      return _.chain(userScores)
        .filter(
          (us) =>
            us.userId === stu.user?.id &&
            us.scenarioId === scenarioId &&
            us.sessionId === sess.id,
        )
        .sumBy('score')
        .value();
    },
    [userScores, scenarioId],
  );

  const renderScenarioTable = useCallback(() => {
    const scenario = _.find(scenarios, (scen) => scen.id === scenarioId);
    // console.log('renderScenarioTable', scenario);
    const data = _.map(students, (stu) => {
      return {
        학번: stu.studentId,
        이름: stu.name,
        ..._.chain(sessions)
          .filter(
            (sess) =>
              !_.includes(
                [
                  ...EX_FIELDS,
                  SessionType.PRE_QUIZ,
                  SessionType.POST_QUIZ,
                  SessionType.PSY_PROBLEM,
                  SessionType.PSY_PLAN,
                  SessionType.MSE,
                ],
                sess.type,
              ),
          )
          .reduce((result, sess) => {
            return {
              ...result,
              [sess.name.replace(/\\r\\n/g, ' ')]: getSessionTotal(sess, stu),
            };
          }, {})
          .value(),
      };
    }) as {[key: string]: any};

    return (
      <>
        {role !== UserRoleType.STUDENT && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '0 16px',
            }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() =>
                exportToCSV({시나리오종합: data}, `${scenario?.name}`)
              }>
              엑셀 다운로드
            </Button>
          </div>
        )}
        <TableContainer className={classes.tblContainer}>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tblHead}>학번</TableCell>
                <TableCell className={classes.tblHead}>이름</TableCell>
                {sessions &&
                  _.chain(sessions)
                    .filter(
                      (sess) =>
                        !_.includes(
                          [
                            ...EX_FIELDS,
                            SessionType.PRE_QUIZ,
                            SessionType.POST_QUIZ,
                            SessionType.PSY_PROBLEM,
                            SessionType.PSY_PLAN,
                            SessionType.MSE,
                          ],
                          sess.type,
                        ),
                    )
                    .map((sess) => (
                      <TableCell
                        key={`table-header-total-${sess.name}`}
                        className={classes.tblHead}>
                        <Tooltip
                          title={sess.name.replace(/\\r\\n/g, ' ')}
                          classes={{tooltip: classes.tooltipLabel}}
                          placement="top">
                          <div>{sess.name.replace(/\\r\\n/g, ' ')}</div>
                        </Tooltip>
                      </TableCell>
                    ))
                    .value()}
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(data, (d, indx) => (
                <TableRow key={`tbl-body-student-${d['학번']}-${indx}`} hover>
                  <TableCell>{d['학번']}</TableCell>
                  <TableCell>{d['이름']}</TableCell>
                  {sessions &&
                    _.chain(sessions)
                      .filter(
                        (sess) =>
                          !_.includes(
                            [
                              ...EX_FIELDS,
                              SessionType.PRE_QUIZ,
                              SessionType.POST_QUIZ,
                              SessionType.PSY_PROBLEM,
                              SessionType.PSY_PLAN,
                              SessionType.MSE,
                            ],
                            sess.type,
                          ),
                      )
                      .map((sess) => (
                        <TableCell
                          key={`tbl-cell-total-score-${sess.id}-${d['학번']}-${indx}`}>
                          {d[sess.name.replace(/\\r\\n/g, ' ')]}
                        </TableCell>
                      ))
                      .value()}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }, [getSessionTotal, role, sessions, scenarios, scenarioId, students]);

  const renderPsyIntervResult = useCallback(
    (stu: Student) => {
      return _.chain(formCategories)
        .map((fc) =>
          _.map(fc.formGroups, (fg) => ({id: fc.id, name: fc.name, fg})),
        )
        .flattenDeep()
        .reduce((result, fc) => {
          const fg = fc.fg;
          const score = _.find(
            userScores,
            (us) => us.formGroupId === fg.id && us.userId === stu.user?.id,
          );

          return {
            ...result,
            [`${fc.name}-${fg.name}`]: score?.score ?? '-',
          };
        }, {})
        .value();
    },
    [formCategories, userScores],
  );

  const renderScoreResult = useCallback(
    (fgs: FormGroup[], stu: Student) => {
      return _.reduce(
        fgs,
        (result, fg) => {
          const score = _.find(
            userScores,
            (us) => us.formGroupId === fg.id && us.userId === stu.user?.id,
          );
          return {...result, [fg.name]: score?.score ?? '-'};
        },
        {},
      );
    },
    [userScores],
  );

  const renderAmdPlanResult = useCallback(
    (fgs: FormGroup[], stu: Student) => {
      // const ans = _.chain(answers)
      //   .filter((a) => a.userId === stu.user?.id)
      //   .value();
      // console.log(
      //   stu,
      //   _.chain(fgs?.[0]?.randomFormSets)
      //     .filter(
      //       (fs) =>
      //         fs.scenarioId === scenarioId &&
      //         fs.sessionId === sessionId &&
      //         fs.userId === stu.user?.id &&
      //         fs.trial === 1,
      //     )
      //     .value(),
      //   _.chain(fgs?.[0]?.randomFormSets)
      //     .filter(
      //       (fs) =>
      //         fs.scenarioId === scenarioId &&
      //         fs.sessionId === sessionId &&
      //         fs.userId === stu.user?.id &&
      //         fs.trial === 2,
      //     )
      //     .value(),
      //   _.chain(fgs?.[0]?.randomFormSets)
      //     .filter(
      //       (fs) =>
      //         fs.scenarioId === scenarioId &&
      //         fs.sessionId === sessionId &&
      //         fs.userId === stu.user?.id &&
      //         fs.trial === 3,
      //     )
      //     .value(),
      // );
      const fg = _.chain(fgs?.[0]?.randomFormSets)
        .filter(
          (fs) =>
            fs.scenarioId === scenarioId &&
            fs.sessionId === sessionId &&
            fs.userId === stu.user?.id,
        )
        .maxBy((o) => o.trial)
        .value();

      const name = fgs?.[0].name ?? 'Unknown';

      return {
        [name]: fg && fg.passed ? 'P' : 'F',
      };
    },
    [answers, scenarioId, sessionId],
  );

  const renderPrePostResult = useCallback(
    (fgs: FormGroup[], stu: Student) => {
      const ans = _.chain(answers)
        .filter((a) => a.userId === stu.user?.id)
        .value();

      const result = _.map(fgs, (fg) => {
        const fgAns = _.chain(ans)
          .filter((a) => a.formGroupId === fg.id)
          .first()
          .value();
        return {
          [fg.name]:
            fgAns && _.includes(fg.answers, Number.parseInt(fgAns.content)),
        };
      });

      return _.reduce(
        result,
        (res, ret) => {
          return {
            ...res,
            ..._.transform(
              ret,
              (res2, value, key) => {
                res2[key] = value ? 'O' : 'X';
              },
              {} as {[key: string]: any},
            ),
          };
        },
        {
          합계: `${
            _.chain(result)
              .map((r) => _.values(r))
              .flattenDeep()
              .compact()
              .value().length
          }/${result.length}`,
        },
      );
    },
    [answers],
  );

  const renderMSEResult = useCallback(
    (fgs: FormGroup[], stu: Student) => {
      console.log('---------------------------------------', stu);
      const ans = _.chain(answers)
        .filter((a) => a.userId === stu.user?.id)
        .value();

      const forms = _.chain(fgs)
        .filter((fg) => fg.type !== 'subjective')
        .value();

      const corrects = _.chain(forms)
        .map((fg) => {
          const fgAns = _.chain(ans)
            .filter((a) => a.formGroupId === fg.id)
            .map((a) => {
              if (fg.type === 'multiple') {
                if (a.content === 'true') {
                  return a.formId;
                }
              } else {
                return Number.parseInt(a.content);
              }
            })
            .compact()
            .value();

          if (fg.answers === null || fg.answers?.length === 0) {
            return fgAns.length === 0;
          } else {
            return (
              _.intersection(fg.answers, fgAns).length === fg.answers?.length
            );
          }
        })
        .compact()
        .value();

      return {합계: `${corrects.length}/${forms.length}`};
    },
    [answers],
  );

  const createTotalReport = useCallback(async () => {
    const resp = await axios({
      url: `/reports/${course?.id}`,
      method: 'POST',
    });
    setLoadingReports(false);
    setReportStatus(resp.data.status);
    console.log('dowloadTotalReport', resp);
  }, [course]);

  const downloadTotalReport = useCallback(async () => {
    const resp = await axios({
      url: `/reports/${course?.id}/download`,
      method: 'GET',
    });
    setLoadingReports(false);
    // setReportStatus(resp.data.status);
    const link = document.createElement('a');
    link.href = resp.data.url;

    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [course]);

  return (
    <div>
      <div>{renderHeader()}</div>
      {loadingReports && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'gray',
            opacity: 0.7,
            zIndex: 999,
          }}>
          <CircularProgress size={24} color="primary" />
        </div>
      )}
      {!scenarioId && !session && (
        <div style={{padding: 16, display: 'flex', justifyContent: 'flex-end'}}>
          {reportStatus === 'done' && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{marginRight: 16}}
              onClick={() => {
                setLoadingReports(true);
                downloadTotalReport();
              }}>
              학생교육 결과 보고서 다운로드
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={reportStatus === undefined || reportStatus === 'creating'}
            onClick={() => {
              setLoadingReports(true);
              createTotalReport();
            }}>
            학생교육 결과 보고서 {reportStatus === 'done' && '재'}생성
            {reportStatus === 'creating' && '중'}
          </Button>
        </div>
      )}

      {scenarioId && (
        <div className={classes.container}>
          <div className={classes.menubar}>
            <Link
              className={`${classes.menuSession} ${
                session === undefined ? classes.active : ''
              }`}
              key={`link-000`}
              onClick={() =>
                history.push(`/result/${course?.id}`, {
                  scenarioId,
                })
              }>
              시나리오종합
            </Link>
            {_.filter(
              sessions,
              (sess) => !_.includes(EX_FIELDS, sess.type),
            ).map((sess) => (
              <Link
                className={`${classes.menuSession} ${
                  session?.id === sess.id ? classes.active : ''
                }`}
                key={`link-${sess.id}`}
                onClick={() =>
                  history.push(`/result/${course?.id}`, {
                    scenarioId,
                    sessionId: sess.id,
                  })
                }>
                {sess.name.replace(/\\r\\n/g, ' ')}
              </Link>
            ))}
          </div>
        </div>
      )}
      {!scenarioId && !session && renderCourseTable()}
      {scenarioId && !session && renderScenarioTable()}
      {scenarioId && session && renderTable()}
      <GradingProblemModal
        open={openModal}
        problems={formGroup}
        session={session}
        onClose={() => setOpenModal(false)}
      />
    </div>
  );
};

export default StudentResultScreen;
