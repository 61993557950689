import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {PasswordFindType} from '~/types';
import {useDispatch} from 'react-redux';
import {reduxForm, InjectedFormProps, Field} from 'redux-form';
import {Button, styled} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import {renderTextField} from './common';
import {closePasswordModal} from '~/features/Modal/slice';
const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    paddingLeft: 24,
    paddingRight: 26,
    marginTop: 15,
  },
  BtnWrap: {
    position: 'fixed',
    top: 116,
    left: 24,
  },
  reset: {
    width: 69,
    height: 32,
    left: 132,
  },
  submit: {
    width: 73,
    height: 32,
    left: 140,
    backgroundColor: '#2595FF',
  },
}));

const StyledButton = styled(Button)({
  textTransform: 'none',
  borderRadius: 2,
});

const PasswordFind = (props: InjectedFormProps<PasswordFindType>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {handleSubmit} = props;

  const handleClose = () => {
    dispatch(closePasswordModal());
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div>
        <Field
          onClose={handleClose}
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="email"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon fontSize="small" color="disabled" />
              </InputAdornment>
            ),
          }}
          label="E-mail"
          placeholder="E-mail"
          name="email"
          autoComplete="email"
          autoFocus
          type="email"
          size="small"
          component={renderTextField}
        />
      </div>
      <div className={classes.BtnWrap}>
        <StyledButton
          onClick={() => dispatch(closePasswordModal())}
          type="reset"
          disableElevation
          fullWidth
          variant="outlined"
          className={classes.reset}>
          Cancel
        </StyledButton>
        <StyledButton
          type="submit"
          disableElevation
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}>
          Submit
        </StyledButton>
      </div>
    </form>
  );
};

const PasswordFindForm = reduxForm<PasswordFindType>({
  // a unique name for the form
  form: 'password-find',
})(PasswordFind);

export default PasswordFindForm;
