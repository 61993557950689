import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {FORMGROUPS} from '~/features/FormGroup/slice';
import {FORMBASIS, load as loadFormBasis} from '~/features/FormBasis/slice';
import {FormGroup, Session} from '~/types';
import Typography from '@material-ui/core/Typography';
import {
  AppBar,
  Dialog,
  IconButton,
  makeStyles,
  Toolbar,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import {SERVER_URI} from '~/contants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '30px 67px',
  },
  title: {
    color: '#495055',
    fontWeight: 700,
    marginBottom: 20,
  },
  section: {
    color: '#2155BB',
    margin: '13px 30px',
    fontWeight: 600,
  },
  contentWrap: {
    padding: theme.spacing(3),
    borderTop: '1px solid #DBDEDF',
    '&:last-child': {
      borderBottom: '1px solid #DBDEDF',
    },
  },
  answerBox: {
    display: 'flex',
  },
  answerWrap: {
    display: 'flex',
    margin: '8px 27px',
  },
  square: {
    marginBottom: -5,
  },
  answer: {
    margin: '0px 8px',
  },

  basisBox: {
    display: 'flex',
    marginRight: -16,
    padding: '10px 20px',
    border: '1px solid #DBDEDF',
    backgroundColor: '#E7F5FC',
  },
  basis: {
    color: '#257EE7',
    fontWeight: 600,
    marginBottom: 10,
  },
  basisContent: {
    color: '#495055',
    fontWeight: 600,
  },
  appBar: {
    position: 'relative',
  },
}));

interface Props {
  scenarioId: number;
  session: Session;
}

const AdmPlanResultScreen = ({scenarioId, session}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const formGroups = useSelector(
    (state: RootState) => state[FORMGROUPS].formGroups[session.type],
  );
  const formBasis = useSelector(
    (state: RootState) => state[FORMBASIS].formBasis,
  );
  const [url, setUrl] = useState<string>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(loadFormBasis());
  }, []);

  const openImage = (url: string) => {
    setUrl(url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderItem = useCallback(
    (fg: FormGroup) => {
      const data = _.chain(fg.forms)
        //.filter((g) => !!g.part)
        .filter((g) => _.includes(fg.answers, g.id))
        .groupBy('part')
        .value();

      return _.chain(data)
        .keys()
        .map((k) => {
          // const plans = data[k];
          const plans = _.map(data[k], (p) => {
            const fb = _.find(formBasis, (f) => f.id === p.formBasisId);
            return {
              ...p,
              formBasis: fb,
            };
          });
          const items = _.chain(plans).groupBy('formBasis.content').value();
          return (
            <div key={k}>
              {k !== 'null' && (
                <Typography
                  variant="subtitle1"
                  color="primary"
                  className={classes.section}>
                  {k}
                </Typography>
              )}
              {_.keys(items).map((fbk) => {
                const obj = _.find(
                  items[fbk],
                  (item) => item.hasImage !== null,
                );
                return (
                  <div key={fbk} className={classes.contentWrap}>
                    <Grid container wrap="nowrap" spacing={2}>
                      {obj && (
                        <Grid item xs={4}>
                          {obj.hasImage}
                          <img
                            width="100%"
                            src={`${SERVER_URI}/forms/${obj.id}/file`}
                            style={{cursor: 'pointer'}}
                            onClick={() =>
                              openImage(`${SERVER_URI}/forms/${obj.id}/file`)
                            }
                          />
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={obj ? 5 : 9}
                        className={classes.answerBox}
                        justify="center"
                        direction="column">
                        {_.map(items[fbk], (i) => (
                          <div
                            key={`plan-key-${i.id}`}
                            className={classes.answerWrap}>
                            <div>
                              <CheckBoxOutlineBlankIcon
                                className={classes.square}
                                color="disabled"
                                fontSize="small"
                              />
                            </div>
                            <Typography
                              variant="subtitle2"
                              className={classes.answer}>
                              {i.name}
                            </Typography>
                          </div>
                        ))}
                      </Grid>
                      <Grid
                        xs={5}
                        className={classes.basisBox}
                        direction="column">
                        <Typography
                          variant="subtitle1"
                          color="primary"
                          className={classes.basis}>
                          간호관리과정 5요소에 따른 계획수립 근거
                        </Typography>
                        {_.split(fbk, '\r\n').map((line) => (
                          <Typography
                            key={line}
                            variant="subtitle1"
                            className={classes.basisContent}>
                            <span>
                              {line}
                              <br />
                            </span>
                          </Typography>
                        ))}
                      </Grid>
                    </Grid>
                  </div>
                );
              })}
            </div>
          );
        })
        .value();
    },
    [formBasis],
  );

  const renderedItems = useMemo(
    () => _.map(formGroups, renderItem),
    [formGroups, renderItem],
  );

  // useEffect(() => {
  //   if (!formGroups) {
  //     dispatch(load({scenarioId, session}));
  //   }
  // }, [dispatch, scenarioId, session, formGroups]);

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        ※ 간호관리활동 계획수립 (Strategy) 결과확인
      </Typography>
      {session.sessionDescriptions.length > 0 && (
        <>
          <Typography>{session.sessionDescriptions[0].description}</Typography>
          <br />
        </>
      )}
      {renderedItems}
      <Dialog
        fullScreen
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <img src={url} style={{width: '100%'}} />
      </Dialog>
    </div>
  );
};

export default AdmPlanResultScreen;
