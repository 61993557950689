import React from 'react';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import {Container} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {LOGIN} from '~/features/Login/slice';
import ProfileEditModal from '~/components/ProfileEditModal';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: 'white',
    padding: 0,
  },
  main: {
    flex: 1,
    display: 'flex',
    overflow: 'auto',
  },
});

const withTemplate = (WrappedComponent: React.FC<any>) => (props: any) => {
  const classes = useStyles();
  const authorized = useSelector((state: RootState) => state[LOGIN].authorized);

  return (
    <Container fixed classes={{root: classes.root}}>
      <Header />
      <div className={classes.main}>
        <WrappedComponent {...props} />
      </div>
      <Footer />
      {authorized && <ProfileEditModal />}
    </Container>
  );
};

export default withTemplate;
