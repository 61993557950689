import {Button, Dialog, DialogTitle, makeStyles} from '@material-ui/core';
import {blue} from '@material-ui/core/colors';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    padding: '0 24px 16px 24px',
  },
  margin: {
    margin: theme.spacing(1),
  },
  btnContainer: {
    alignSelf: 'flex-end',
  },
}));

export interface SubmitCheckDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

function SubmitCheckDialog(props: SubmitCheckDialogProps) {
  const classes = useStyles();
  const {onClose, onSubmit, open} = props;

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}>
      <DialogTitle id="simple-dialog-title">제출하기</DialogTitle>
      <div className={classes.root}>
        <div>
          한번 제출하면 다시 수정할 수 없습니다.
          <br />
          제출하기전에 다시 한번 답을 확인하세요.
        </div>
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            className={classes.margin}
            onClick={handleClose}>
            취소
          </Button>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleSubmit}>
            제출
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default SubmitCheckDialog;
