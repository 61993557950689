import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Chart} from '~/types';

interface State {
  loading: boolean;
  charts: Chart[];
}

let initialState: State = {
  loading: false,
  charts: [],
};

const slice = createSlice({
  name: 'chart',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<Chart[]>) => {
      state.loading = false;
      state.charts = payload;
    },
  },
});

export const {load, successLoad} = slice.actions;
export const CHART = slice.name;
export default slice.reducer;
