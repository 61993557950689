import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Theme,
} from '@material-ui/core';
import _ from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Paper} from '@material-ui/core';

import {RootState} from '~/app/rootReducers';
import * as FormGroupS from '~/features/FormGroup/slice';
import * as Session from '~/features/Session/slice';
import {
  FormCategory as FormCategoryT,
  FormGroup as FormGroupT,
  ProblemState,
  SessionType,
} from '~/types';
import * as CandidateFC from '~/features/CandidateFC/slice';
import * as CandidateFG from '~/features/CandidateFG/slice';
import {CANDIDATEFG} from '~/features/CandidateFG/slice';
import FormGroupForm from '~/forms/FormGroupForm';
import {submit} from 'redux-form';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import {useHistory, useLocation} from 'react-router-dom';
import {CANDIDATEFC} from '~/features/CandidateFC/slice';
import * as FormCategory from '~/features/FormCategory/slice';
import {FORMCATEGORIES} from '~/features/FormCategory/slice';
import {FORMGROUPS} from '~/features/FormGroup/slice';
import api from '~/api';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  grid: {
    flex: 1,
    padding: '10px',
  },
  paper: {
    flex: 1,
    height: '80vh',
    overflow: 'auto',
  },
  modalPaper: {
    flex: 1,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a: FormGroupT[], b: FormGroupT[]) {
  const bIds = _.map(b, (f) => f.id);
  return a.filter((value) => bIds.indexOf(value.id) === -1);
}

function intersection(a: FormGroupT[], b: FormGroupT[]) {
  const bIds = _.map(b, (f) => f.id);
  return a.filter((value) => bIds.indexOf(value.id) !== -1);
}
const ProblemScreen = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<ProblemState>();
  const {scenario, session} = location.state;

  const fgs = useSelector((state: RootState) => state[FORMGROUPS].formGroups);

  const candidateFGs = useSelector(
    (state: RootState) => state[CANDIDATEFG].formGroups,
  );
  const candidateFCs = useSelector(
    (state: RootState) => state[CANDIDATEFC].formCategories,
  );
  const formCategories = useSelector(
    (state: RootState) => state[FORMCATEGORIES].formCategories,
  );

  const [open, setOpen] = useState<boolean>(false);

  const [checked, setChecked] = useState<FormGroupT[]>([]);
  const [right, setRight] = useState<FormGroupT[]>([]);
  const [left, setLeft] = useState<FormGroupT[]>([]);
  const [search, setSearch] = useState<string>('');
  const [formGroup, setFormGroup] = useState<FormGroupT | undefined>();
  const [formGroups, setFormGroups] = useState<FormGroupT[]>([]);
  const [formCategory, setFormCategory] = useState<FormCategoryT | undefined>();
  const [formCategoryId, setFormCategoryId] = useState<number | undefined>();
  const [newFcName, setNewFcName] = useState<string>();
  const [mseFc, setMseFc] = useState<FormCategoryT>();
  const [mseFcId, setMseFcId] = useState<number>();
  const [mseFgs, setMseFgs] = useState<FormGroupT[]>();

  const handleAddFormGroup = useCallback(
    (formGroup: FormGroupT) => {
      if (scenario && session) {
        if (formGroup.id) {
          const {uanswer, forms, score, randomFormSets, ...rest} = formGroup;
          if (_.find(right, (l) => l.id === formGroup.id)) {
            dispatch(
              FormGroupS.update({
                session,
                scenarioId: scenario.id,
                formGroup: rest,
              }),
            );
          } else {
            dispatch(CandidateFG.update(rest));
          }
        } else {
          dispatch(
            CandidateFG.create({
              scenarioId: scenario.id,
              scenarioType: scenario.type,
              sessionId: session.id,
              formGroup: {...formGroup, scenarioType: scenario.type},
            }),
          );
        }
      }
    },
    [dispatch, scenario, session, right],
  );

  const leftChecked = useMemo(
    () => intersection(checked, left),
    [checked, left],
  );
  const rightChecked = useMemo(
    () => intersection(checked, right),
    [checked, right],
  );

  useEffect(() => {
    if (session?.type === SessionType.MSE) {
      const fc = _.find(formCategories, (fc) => fc.id === mseFcId);
      setMseFc(fc);
    }
  }, [session, formCategories, mseFcId]);

  useEffect(() => {
    if (session?.type === SessionType.MSE && mseFc) {
      setChecked(mseFc.formGroups);
    }
  }, [mseFc, session]);

  useEffect(() => {
    setChecked([]);
  }, [session]);

  useEffect(() => {
    if (formCategories && formCategoryId) {
      setFormCategory(_.find(formCategories, (fc) => fc.id === formCategoryId));
    } else {
      setFormCategory(undefined);
    }
  }, [formCategoryId, formCategories]);

  useEffect(() => {
    if (formCategory) {
      setFormGroups(formCategory.formGroups);
    }
  }, [formCategory]);

  useEffect(() => {
    if (session && session.type !== SessionType.PSY_INTERVENTION) {
      setFormCategoryId(undefined);
      setFormGroups(fgs[session.type]);
    } else {
      setFormGroups([]);
    }
  }, [session, fgs]);

  useEffect(() => {
    if (scenario && session) {
      dispatch(FormCategory.load({scenarioId: scenario.id, session}));
    }
  }, [dispatch, scenario, session]);

  useEffect(() => {
    dispatch(CandidateFC.load());
  }, [dispatch]);

  useEffect(() => {
    if (session && formGroups) {
      setRight(formGroups);
    }
  }, [session, formGroups]);

  useEffect(() => {
    setLeft(
      _.filter(not(candidateFGs, right), (fg) => fg.name.includes(search)),
    );
  }, [candidateFGs, right, search]);

  useEffect(() => {
    if (session && scenario) {
      dispatch(FormGroupS.load({scenarioId: scenario.id, session}));
    }
  }, [dispatch, scenario, session]);

  useEffect(() => {
    if (scenario) {
      dispatch(CandidateFG.load(scenario.type));
    }
  }, [dispatch, scenario]);

  const handleToggle = useCallback(
    (value: FormGroupT) => () => {
      const currentIndex = _.findIndex(checked, (t) => t.id === value.id);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    },
    [checked],
  );

  const editFormGroup = (value: FormGroupT) => {
    history.push(`/admin-class/problems/options`, {
      ...location.state,
      formGroupId: value.id,
    });
  };

  const customList = useCallback(
    (items: FormGroupT[], edit?: boolean) => (
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items.map((value: FormGroupT) => {
            const labelId = `transfer-list-item-${value.id}-label`;

            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={
                      _.findIndex(checked, (t) => t.id === value.id) !== -1
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{'aria-labelledby': labelId}}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.name} />
                {edit && (
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      editFormGroup(value);
                    }}>
                    <AddCircleOutlineIcon fontSize="inherit" />
                  </IconButton>
                )}
                <IconButton
                  aria-label="settings"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setFormGroup(value);
                    setOpen(true);
                  }}>
                  <SettingsIcon fontSize="inherit" />
                </IconButton>
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    ),
    [checked, handleToggle],
  );

  const leftListMarkup = useMemo(() => {
    return customList(left);
  }, [left, customList]);

  const rightListMarkup = useMemo(() => {
    return customList(right, true);
  }, [right, customList]);

  const handleCheckedRight = () => {
    if (session && scenario) {
      if (session.type === SessionType.PSY_INTERVENTION && formCategory) {
        dispatch(
          FormCategory.attachFormGroup({
            scenarioId: scenario.id,
            formCategoryId: formCategory.id,
            session,
            formGroupIds: _.map(leftChecked, (c) => c.id),
          }),
        );
      } else {
        dispatch(
          Session.attachFormGroup({
            scenarioId: scenario.id,
            session,
            sessionId: session.id,
            formGroupIds: _.map(leftChecked, (c) => c.id),
          }),
        );
      }
    }
  };

  const handleCheckedLeft = () => {
    if (session && scenario) {
      if (session.type === SessionType.PSY_INTERVENTION && formCategory) {
        dispatch(
          FormCategory.detachFormGroup({
            scenarioId: scenario.id,
            formCategoryId: formCategory.id,
            session,
            formGroupIds: _.map(rightChecked, (c) => c.id),
          }),
        );
      } else {
        dispatch(
          Session.detachFormGroup({
            scenarioId: scenario.id,
            session,
            sessionId: session.id,
            formGroupIds: _.map(rightChecked, (c) => c.id),
          }),
        );
      }
    }
  };

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  const handleFormCategory = (event: React.ChangeEvent<{value: unknown}>) => {
    setFormCategoryId(event.target.value as number);
  };

  const toggleFormCategory = (formCategory: FormCategoryT) => {
    if (session && scenario) {
      const currentIndex = _.findIndex(
        formCategories,
        (t) => t.id === formCategory.id,
      );

      if (currentIndex === -1) {
        dispatch(
          FormCategory.attach({
            formCategoryId: formCategory.id,
            session,
            scenarioId: scenario.id,
          }),
        );
      } else {
        dispatch(
          FormCategory.detach({
            formCategoryId: formCategory.id,
            session,
            scenarioId: scenario.id,
          }),
        );
      }
    }
  };

  const handleAddFormCategory = useCallback(async () => {
    if (newFcName) {
      const fc = await api.createFormCategory(newFcName);
      setNewFcName(undefined);
      if (scenario && session) {
        dispatch(
          FormCategory.attach({
            formCategoryId: fc.data.id,
            scenarioId: scenario?.id,
            session: session,
          }),
        );
      }
    }
  }, [newFcName, dispatch, scenario, session]);

  const handleChangeMseFc = useCallback(
    async (e) => {
      setMseFcId(e.target.value);
      // const fc = _.find(formCategories, (fc) => fc.id === e.target.value);
      // setMseFc(fc);
      // if (fc) {
      //   const resp = await api.getFormGroupWithFormCategoryId(fc.id);
      //   setChecked(resp.data);
      // }
    },
    [formCategories],
  );

  const handleMseAttachFormGroupToFc = useCallback(() => {
    if (scenario && mseFc && session) {
      dispatch(
        FormCategory.attachFormGroup({
          scenarioId: scenario.id,
          formCategoryId: mseFc.id,
          session,
          formGroupIds: _.map(rightChecked, (c) => c.id),
        }),
      );
    }
  }, [rightChecked, mseFc]);

  const handleMseDetachFormGroupToFc = useCallback(() => {
    if (scenario && mseFc && session) {
      dispatch(
        FormCategory.detachFormGroup({
          scenarioId: scenario.id,
          formCategoryId: mseFc.id,
          session,
          formGroupIds: _.map(rightChecked, (c) => c.id),
        }),
      );
    }
  }, [rightChecked, mseFc]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        className={classes.grid}>
        <Grid xs item>
          <TextField
            fullWidth
            id="filled-search"
            label="Search field"
            type="search"
            variant="outlined"
            onChange={handleSearch}
          />
          {leftListMarkup}
        </Grid>
        <Grid xs={1} item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={() => {
                setFormGroup(undefined);
                setOpen(true);
              }}
              aria-label="add formgroup">
              +
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right">
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left">
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid xs item>
          {session?.type === SessionType.MSE && (
            <>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Form Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  fullWidth
                  value={formCategory?.id}
                  onChange={handleChangeMseFc}>
                  {_.map(formCategories, (fc) => (
                    <MenuItem key={`form-category-${fc.id}`} value={fc.id}>
                      {fc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div>
                <TextField
                  id="standard-basic"
                  label="카테고리를 입력하세요"
                  fullWidth
                  value={newFcName}
                  onChange={(e) => setNewFcName(e.target.value)}
                />
                <br />
                <br />
                <Button variant="outlined" onClick={handleAddFormCategory}>
                  카테고리 생성
                </Button>
                <br />
                <br />
                <Button
                  variant="outlined"
                  onClick={handleMseAttachFormGroupToFc}>
                  카테고리에 추가
                </Button>
                <br />
                <br />
                <Button
                  variant="outlined"
                  onClick={handleMseDetachFormGroupToFc}>
                  카테고리에서 제거
                </Button>
              </div>
            </>
          )}
          {session?.type === SessionType.PSY_INTERVENTION && (
            <div>
              <FormGroup row>
                {_.map(candidateFCs, (fc) => (
                  <FormControlLabel
                    key={`form-category-cb-${fc.id}`}
                    control={
                      <Checkbox
                        checked={
                          !!_.find(formCategories, (sfc) => sfc.id === fc.id)
                        }
                        name={`form-category-cb-${fc.id}`}
                        onClick={() => toggleFormCategory(fc)}
                      />
                    }
                    label={fc.name}
                  />
                ))}
              </FormGroup>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Form Category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formCategory?.id}
                  onChange={handleFormCategory}>
                  {_.map(formCategories, (fc) => (
                    <MenuItem key={`form-category-${fc.id}`} value={fc.id}>
                      {fc.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {rightListMarkup}
        </Grid>
      </Grid>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
        <Paper elevation={0} className={classes.modalPaper}>
          <FormGroupForm
            initialValues={formGroup}
            onSubmit={handleAddFormGroup}
          />
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => dispatch(submit('FormGroup'))}>Add</Button>
        </Paper>
      </Modal>
    </div>
  );
};

export default ProblemScreen;
