import {
  Button,
  IconButton,
  InputBase,
  Link,
  makeStyles,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Theme,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import {createMuiTheme} from '@material-ui/core/styles';
import _ from 'lodash';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '~/app/rootReducers';
import {COURSE} from '~/features/Course/slice';
import {FORMGROUPS, updateScore} from '~/features/FormGroup/slice';
import * as FormCategoryAction from '~/features/FormCategory/slice';
import {FormGroup, Score, Session, SessionType} from '~/types';
import {openScoreSettingModal} from '~/features/Modal/slice';
import ScoreSettingModal from '~/modals/ScoreSettingtModal';
import update from 'immutability-helper';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '80%',
    margin: '100px auto',
    position: 'relative',
  },
  tableContainer: {},
  tableTitle: {
    fontWeight: 600,
    '&:nth-child(1)': {
      width: 166,
    },
    '&:nth-child(2)': {
      width: 309,
    },
    '&:nth-child(3)': {
      width: 445,
    },
    '&:nth-child(4)': {
      width: 110,
    },
  },
  tableActive: {
    textTransform: 'none',
    height: '25px',
    justifyContent: 'center',
  },
  tblCircle: {
    width: 8,
    color: '#33BE63',
    position: 'relative',
    top: 8,
    left: -5,
  },
  tblInactCircle: {
    width: 8,
    color: '#DF3826',
    position: 'relative',
    top: 8,
    left: -5,
  },
  tableCellScore: {
    marginLeft: 17,
    width: 30,
  },
  tableCellLink: {
    color: 'black',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  tableCellBtn: {
    marginLeft: 7,
    width: 20,
    height: 20,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const theme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '14px 0px 14px 16px',
        border: '1px solid rgba(219, 222, 223, 1)',
        cursor: 'default',
      },
      body: {
        padding: '10px 0px 10px 16px',
      },
    },
  },
});

const StyledButton = styled(Button)({
  backgroundColor: '#2595FF',
  color: 'white',
  borderRadius: '2px',
  boxShadow: 'none',
  float: 'right',
  top: 10,
});

interface Props {
  session: Session;
  scenarioId: number;
}

const ScoreSetting = ({session, scenarioId}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const course = useSelector((state: RootState) => state[COURSE].current);

  const formGroupsRedux = useSelector(
    (state: RootState) => state[FORMGROUPS].formGroups[session.type],
  );
  const formCategoriesRedux = useSelector(
    (state: RootState) =>
      state[FormCategoryAction.FORMCATEGORIES].formCategories,
  );

  const [scores, setScores] = useState<Score[]>([]);
  const [formGroup, setFormGroup] = useState<FormGroup>();

  const [formGroups, setFormGroups] = useState<FormGroup[]>([]);

  useEffect(() => {
    if (session?.type === SessionType.PSY_INTERVENTION) {
      setFormGroups(
        _.chain(formCategoriesRedux)
          .map((fc) =>
            _.map(fc.formGroups, (fg) => ({
              ...fg,
              name: `${fc.name}-${fg.name}`,
              formCategoryId: fc.id,
            })),
          )
          .flattenDeep()
          .value(),
      );
    } else {
      setFormGroups(formGroupsRedux);
    }
  }, [session, formGroupsRedux, formCategoriesRedux]);

  useEffect(() => {
    if (session) {
      setScores(
        _.map(formGroups, (g) => {
          if (session.type === SessionType.PSY_INTERVENTION) {
            return (
              _.find(
                g.scores,
                (s) => s.formCategoryId === g.formCategoryId,
              ) ?? {
                score: 0,
                isActivated: true,
                formCategoryId: g.formCategoryId,
              }
            );
          } else {
            return (
              _.find(g.scores, (s) => s.formCategoryId === null) ?? {
                score: 0,
                isActivated: true,
              }
            );
          }
        }),
      );
    }
  }, [formGroups, session]);

  const handleUpdate = useCallback(() => {
    if (course) {
      for (let index = 0; index < formGroups.length; index++) {
        const fg = formGroups[index];
        dispatch(
          updateScore({
            score: scores[index],
            formGroup: fg,
            session,
            scenarioId,
          }),
        );
      }
    }
  }, [dispatch, course, scores, formGroups, session, scenarioId]);

  const handleActivity = useCallback(
    (index: number) => {
      setScores(
        update(scores, {[index]: (s) => ({...s, isActivated: !s.isActivated})}),
      );
    },
    [scores],
  );

  const handleChangeScore = useCallback(
    (val: string, index: number) => {
      scores[index] = {...scores[index], score: parseInt(val)};
      setScores([...scores]);
    },
    [scores],
  );

  const showProblem = useCallback(
    (fg: FormGroup) => {
      setFormGroup(fg);
      dispatch(openScoreSettingModal());
    },
    [dispatch],
  );

  const renderTable = useCallback(() => {
    if (formGroups?.length === scores.length) {
      return _.map(formGroups, (row, index) => (
        <TableRow key={row.name}>
          <TableCell component="th" scope="row">
            <Button
              onClick={() => handleActivity(index)}
              className={classes.tableActive}>
              {scores[index].isActivated ? (
                <Typography>
                  <FiberManualRecordIcon className={classes.tblCircle} />
                  active
                </Typography>
              ) : (
                <Typography>
                  <FiberManualRecordIcon className={classes.tblInactCircle} />
                  inactive
                </Typography>
              )}
            </Button>
          </TableCell>
          <TableCell>{row.name}</TableCell>
          <TableCell>
            <Link
              onClick={() => showProblem(row)}
              underline="none"
              className={classes.tableCellLink}>
              문제 불러오기
            </Link>
          </TableCell>
          <TableCell>
            <TextField
              variant="standard"
              className={classes.tableCellScore}
              value={scores[index]?.score || 0}
              onChange={(e) => handleChangeScore(e.target.value, index)}
            />
            {/* <IconButton aria-label="delete" className={classes.tableCellBtn}>
              <CreateOutlinedIcon className={classes.tableCellBtn} />
            </IconButton> */}
          </TableCell>
        </TableRow>
      ));
    }
  }, [handleChangeScore, handleActivity, showProblem, formGroups, scores]);

  return (
    <div className={classes.container}>
      <ThemeProvider theme={theme}>
        <TableContainer>
          <Table aria-label="simple table" className={classes.tableContainer}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableTitle}>활성화상태</TableCell>
                <TableCell className={classes.tableTitle}>교육과제</TableCell>
                <TableCell className={classes.tableTitle}>문제보기</TableCell>
                <TableCell className={classes.tableTitle}>점수입력</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTable()}</TableBody>
          </Table>
        </TableContainer>
        <StyledButton onClick={handleUpdate} variant="contained">
          저장하기
        </StyledButton>
      </ThemeProvider>

      {formGroup && <ScoreSettingModal problem={formGroup} />}
    </div>
  );
};

export default ScoreSetting;
