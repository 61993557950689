import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Form} from '~/types';

interface State {
  loading: boolean;
  forms: Form[];
}

let initialState: State = {
  loading: false,
  forms: [],
};

const slice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    load: (state, _action: PayloadAction<number>) => {
      state.loading = true;
    },
    successLoad: (state, {payload}: PayloadAction<Form[]>) => {
      state.loading = false;
      state.forms = payload;
    },
  },
});

export const {load, successLoad} = slice.actions;
export const FORMS = slice.name;
export default slice.reducer;
