import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, select, takeLatest} from 'redux-saga/effects';
import {RootState} from '~/app/rootReducers';
import {COURSE} from '../Course/slice';
import {load, loadSuccess} from './slice';
import api from '~/api';

function* handleAnswers({
  payload: {scenarioId, sessionId, userId},
}: PayloadAction<{scenarioId: number; sessionId: number; userId?: number}>) {
  try {
    const courseId = yield select(
      (state: RootState) => state[COURSE].current?.id,
    );
    const resp = yield call(
      api.getAnswers,
      courseId,
      scenarioId,
      sessionId,
      userId,
    );
    yield put(loadSuccess(resp.data));
  } catch (err) {
    console.log('error', err);
  }
}

export function* watchAnswers() {
  yield takeLatest(load, handleAnswers);
}
