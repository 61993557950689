import {makeStyles} from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import {useSelector} from 'react-redux';
import {Field, InjectedFormProps, reduxForm} from 'redux-form';
import {RootState} from '~/app/rootReducers';
import {SERVER_URI} from '~/contants';
import {COURSE} from '~/features/Course/slice';
import {FORMBASIS} from '~/features/FormBasis/slice';
import {Form} from '~/types';
import api from '~/api';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    height: '100%',
    paddingLeft: 24,
    paddingRight: 26,
    marginTop: 24,
  },
}));

interface Props {
  scenarioId?: number;
}

const FormF = (props: InjectedFormProps<Form, Props> & Props) => {
  const classes = useStyles();
  const {handleSubmit, initialValues, scenarioId} = props;

  const formBasis = useSelector(
    (state: RootState) => state[FORMBASIS].formBasis,
  );
  const course = useSelector((state: RootState) => state[COURSE].current);

  const fileHandler = async (e: any) => {
    const files = e.target.files;
    let formData = new FormData();
    formData.append('file', files[0]);
    if (course && scenarioId && initialValues?.id) {
      await api.uploadFormImage(
        course.id,
        scenarioId,
        initialValues.id,
        formData,
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <div>
        <label>옵션명</label>
        <Field name="name" component="input" type="text" />
      </div>
      <div>
        <label>multiline</label>
        <Field name="isMultiline" component="input" type="checkbox" />
      </div>
      <div>
        <label>최소글자</label>
        <Field
          name="minLength"
          component="input"
          pattern="[0-9]*"
          type="number"
          parse={(val: string) => parseInt(val, 10)}
        />
      </div>
      <div>
        <label>최대글자</label>
        <Field
          name="maxLength"
          component="input"
          type="number"
          parse={(val: string) => parseInt(val, 10)}
        />
      </div>
      <div>
        <label>seq</label>
        <Field
          name="seq"
          component="input"
          type="number"
          parse={(val: string) => parseInt(val, 10)}
        />
      </div>
      <div>
        <label>Title Only</label>
        <Field name="isTitleOnly" component="input" type="checkbox" />
      </div>
      <div>
        <label>간호관리활동 계획수립 부분</label>
        <Field name="part" component="input" type="text" />
      </div>
      <div>
        <label>근거</label>
        <Field
          name="formBasisId"
          component="select"
          parse={(val: string) => parseInt(val, 10)}>
          <option />
          {_.map(formBasis, (fb) => (
            <option key={`form-basis-${fb.id}`} value={fb.id}>
              {fb.content}
            </option>
          ))}
        </Field>
      </div>
      <div>
        {initialValues?.id && (
          <img
            width="500px"
            src={`${SERVER_URI}/forms/${initialValues.id}/file`}
          />
        )}
        <input type="file" onChange={fileHandler} />
      </div>
    </form>
  );
};

const FormForm = reduxForm<Form, Props>({
  form: 'Form',
})(FormF);

export default FormForm;
