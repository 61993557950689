import React from 'react';
import {FormControl, RadioGroup} from '@material-ui/core';
import {WrappedFieldProps} from 'redux-form';

interface IntroProps {
  label: string;
  name: string;
  value: string;
}

const radioButton = ({
  value,
  name,
  label,
  input,
  children,
  ...rest
}: WrappedFieldProps & IntroProps & {children: any}) => {
  return (
    <FormControl>
      <RadioGroup {...input} {...rest}>
        {children}
      </RadioGroup>
    </FormControl>
  );
};

export default radioButton;
